import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import arrowNavi from 'Src/assets/icons/arrowNavi.svg';

import { Box, Text } from './basic';

const isElemSticked = (elem: any, cb: (is: boolean) => any) => () =>
    cb(
        elem.getBoundingClientRect().y <= 30 &&
            elem.getBoundingClientRect().y >= -20,
    );

const Heading = styled(Box)<{ background?: string }>`
    position: sticky;
    top: 0px;
    z-index: 999;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    background: ${props => props.background || '#f3fa75'};
    border: 0;
    cursor: pointer;
    height: 67px;
    transition: 0.3s;
    overflow: hidden;
    &.sticked {
        margin-top: 0;
        padding: 0;
        height: 22px;
        * {
            padding: 0;
            display: none;
        }
    }
`;

const Back = styled.p`
    position: absolute;
    left: 60px;
    top: 0;
    height: 100%;
    width: 30px;
    background: url(${arrowNavi}) center/contain no-repeat;
    cursor: pointer;
`;

const StyledText = styled(Text)`
    user-select: none;
`;

class PageHeader extends React.PureComponent<any> {
    state: any = {
        id: Array.from(
            { length: 10 },
            () => 'qwerty'[Math.floor(Math.random() * 6)],
        ).join(''),
        isSticked: false,
    };
    componentDidMount() {
        const elem = document.getElementById(this.state.id);

        document.onscroll = isElemSticked(elem, isSticked =>
            this.setState({ isSticked }),
        );
    }
    componentWillUnmount() {
        document.onscroll = null;
    }
    render() {
        return (
            <Heading
                borderBottom="0"
                py="3"
                id={this.state.id}
                className={this.state.isSticked ? 'sticked' : ''}
                onClick={this.state.isSticked && (() => window.scrollTo(0, 0))}
            >
                {!this.props.withoutBack && (
                    <Back
                        onClick={
                            this.props.to
                                ? () => this.props.history.push(this.props.to)
                                : this.props.history.goBack
                        }
                    />
                )}
                <StyledText
                    t
                    onClick={this.props.onClick || (() => null)}
                    args={this.props.count}
                    type="h3"
                    lineHeight="initial !important"
                >
                    {this.props.title}
                </StyledText>
                {this.props.children}
            </Heading>
        );
    }
}

export default withRouter(PageHeader);
