import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';

import { Box as BaseBox, Dots, Text } from 'Src/components/basic';
// import Price from 'Src/components/Price';
// import { Cover } from 'Src/components/ResizedImage';
import { Link } from 'Src/locales';
import { AppContext } from 'Src/model';

const Dot = styled.li`
    cursor: pointer;
`;

const Box = styled(BaseBox)<{ noCenter?: boolean }>`
    ${props =>
        props.noCenter &&
        `
        display: block;
        `}
`;
const CursoredBox = styled(Box)`
    cursor: pointer;
`;
const Img = styled.img<{ current?: boolean }>`
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: 0.3s;
    &:hover {
        transform: ${props => (props.current ? 'scale(1.3)' : 'none')};
    }
`;

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    // centerMode: true,
    // centerPadding: '20px',
    appendDots: (dots: any) => (
        <Dots>
            {dots.map((dot: any, index: number) => (
                <Dot
                    key={index}
                    className={dot.props.className}
                    onClick={dot.props.children.props.onClick}
                >
                    {dot.props.children.props.children}
                </Dot>
            ))}
        </Dots>
    ),
    dotsClass: 'null',
};
const CoverBox = styled(Box)<{
    withAnimation?: boolean;
    withoutPadding?: boolean;
}>`
    .slider {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 358px;
        padding-bottom: ${props => (props.withoutPadding ? 0 : '25px')};
        padding-top: ${(props: any) => (props.withAnimation ? '60%' : 0)};
        .slick-list {
            padding: 0 !important;
            height: 100%;
        }
        .slick-track {
            height: 100%;
            .slick-slide {
                ${props =>
                    props.withoutPadding ? 0 : 'padding: 0 0.5rem 0 0'};
            }
            ${props =>
                props.withoutPadding &&
                `
                div {
                    height: 100%;
                }
                `}
        }
    }
`;
const Item = (props: any) => {
    return (
        <Box
            padding={props.withoutText ? '0' : '0px 20px'}
            display="flex"
            justifyContent="center"
            noCenter={props.withoutText}
            // maxWidth="800px"
        >
            {props.withoutPicture || (
                <Link to={`/items/${props.id}`}>
                    <Img
                        current={props.current || false}
                        src={
                            props.images.find((_image: any) => _image.isCover)
                                ? props.images.find(
                                      (_image: any) => _image.isCover,
                                  ).path
                                : ''
                        }
                    />
                </Link>
            )}
            {props.withoutText || (
                <CursoredBox
                    width="100%"
                    height="358px"
                    m="50px 50px 0 40px"
                    onClick={props.onClick || undefined}
                >
                    <Text mb="17px" type="body1">
                        {props.summary}
                    </Text>
                    <Box mt="40px" onClick={e => e.stopPropagation()}>
                        {/*                        <Price
                            title="ui.in_bag"
                            fontType="h4"
                            id={props.id}
                            value={props.price}
                            width="360px"
                            height="46px"
                            onBagClick={() => props.addBook(props, 1)}
                        />*/}
                    </Box>
                </CursoredBox>
            )}
        </Box>
    );
};

export default withRouter(
    class extends PureComponent<any, { hovered: boolean; current: number }> {
        static contextType = AppContext;
        state = {
            hovered: false,
            current: 0,
        };
        constructor(props: any) {
            super(props);
        }
        handleMouseEnter = () => {
            this.props.anotherSlider.slickPause();
        };
        handleMouseLeave = () => {
            this.props.anotherSlider.slickPlay();
        };
        render() {
            return (
                <CoverBox
                    withoutPadding={this.props.withoutText}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    {
                        <Slider
                            {...settings}
                            ref={(r: any) => {
                                this.props.setRef && this.props.setRef(r);
                            }}
                            afterChange={(current: number) => {
                                this.setState({ current });
                            }}
                            speed={this.props.speed || settings.speed}
                            dots={this.props.withoutDots ? false : true}
                            asNavFor={this.props.anotherSlider || undefined}
                            lazyLoad={'progressive'}
                            className="slider"
                        >
                            {this.props.items.map(
                                (
                                    _item: IBlockPromoItemProps,
                                    _index: number,
                                ) => {
                                    return (
                                        <Item
                                            current={
                                                this.state.current === _index
                                            }
                                            push={this.props.history.push}
                                            addBook={this.context.bag.addBook}
                                            onClick={() =>
                                                this.props.history.push(
                                                    `${
                                                        this.context.locale
                                                            .value
                                                            ? `/${this.context.locale.value}`
                                                            : ''
                                                    }/items/${_item.id}`,
                                                )
                                            }
                                            key={_index}
                                            {..._item}
                                            {...this.props}
                                        />
                                    );
                                },
                            )}
                        </Slider>
                    }
                </CoverBox>
            );
        }
    },
);
