import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Icon from 'Src/components/Icon';
import { Cover } from 'Src/components/ResizedImage';
import { AppContext } from 'Src/model';

import { Box, PayButton as BasePayButton, Text } from './basic';

const Container = styled(Box)`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    animation: in 0.4s;

    @keyframes in {
        0% {
            transform: translateY(-200px);
        }
        100% {
            transform: translateY(0px);
        }
    }
`;
const Item = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
`;

const CartButton = styled(BasePayButton)`
    padding: 12px 0 10px;
    background: black;
    color: white;
    &:active {
        background: white;
        color: black;
    }
`;

const PayButton = styled(BasePayButton)`
    padding: 12px 0 10px;
`;

interface CartPopupState {
    timeout: any;
    animation: any;
    hide: boolean;
    hovered: boolean;
}

class CartPopup extends Component<any, CartPopupState> {
    state: CartPopupState = {
        timeout: null,
        animation: null,
        hide: true,
        hovered: false,
    };
    constructor(props: any) {
        super(props);
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.bag.lastAdded.id !== this.props.bag.lastAdded.id) {
            clearTimeout(this.state.timeout);
            clearTimeout(this.state.animation);
            this.setState({
                timeout: setTimeout(() => {
                    if (!this.state.hovered) {
                        this.props.bag.hidePopup();
                    }
                }, 3000),
                animation: setTimeout(() => {
                    if (!this.state.hovered) {
                        this.setState({ hide: false });
                    }
                }, 2000),
            });
        }
    }

    handleBagClick = () => this.props.bag.hidePopup();
    handleOrderClick = () => this.props.bag.hidePopup();
    handleCloseClick = () => this.props.bag.hidePopup();
    handleMouseEnter = () => this.setState({ hovered: true });
    handleMouseLeave = () => {
        this.setState({ hovered: false }, () => {
            this.setState({
                timeout: setTimeout(() => {
                    this.props.bag.hidePopup();
                }, 1200),
                animation: setTimeout(() => {
                    this.setState({ hide: false });
                }, 200),
            });
        });
    };
    render() {
        const book = this.props.bag.lastAdded;
        const length = ((acc: number) =>
            this.props.bag.books.map((_book: any) => {
                acc += _book.amount;
            }) && acc)(0);
        const total = ((acc: number) =>
            this.props.bag.books.map((_book: any) => {
                acc += _book.price * _book.amount;
            }) && acc)(0);

        return book.id === undefined ||
            (this.props.location.pathname === '/cart' ||
                this.props.location.pathname ===
                    `/${this.props.locale.value}/cart` ||
                this.props.location.pathname === `/order` ||
                this.props.location.pathname ===
                    `/${this.props.locale.value}/order`) ? null : (
            <Container
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <Item
                    key={book.id}
                    backgroundColor="#F2F2F2"
                    border="1"
                    height="176px"
                    pl="1px"
                >
                    <Box
                        borderRight="2px solid #000"
                        px="magic.1"
                        display="flex"
                        alignItems="center"
                    >
                        <Cover
                            path={
                                book.images.find((image: any) => image.isCover)
                                    ? book.images.find(
                                          (image: any) => image.isCover,
                                      ).path
                                    : ''
                            }
                            height={130}
                            margin="0 20px 0 0"
                        />
                        <Box
                            mt="50px"
                            mb="34px"
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            alignSelf="stretch"
                            width="100%"
                        >
                            <Box display="flex">
                                <Icon name="check" mr="15px" />
                                <Text type="body2" pb="3" t>
                                    popup.item_is_added
                                </Text>
                            </Box>
                            <Link to={`/${this.props.locale.value}/cart`}>
                                <CartButton p="1" onClick={this.handleBagClick}>
                                    <Text type="h4" t textTransform="uppercase">
                                        popup.go_to_cart
                                    </Text>
                                </CartButton>
                            </Link>
                        </Box>
                    </Box>
                    <Box
                        mx="magic.1"
                        mt="50px"
                        mb="34px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignSelf="stretch"
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Text type="body2" args={total / 100} t>
                                popup.total_sum
                            </Text>
                            <Text type="body2" mr="40px" args={length} t>
                                popup.amount
                            </Text>
                        </Box>
                        <Link to={`/${this.props.locale.value}/order`}>
                            <PayButton p="1" onClick={this.handleOrderClick}>
                                <Text type="h4" textTransform="uppercase" t>
                                    popup.go_to_pay
                                </Text>
                            </PayButton>
                        </Link>
                    </Box>
                </Item>
            </Container>
        );
    }
}

export default withRouter(
    class extends React.PureComponent<any> {
        constructor(props: any) {
            super(props);
        }
        render() {
            return (
                <AppContext.Consumer>
                    {(model: any) => (
                        <CartPopup
                            bag={model.bag}
                            locale={model.locale}
                            location={this.props.location}
                        />
                    )}
                </AppContext.Consumer>
            );
        }
    },
);
