import * as React from 'react';
import styled from 'styled-components';

import Icon from 'Src/components/Icon';

import { Box, Text } from './basic';

interface ICollapseState {
    isCollapsed: boolean;
}

const Heading = styled(Box)`
    &:hover {
        cursor: pointer;
    }
`;

const StyledText = styled(Text)`
    user-select: none;
`;

export default class Collapse extends React.PureComponent<any> {
    state: ICollapseState = { isCollapsed: true };
    toggleCollapse() {
        this.setState(
            (state: ICollapseState): ICollapseState => ({
                ...state,
                isCollapsed: !state.isCollapsed,
            }),
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.isCollapsed);
                }
            },
        );
    }
    public render() {
        return (
            <Box
                borderBottom={
                    this.props.border === 'little'
                        ? 'none'
                        : this.props.mobile
                        ? '1px solid black'
                        : '2px solid #000'
                }
                width={this.props.width || 'auto'}
                borderColor={this.props.color || 'black'}
            >
                <Heading
                    paddingTop={this.props.small ? '11px' : '3'}
                    paddingBottom={this.props.mobile ? '8px' : '3'}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pr="2"
                    onClick={() => this.toggleCollapse()}
                >
                    <StyledText
                        args={this.props.args}
                        type={
                            this.props.fontType
                                ? this.props.fontType
                                : this.props.mobile
                                ? 'mh2'
                                : 'h4'
                        }
                        color={this.props.color || 'black'}
                        textTransform="uppercase"
                        t={this.props.t}
                    >
                        {this.props.title}
                    </StyledText>
                    <Icon
                        size={
                            this.props.iconSize
                                ? this.props.iconSize
                                : this.props.mobile
                                ? 20
                                : 21
                        }
                        color={this.props.color || 'black'}
                        name={
                            this.state.isCollapsed
                                ? this.props.mobile
                                    ? this.props.color
                                        ? 'plusWhite'
                                        : 'mplus'
                                    : this.props.color
                                    ? 'plusWhite'
                                    : 'plus'
                                : this.props.mobile
                                ? this.props.color
                                    ? 'minusWhite'
                                    : 'mminus'
                                : this.props.color
                                ? 'minusWhite'
                                : 'minus'
                        }
                    />
                </Heading>
                {!this.state.isCollapsed && this.props.children}
            </Box>
        );
    }
}
