import { themeGet } from '@styled-system/theme-get';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
// import arrowRight from 'Src/assets/icons/arrowRight.svg';
import {
    borders,
    BordersProps,
    color,
    ColorProps,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    shadow,
    ShadowProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from 'styled-system';

import logoWhite from 'Src/assets/logo-white.svg';
import logo from 'Src/assets/logo.svg';
import I18n from 'Src/locales';

const BaseText = styled.p<
    SpaceProps &
        ColorProps &
        TypographyProps & {
            textTransform?: 'uppercase' | 'capitalize' | 'initial';
        } & { textDecoration?: 'none' | 'underline' } & {
            whiteSpace?: 'nowrap' | 'normal';
        } & {
            type?:
                | 'h1'
                | 'h2'
                | 'h3'
                | 'h4'
                | 'h5'
                | 'body1'
                | 'body2'
                | 'body3'
                | 'mh1'
                | 'mh2'
                | 'mh3'
                | 'mbody1'
                | 'mbody2'
                | 'mbody3';
        }
>`
  ${typography}
  ${space}
  ${color}
  ${props => props.textTransform && `text-transform: ${props.textTransform}`}
  ${props => props.textDecoration && `text-decoration: ${props.textDecoration}`}
  ${props => props.whiteSpace && `white-space: ${props.whiteSpace}`}
  ${props => {
      if (props.type) {
          switch (props.type) {
              case 'h1':
                  return `
            font-family: Fugue;
            font-size: 60px;
            line-height: 62px;
          `;
              case 'h2':
                  return `
            font-family: Fugue;
            font-size: 44px;
            letter-spacing: 1px;
            line-height: 47px;
          `;
              case 'h3':
                  return `
            font-family: Fugue;
            font-size: 30px;
            line-height: 32px;
            letter-spacing: 1px;
          `;
              case 'h4':
                  return `
            font-family: Fugue;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.8px;
          `;
              case 'h5':
                  return `
            font-family: Fugue;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.4px;
          `;
              case 'body1':
                  return `
            font-family: UniversRoman;
            font-size: 27px;
            line-height: 33px;
          `;
              case 'body2':
                  return `
            font-family: UniversRoman;
            font-size: 15px;
            line-height: 20px;
          `;
              case 'body3':
                  return `
            font-family: UniversRoman;
            font-size: 12px;
            line-height: 15px;
          `;
              case 'mh1':
                  return `
            font-family: Fugue;
            font-size: 22px;
            line-height: 25px;
            letter-spacing: 0.8px;
          `;
              case 'mh2':
                  return `
            font-family: Fugue;
            font-size: 15px;
            line-height: 15px;
            letter-spacing: 0.4px;
          `;
              case 'mh3':
                  return `
            font-family: Fugue;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.3px;
          `;
              case 'mbody1':
                  return `
            font-family: UniversRoman;
            font-size: 15px;
            line-height: 20px;
          `;
              case 'mbody2':
                  return `
            font-family: UniversRoman;
            font-size: 12px;
            line-height: 15px;
          `;
              case 'mbody3':
                  return `
            font-family: UniversRoman;
            font-size: 8px;
            line-height: 12px;
          `;

              default:
                  return '';
          }
      }

      return null;
  }}
`;

export const Text = (props: any) => {
    return props.tD ? (
        <BaseText
            {...props}
            dangerouslySetInnerHTML={{
                __html: (() =>
                    I18n.getTranslation(location, props.tD, props.args))(),
            }}
        ></BaseText>
    ) : (
        <BaseText {...props}>
            {props.t ? (
                <I18n t={props.children} args={props.args} />
            ) : (
                props.children
            )}
        </BaseText>
    );
};

export const CommentInput = styled.textarea<SpaceProps & TypographyProps>`
    ${space};
    ${typography};
    outline: 0;
    border: 1px solid #000;
    padding: 18px 30px;
    width: 100%;
`;

export const Box = styled.div<
    SpaceProps &
        FlexboxProps &
        PositionProps &
        LayoutProps &
        ShadowProps &
        BordersProps &
        ColorProps & { jc?: 'space-between' | 'flex-start' }
>`
  ${space}
  ${flexbox}
  ${position}
  ${layout}
  ${shadow}
  ${borders}
  ${color}
  ${props => props.jc && `justify-content: ${props.jc}`}
`;
export const Cover = styled(Box)<
    SpaceProps &
        LayoutProps & { bgUrl: string } & { withShadow?: boolean } & {
            isSelected?: boolean;
        }
>`
  ${space}
  ${layout}
  background: #fff url(${props => props.bgUrl}) center/cover no-repeat;
  box-shadow: ${props =>
      props.withShadow ? '0px 4px 5px rgba(0,0,0,0.3)' : 'none'};
  display: block;
`;

export const CoverImage = styled.img<
    SpaceProps &
        LayoutProps & {
            withShadow?: boolean;
            width?: string;
            isLoading: boolean;
        } & { centered?: boolean } & { withCursor?: boolean }
>`
  ${space}
  ${layout}
  ${props =>
      props.isLoading &&
      `
      border: 1px solid #fff;
      background-color: #000 !important;
    `}
  box-shadow: ${props =>
      props.withShadow ? '-15px 0px 20px rgba(0,0,0,0.4)' : 'none'};
  display: block;
  margin: ${props => props.margin || 'auto'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '360px'};
  background-color: white !important;
  object-fit: ${props => (props.centered ? 'contain' : 'cover')};
  &:hover {
    cursor: ${props => props.withCursor && 'pointer'}
  }
`;

export const Button = styled.button<
    BordersProps &
        SpaceProps &
        ColorProps &
        TypographyProps &
        LayoutProps & { withoutBorder?: boolean }
>`
  appearance: none;
  text-align: center;
  border: ${props => (props.withoutBorder ? '0' : themeGet('borders.1'))};
  font-family: ${themeGet('fonts.1')};
  background-color: ${themeGet('colors.white')};
  padding: 20px 0;
  display: block;
  outline: 0;
  width: 100%;
  ${borders}
  ${space}
  ${layout}
  ${color}
  ${typography}
  &:disabled {
    background-color: #000;
  }
`;

export const Tag = styled.div<SpaceProps & FlexboxProps & LayoutProps>`
  ${space}
  ${flexbox}
  ${layout}
  padding: 7px 15px;
  font-size: 11px;
  font-family: UniversLight;
  border: none;
  color: white;
  border-radius: 10px;
`;

export const CountButton = styled.button<SpaceProps & LayoutProps>`
    ${space}
    ${layout}
  outline: none;
    padding: 2px 10px 2px;
    background: #fff;
    width: 30px;
    height: 23px;
    font-size: 15px;
    display: flex;
    font-family: UniversLight;
    justify-content: center;
    align-items: center;
    line-height: 0;
`;

export const Logo = styled.div<{ white?: boolean } & { nm?: boolean }>`
    background: url(${props => (props.white ? logoWhite : logo)}) center/contain
        no-repeat;
    height: 1.5rem;
    margin: ${props => (props.nm ? '0' : '1rem 1.5rem')};
    width: 7.5rem;
`;

export const Href = styled.a<SpaceProps & TypographyProps>`
    ${space}
    ${typography}
  color: #1A67F6;
`;

export const Image = styled.div<{ bg: string }>`
    width: 100%;
    height: 30vh;
    background: ${(props: any) => `url(${props.bg})` || 'transparent'};
    background-position: center;
    background-size: cover;
`;

const commonInputStyles = css`
    appearance: none;
    background-color: #ededed;
    font-family: ${themeGet('fonts.1')};
    padding: 15px 25px;
    width: 100%;
    display: block;
    font-size: ${themeGet('fontSizes.0')};
    line-height: 19px;
    border: none;
    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${themeGet('colors.black')};
        font-family: inherit;
        font-size: inherit;
    }
    :-ms-input-placeholder {
        color: ${themeGet('colors.black')};
        font-family: inherit;
        font-size: inherit;
    }
`;

const BaseInput = styled.input<SpaceProps & { small?: boolean }>`
  ${commonInputStyles}
  ${space}
  ${props =>
      props.small
          ? `
    padding: 15px 20px;
    font-size: 11px;
  `
          : ''}
  &.error {
    box-shadow: 0px 2px 2px #FF450D;
  }
`;

export const Input = (props: any) => (
    <Box mr={props.mr}>
        <Box display="flex" justifyContent="space-between">
            <Text t type={props.mobile ? 'mbody3' : 'body3'}>
                {props.placeholder}
            </Text>
            {props.error && (
                <Text type={props.mobile ? 'mbody3' : 'body3'} color="#fa2c2c">
                    {props.error}
                </Text>
            )}
        </Box>
        <BaseInput
            {...props}
            className={props.error && 'error'}
            placeholder={''}
        />
    </Box>
);

const BaseTextarea = styled.textarea<SpaceProps & { small?: boolean }>`
  ${commonInputStyles}
  ${space}
  ${props =>
      props.small
          ? `
    padding: 15px 20px;
    font-size: 11px;
  `
          : ''}
    resize: vertical;
    min-height: 100px;
`;

export const Textarea = (props: any) => (
    <Box mr={props.mr}>
        <Text t type={props.mobile ? 'mbody3' : 'body3'}>
            {props.placeholder}
        </Text>
        <BaseTextarea {...props} placeholder={''} />
    </Box>
);
export const CommentTextarea = styled(Textarea)`
    border: 2px solid #000000;
    padding: 40px 85px 25px;
    background: transparent;
    font-size: 15px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.9);
`;

export const BookTextarea = (props: any) => (
    <Box mr={props.mr}>
        <BaseTextarea {...props} placeholder={props.placeholder} />
    </Box>
);

export const BookCommentTextarea = styled(BookTextarea)`
    border: 2px solid #000000;
    padding: 40px 85px 25px;
    background: transparent;
    font-size: 15px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.9);
`;

// export const TextareaBox = styled(Box)`
//     position: relative;
//     &::before {
//         background: url(${arrowRight}) center/contain no-repeat;
//         display: block;
//         content: '';
//         position: absolute;
//         right: 20px;
//         bottom: 15px;
//         width: 32px;
//         height: 22px;
//     }
// `;

// export class CommentTextarea extends PureComponent<any> {
//     render() {
//         return (
//             <TextareaBox>
//                 <StyledTextarea />
//             </TextareaBox>
//         );
//     }
// }

export const Dots = styled.div<{ isFeature?: boolean; isMobile?: boolean }>`
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
        font-size: 0;
        border-radius: 100px;
        border: ${props =>
            props.isMobile
                ? '1px solid rgb(0, 0, 0)'
                : '2px solid rgb(0, 0, 0)'};
        margin-right: 9px;
        display: inline-block;
        width: ${props => (props.isMobile ? '10px' : '14px')};
        height: ${props => (props.isMobile ? '10px' : '14px')};
        &.slick-active {
            background: rgb(0, 0, 0);
        }
    }
`;

export const PayButton = styled(Button)`
    border-radius: 0;
    transition: border-radius 0.3s ease;
    cursor: pointer;
    &:focus:not(:disabled) {
        outline: none;
        border-radius: 20px;
        transition: border-radius 0.3s ease;
    }
    &:hover:not(:disabled) {
        border-radius: 20px;
        transition: border-radius 0.3s ease;
    }
    &:active {
        background: black;
        color: white;
    }
    &:disabled {
        opacity: 1;
        background: #000;
        cursor: auto;
    }
`;

const BaseListHeader = styled.div<
    SpaceProps &
        LayoutProps & { color?: string } & { background?: string } & {
            size?: 'small' | 'big';
        } & {
            bt?: boolean;
        }
>`
    ${space};
    ${layout};
    vertical-align: middle;
    text-align: ${(props: any) => (props.size === 'big' ? 'left' : 'center')};
    border-bottom: ${(props: any) => props.size === 'big' && '1px solid #000'};
    border-top: ${(props: any) => props.bt && '1px solid #000'};
    color: ${(props: any) => props.color || '#000'};
    font-family: 'Fugue';
    position: ${props => (props.size === 'big' ? 'relative' : 'sticky')};
    top: ${props => (props.size === 'big' ? 'auto' : '140px')};
    background: ${(props: any) => props.background || 'transparent'};
    padding: ${(props: any) =>
        props.size === 'big' ? '30px 20px' : '15px 0 10px'};
    font-size: ${(props: any) => (props.size === 'big' ? '35px' : '17px')};
    div {
        position: absolute;
        left: 25px;
    }
`;

export class ListHeader extends PureComponent<any> {
    render() {
        return this.props.to ? (
            <Link to={this.props.to}>
                <BaseListHeader {...this.props}>
                    {this.props.children}
                </BaseListHeader>
            </Link>
        ) : (
            <BaseListHeader {...this.props}>
                {this.props.children}
            </BaseListHeader>
        );
    }
}
