import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowsHeader } from 'Src/components';
import PressHeading from 'Src/components/animations/PressHeading';
import { Box } from 'Src/components/basic';
import SeeAllButton from 'Src/components/SeeAllButton.tsx';
import { AppContext } from 'Src/model';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 7rem auto auto;
`;
const Heading = styled.div`
    grid-column: 1 / span 2;
    border-top: solid rgb(0, 0, 0);
    border-width: 2px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    > *:not(*:last-of-type) {
        border-right: 2px solid rgb(0, 0, 0);
    }
`;
const Slot = styled.div`
    overflow: hidden;
`;
const SlotLeftVertical = styled(Slot)`
    grid-row: 2 / span 2;
    border-right: 2px solid rgb(0, 0, 0);
`;
const SlotRightBottom = styled(Slot)`
    align-self: end;
`;

const NewPopular = function NewPopular(props: any) {
    const [merchPage, setMerchPage] = useState(0);
    const [popularPage, setPopularPage] = useState(0);

    const appContext: any = useContext(AppContext);

    return (
        <Container>
            <Heading>
                <ArrowsHeader
                    updateCount={props.updateCount('merch')}
                    t
                    page={merchPage}
                    setPage={setMerchPage}
                    onClick={() =>
                        props.history.push(`/${appContext.locale.value}/merch`)
                    }
                >
                    headers.merch
                </ArrowsHeader>
                <ArrowsHeader
                    updateCount={props.updateCount('popular')}
                    t
                    page={popularPage}
                    setPage={setPopularPage}
                    onClick={null}
                >
                    headers.popular
                </ArrowsHeader>
            </Heading>
            <SlotLeftVertical>
                <>
                    {React.cloneElement(props.children[0], { page: merchPage })}
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        padding="0 0 30px"
                    >
                        <SeeAllButton to={`/${appContext.locale.value}/merch`}>
                            ui.see_all
                        </SeeAllButton>
                    </Box>
                </>
            </SlotLeftVertical>
            <Slot>
                {React.cloneElement(props.children[1], { page: popularPage })}
            </Slot>
            <SlotRightBottom>
                <PressHeading />
            </SlotRightBottom>
        </Container>
    );
};

export default withRouter(NewPopular);
