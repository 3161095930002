import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import Grid from 'Src/components/blocks/Grid';
import Icon from 'Src/components/Icon';
import PageDelimeter from 'Src/components/PageDelimeter';
import PageHeader from 'Src/components/PageHeader';
import Select from 'Src/components/Select';
import Book from 'Src/components/stickers/Book';

const Container = styled.div`
    padding: 3rem 3.75rem;
    position: relative;
    min-height: 1500px;
`;
const Content = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
    grid-template-areas: 'side-bar items items items';
`;
const Sidebar = styled.div<{ top: number }>`
    grid-area: side-bar;
    width: 14.5rem;
    position: sticky;
    top: ${props => `${props.top}px`};
    bottom: 10000px;
`;
const MockBox = styled.div`
    grid-area: side-bar;
    width: 14.5rem;
    min-height: 100vh;
`;
const GridView = styled.div`
    grid-area: items;
    display: grid;
    grid-row-gap: 4rem;
    justify-content: space-between;
    grid-template-columns: repeat(3, 14.5rem);
    grid-template-rows: repeat(4, auto);
`;
const ListView = styled.div`
    grid-area: items;
`;
const IconBox = styled(Box)`
    position: absolute;
    right: 60px;
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: -51px;
`;
const DropFilters = styled(Text)`
    color: #1a67f7;
    margin-top: 20px;
    cursor: pointer;
`;
const EmptyText = styled(Text)`
    padding: 50px 60px 0;
    grid-column-start: 1;
    grid-column-end: 23;
`;

const ViewIcon = styled(Icon)<{ activeName: string }>`
    opacity: ${props => (props.name === props.activeName ? 1 : 0.6)};
    width: 40px;
    height: 25px;
`;

class Filtered extends React.PureComponent<any> {
    state = {
        sidebarHeight: 20,
    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    handleDropFiltersClick = () => {
        this.props.setFilterValue('topics', []);
        this.props.setFilterValue('authors', []);
        this.props.setFilterValue('languages', []);
    };
    handleFilterClick = () => {
        const sidebar = document.getElementById('sidebar');

        if (sidebar) {
            this.setState({
                sidebarHeight:
                    sidebar.clientHeight > window.innerHeight
                        ? -sidebar.clientHeight + window.innerHeight - 20
                        : 20,
            });
        }
    };
    public render() {
        const {
            view,
            books,
            popular,
            filter,
            filterValues,
            topicsSelect,
            authorsSelect,
            setFilterValue,
            setView,
        } = this.props;

        const View = view === 'grid' ? GridView : ListView;
        const bookPresentation = view === 'grid' ? 'card' : 'line';

        const withoutFilters = Object.values(this.props.filter)
            .filter((_filter: any) => typeof _filter === 'object')
            .every((_filter: any) => _filter.length === 0);

        return (
            <>
                <PageHeader
                    onClick={this.handleDropFiltersClick}
                    title={'headers.strelka_press_books'}
                >
                    <IconBox>
                        <ViewIcon
                            marginRight="1rem"
                            activeName={view}
                            name="grid"
                            onClick={() => setView('grid')}
                        />
                        <ViewIcon
                            activeName={view}
                            name="list"
                            onClick={() => setView('list')}
                        />
                    </IconBox>
                </PageHeader>
                <Container>
                    <Content>
                        <MockBox>
                            <Sidebar
                                id="sidebar"
                                top={this.state.sidebarHeight}
                            >
                                {filterValues && (
                                    <>
                                        <Select
                                            title="ui.theme"
                                            t
                                            initial={filter.topics}
                                            items={topicsSelect}
                                            value={filter.topics}
                                            handleOpenCollapseClick={
                                                this.handleFilterClick
                                            }
                                            onChange={selected =>
                                                setFilterValue(
                                                    'topics',
                                                    selected,
                                                )
                                            }
                                        />
                                        <Select
                                            title="ui.author"
                                            t
                                            initial={filter.authors}
                                            items={authorsSelect}
                                            value={filter.authors}
                                            handleOpenCollapseClick={
                                                this.handleFilterClick
                                            }
                                            onChange={selected =>
                                                setFilterValue(
                                                    'authors',
                                                    selected,
                                                )
                                            }
                                        />
                                    </>
                                )}
                                {!withoutFilters && (
                                    <DropFilters
                                        t
                                        type="body2"
                                        onClick={this.handleDropFiltersClick}
                                    >
                                        ui.drop_filters
                                    </DropFilters>
                                )}
                            </Sidebar>
                        </MockBox>
                        <View>
                            {books.length === 0 ? (
                                <EmptyText type="body1" t>
                                    ui.nothing_found
                                </EmptyText>
                            ) : (
                                books.map((book: any) => (
                                    <Book
                                        key={book.id}
                                        {...book}
                                        presentation={bookPresentation}
                                        withButtonPrice={false}
                                    />
                                ))
                            )}
                        </View>
                    </Content>
                </Container>
                <PageDelimeter
                    title="headers.popular"
                    margin="0 0 20px"
                    color="#fff"
                    background="#EA3DF7"
                />
                <Grid rows={1} cols={4} direction="horizontal">
                    {popular.map((book: any) => (
                        <Book key={book.id} {...book} />
                    ))}
                </Grid>
            </>
        );
    }
}

export default withRouter(Filtered);
