import * as React from 'react';

import Grid from 'Src/components/blocks/Grid';
import Book from 'Src/components/stickers/Book';

export default function Block(props: any) {
    if (props.type === 'grid-scrollable') {
        return (
            <Grid {...props.props} count={props.count} page={props.page}>
                {props.children.map((book: any) => (
                    <Book key={book.id} {...book} />
                ))}
            </Grid>
        );
    } else {
        return <div>block {props.type} not found</div>;
    }
}
