import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Desktop from './Desktop';
import Mobile from './Mobile';

export default function() {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return isMobile ? <Mobile /> : <Desktop />;
}
