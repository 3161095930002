import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';

const Sidebar = styled.div`
    grid-area: side-bar;
    width: 250px;
    position: absolute;
    height: 203px;
    background: #d8d8d8;
`;

const Content = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
    grid-template-areas: 'side-bar items items items';
`;

const Container = styled.div`
    height: 253px;
    background-color: #ededed;
    margin-bottom: 25.5px;
`;

const GridView = styled.div`
    grid-area: items;
`;

const getCards = function(finish: any) {
    const cards = [];

    for (let i = 0; i < finish; i++) {
        cards[i] = <Container />;
    }

    return cards;
};

export default function() {
    return (
        <Box>
            <Box margin="217px 61.5px 4rem" position="relative">
                <Sidebar />
                <Content>
                    <GridView>{getCards(7)}</GridView>
                </Content>
            </Box>
        </Box>
    );
}
