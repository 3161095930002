import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text as BaseText } from 'Src/components/basic';
import Icon from 'Src/components/Icon';
import { Cover } from 'Src/components/ResizedImage';

const EmptyText = styled(BaseText)`
    font-family: WilliamDisplay;
    font-size: 36px;
    line-height: 42px;
`;
const FavoriteContent = styled(Box)`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    padding: 20px;
`;
const BoxLeftCenter = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const TextBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -40px;
`;
const BoxRightCenter = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Favorite = (props: any) => (
    <FavoriteContent>
        <BoxLeftCenter>
            <Cover
                path={props.images.find((image: any) => image.isCover).path}
                height={177}
                margin="0"
            />
        </BoxLeftCenter>
        <TextBox>
            <BaseText
                fontSize="15px"
                marginBottom="17px"
                fontFamily="1"
                lineHeight="18px"
                args={props.title}
                t
            >
                ui.book_title
            </BaseText>
            <u>
                <Link to={`/items/${props.id}`}>
                    <BaseText t fontSize="15px" fontFamily="2">
                        ui.go_to_item
                    </BaseText>
                </Link>
            </u>
        </TextBox>
        <BoxRightCenter>
            <BaseText t args={props.price / 100} fontSize="15px" fontFamily="1">
                ui.price
            </BaseText>
        </BoxRightCenter>
        <BoxRightCenter>
            <Icon name="cross" size={17} onClick={props.onCloseClick} />
        </BoxRightCenter>
    </FavoriteContent>
);

export default class extends PureComponent<any> {
    state: any = {
        items: this.props.favorites,
    };
    handleUnsetFavorite = (id: number) => {
        this.props.unsetFavorite(id);
        this.setState((state: any) => ({
            items: state.items.filter((fav: any) => fav.id !== id),
        }));
    };
    render() {
        return this.props.favorites.length === 0 ? (
            <React.Fragment>
                <EmptyText t>profile.no_favorites</EmptyText>
                <u>
                    <Link to="/items">
                        <BaseText t>ui.go_to_shop</BaseText>
                    </Link>
                </u>
            </React.Fragment>
        ) : (
            <React.Fragment>
                {this.state.items.map((favorite: any, index: number) => (
                    <Favorite
                        {...favorite}
                        key={index}
                        onCloseClick={() =>
                            this.handleUnsetFavorite(favorite.id)
                        }
                    />
                ))}
            </React.Fragment>
        );
    }
}
