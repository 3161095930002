// import { themeGet } from '@styled-system/theme-get';
import * as React from 'react';
import MediaQuery from 'react-responsive';

import { AppContext } from 'Src/model';
import { Find as FindDesktop } from 'Src/pages';
import { Find as FindMobile } from 'Src/pages/mobile';

export default class extends React.Component<any> {
    static contextType = AppContext;
    state: any = {
        books: [],
    }; // todo: add state interface

    async fetchBooks() {
        const books = await this.context.books.getAll();

        this.setState({ books: books });
    }

    componentDidMount() {
        this.fetchBooks();
    }
    render() {
        return (
            <>
                <MediaQuery maxDeviceWidth={600}>
                    <FindMobile {...this.state} />
                </MediaQuery>
                <MediaQuery minDeviceWidth={601}>
                    <FindDesktop {...this.state} />
                </MediaQuery>
            </>
        );
    }
}
