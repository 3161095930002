// import { themeGet } from '@styled-system/theme-get';
import * as React from 'react';
import MediaQuery from 'react-responsive';

import { Profile as ProfileDesktop } from 'Src/pages';
import { Profile as ProfileMobile } from 'Src/pages/mobile';

export default function() {
    return (
        <React.Fragment>
            <MediaQuery maxDeviceWidth={600}>
                <ProfileMobile />
            </MediaQuery>
            <MediaQuery minDeviceWidth={601}>
                <ProfileDesktop />
            </MediaQuery>
        </React.Fragment>
    );
}
