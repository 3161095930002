import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';
import Card from 'Src/pages/PageLoaders/Card';

const GridView = styled.div`
    grid-area: items;
    display: grid;
    grid-row-gap: 4rem;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
`;

const getCards = function(finish: any) {
    const cards = [];

    for (let i = 0; i < finish; i++) {
        cards[i] = <Card />;
    }

    return cards;
};

export default function() {
    return (
        <Box>
            <Box display="flex" margin="50px 61px 54px">
                <Box marginRight="107.5px">
                    <Box
                        width="486px"
                        height="470px"
                        marginBottom="42.5px"
                        backgroundColor="#EDEDED"
                    />
                    <Box
                        width="230.5px"
                        height="162.5px"
                        marginBottom="42.5px"
                        backgroundColor="#EDEDED"
                    />
                    <Box
                        width="296px"
                        height="62.5px"
                        backgroundColor="#EDEDED"
                    />
                </Box>
                <Box width="100%">
                    <Box
                        height="200px"
                        marginBottom="33.5px"
                        backgroundColor="#D8D8D8"
                    />
                    <Box
                        height="55px"
                        marginBottom="53.5px"
                        backgroundColor="#D8D8D8"
                    />
                    <Box
                        height="128px"
                        marginBottom="46px"
                        backgroundColor="#D8D8D8"
                    />
                    <Box
                        height="159px"
                        marginBottom="45px"
                        backgroundColor="#D8D8D8"
                    />
                    <Box height="159px" backgroundColor="#D8D8D8" />
                </Box>
            </Box>
            <Box backgroundColor="#D8D8D8" width="100%" height="43px" />
            <Box margin="59px 61.5px">
                <GridView>{getCards(4)}</GridView>
            </Box>
        </Box>
    );
}
