import * as React from 'react';
import styled from 'styled-components';

import { Box, Text as BaseText } from 'Src/components/basic';
import Icon from 'Src/components/Icon';

const StyledBox = styled(Box)<{ zeroPadding?: boolean }>`
    padding: ${props =>
        props.zeroPadding ? '30px 0 30px 65px;' : '30px 65px 30px 65px'};
    border-right: 2px solid rgb(0, 0, 0);
    flex-grow: 1;
    @media (max-width: 1300px) {
        padding: ${props =>
            props.zeroPadding ? '30px 0 30px 55px' : '30px 55px 30px 55px'};
    }
    &:last-child {
        border-color: white;
    }
    width: calc(100% / 4);
    box-sizing: border-box;
`;

const NoWrapText = styled(BaseText)`
    white-space: nowrap;
`;

// const StyledBaseText = styled(BaseText)`
//     display: inline;
// `;

const StyledLink = styled.a``;

const HeaderText = styled(BaseText)`
    text-transform: uppercase;
`;

const TextBox = styled(Box)`
    display: inline;
    white-space: nowrap;
    margin-right: 10px;
`;

export default function AppFooter() {
    return (
        <Box
            color="black"
            backgroundColor="white"
            borderTop="2px solid rgb(0, 0, 0)"
            borderBottom="2px solid rgb(0, 0, 0)"
        >
            <Box display="flex" justifyContent="space-between">
                <StyledBox>
                    <HeaderText type="h4" t>
                        footer.contact_us
                    </HeaderText>
                    <BaseText type="body2" mt="3">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="mailto:books@strelka.com"
                        >
                            books@strelka.com
                        </a>
                        <br />
                        +7 (495) 268 06 19
                    </BaseText>
                </StyledBox>
                <StyledBox>
                    <HeaderText type="h4" t>
                        footer.find_us
                    </HeaderText>
                    <BaseText type="body2" mt="3" t>
                        footer.address
                    </BaseText>
                </StyledBox>
                <StyledBox>
                    <HeaderText type="h4" t>
                        footer.follow_us
                    </HeaderText>
                    <BaseText type="body2" mt="3">
                        <TextBox>
                            <StyledLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/StrelkaPress/?ref=bookmarks"
                            >
                                Facebook
                            </StyledLink>
                            <Icon mb="-4px" name="arrowTopRight" size={18} />
                        </TextBox>
                        <TextBox>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/strelka_institute/"
                            >
                                Instagram
                            </a>
                            <Icon mb="-4px" name="arrowTopRight" size={18} />
                        </TextBox>
                    </BaseText>
                    {/* <StyledLink target="_blank" rel="noopener noreferrer">
                        <StyledBaseText type="body2" t>
                            footer.follow
                        </StyledBaseText>
                    </StyledLink> */}
                </StyledBox>
                <StyledBox zeroPadding>
                    <HeaderText whiteSpace="wrap" type="h4" t>
                        footer.strelka
                    </HeaderText>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/rules.pdf"
                    >
                        <Box display="flex" mt="3">
                            <NoWrapText type="body2" t>
                                footer.terms_and_conditions
                            </NoWrapText>
                            <Icon name="arrowTopRight" size={18} />
                        </Box>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/privacy-policy.pdf"
                    >
                        <Box display="flex">
                            <BaseText type="body2" t>
                                footer.privacy_policy
                            </BaseText>
                            <Icon name="arrowTopRight" size={18} />
                        </Box>
                    </a>
                </StyledBox>
            </Box>
        </Box>
    );
}
