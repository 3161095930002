import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import { Cover } from 'Src/components/ResizedImage';
import { AppContext } from 'Src/model';
import { cutString } from 'Src/utils/helpers';

const LinkBlock = styled(Link)`
    display: block;
`;

const HoveredLinkBlock = styled(LinkBlock)`
    padding: 20px;
    z-index: 0;
    width: 100%;
    margin-bottom: 30px;
    &:active {
        background: #ededed;
    }
`;

const SingleGridLinkBLock = styled(HoveredLinkBlock)`
    background-color: #ededed;
    padding: 20px 15px 0;
    margin-bottom: 20px;
`;

export default function Book(props: any) {
    const _props = props.props || props;
    // const authors = _props.authors.map((a: any) => a.name).join(', ');
    const authors =
        _props.authors &&
        _props.authors.map((_item: any) => _item.name).join(', ');
    const cover =
        _props.images && _props.images.find((img: any) => Boolean(img.isCover));
    const coverImageUrl = cover ? cover.path : '';
    const context: any = useContext(AppContext);
    const link = `/${context.locale.value}/items/${_props.id}`;

    if (_props.presentation === 'line') {
        return (
            // <Box width="100%" >
            <HoveredLinkBlock to={link}>
                <Box display="flex" justifyContent="flex-start">
                    <Box style={{ width: '112px' }}>
                        <Cover
                            path={coverImageUrl}
                            height={112}
                            margin="0"
                            objectFit={'cover'}
                        />
                    </Box>
                    <Box width="100%" maxWidth="170px" ml="-10px">
                        <Text type="mh3" textTransform="uppercase">
                            {_props.title}
                        </Text>
                        <Text
                            type="mh3"
                            pt="10px"
                            pb="12px"
                            textTransform="uppercase"
                        >
                            {authors}
                        </Text>
                        {_props.isInStore ? (
                            <Text
                                type="mh3"
                                pt={2}
                                mb="40px"
                                textTransform="uppercase"
                                args={_props.price / 100}
                                t
                            >
                                ui.price
                            </Text>
                        ) : (
                            <Text type="mh3" textTransform="uppercase">
                                нет в наличии
                            </Text>
                        )}
                    </Box>
                </Box>
            </HoveredLinkBlock>
            // </Box>
        );
    } else if (_props.presentation === 'singleGrid') {
        return (
            <SingleGridLinkBLock to={link}>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Cover
                        height={232.45}
                        path={coverImageUrl}
                        margin="auto"
                        objectFit={'cover'}
                    />
                    <Box padding="10px 0 0" width="100%">
                        <Text pt={3} type="mh3" textTransform="uppercase">
                            {_props.title &&
                                cutString(
                                    _props.title,
                                    window.outerWidth > 356 ? 42 : 35,
                                )}
                        </Text>
                        {_props.isInStore ? (
                            <Text
                                type="mh3"
                                pt={2}
                                mb="40px"
                                textTransform="uppercase"
                                args={_props.price / 100}
                                t
                            >
                                ui.price
                            </Text>
                        ) : (
                            <Text type="mh3" textTransform="uppercase">
                                нет в наличии
                            </Text>
                        )}
                    </Box>
                </Box>
            </SingleGridLinkBLock>
        );
    } else {
        return (
            <LinkBlock to={link}>
                <Box width="40vw">
                    <Cover height={140} path={coverImageUrl} margin="0" />
                    <Text pt={3} type="mh3" textTransform="uppercase">
                        {_props.title &&
                            cutString(
                                _props.title,
                                window.outerWidth > 356 ? 42 : 35,
                            )}
                    </Text>
                    {_props.isInStore ? (
                        <Text
                            type="mh3"
                            pt={2}
                            mb="40px"
                            textTransform="uppercase"
                            args={_props.price / 100}
                            t
                        >
                            ui.price
                        </Text>
                    ) : (
                        <Text
                            type="mh3"
                            pt={2}
                            mb="40px"
                            textTransform="uppercase"
                        >
                            нет в наличии
                        </Text>
                    )}
                </Box>
            </LinkBlock>
        );
    }
}
