/* eslint-disable @typescript-eslint/ban-ts-ignore */
const magic = [30, 60, 90, 130, 145, 230, 351];
const theme = {
    magic,
    fontSizes: [12, 14, 16, 20, 24, 32, 36, 44],
    space: [0, 4, 8, 16, 32, 64, 128],
    sizes: [0, 4, 8, 16, 32, 64, 128],
    shadows: ['0 2px 2px rgba(0, 0, 0, 0.5)'],
    borders: ['1px solid rgb(0, 0, 0)', '2px solid rgb(0, 0, 0)'],
    fonts: [
        'Fugue',
        'UniversRoman',
        'UniversLight',
        'William',
        'WilliamDisplay',
    ],
    colors: {
        black: '#000',
        white: '#fff',
        blue: '#1A67F6',
        shade: 'rgba(0,0,0,0.1)',
    },
};

// @ts-ignore
theme.space.magic = magic;
// @ts-ignore
theme.sizes.magic = magic;
// @ts-ignore
theme.fontSizes.magic = magic;

export default theme;
