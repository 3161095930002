// import { themeGet } from '@styled-system/theme-get';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import { PayButton } from 'Src/components/basic';
import Grid from 'Src/components/blocks/Grid';
import Counter from 'Src/components/Counter';
import Icon from 'Src/components/Icon';
import PageDelimeter from 'Src/components/PageDelimeter';
import PageHeader from 'Src/components/PageHeader';
import { Cover } from 'Src/components/ResizedImage';
import Book from 'Src/components/stickers/Book';
import { withContext } from 'Src/model';

const Row = styled(Box)`
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-column-gap: 100px;
    justify-content: space-between;
    align-items: center;
`;
const RemoveButton = styled(Icon)`
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
`;

function CartBook(props: any) {
    return (
        <Row key={props.id} py="magic.0" borderBottom="2px solid #000">
            <RemoveButton
                name="cross"
                mt="magic.0"
                mr="2"
                onClick={props.onRemove}
            />
            <Box ml="auto">
                <Link to={`/items/${props.id}`}>
                    <Cover
                        height={150}
                        margin="0"
                        path={
                            props.images.find((_image: any) => _image.isCover)
                                .path
                        }
                    />
                </Link>
            </Box>
            <Box>
                {props.isPreorder && (
                    <Text
                        t={props.type === 'books'}
                        type="h4"
                        textTransform="uppercase"
                        color={'#1A67F6'}
                    >
                        ui.preorder
                    </Text>
                )}
                <Text
                    t={props.type === 'books'}
                    type="h4"
                    textTransform="uppercase"
                    args={props.title}
                >
                    {props.type === 'books'
                        ? 'ui.book_title'
                        : `${props.title}, ${Object.values(props.variants)}`}
                </Text>
                {props.type === 'books' && (
                    <Text t margin="20px 0 0 0" type="body2" args={props.title}>
                        {!props.isDigital
                            ? 'ui.book_paper_version'
                            : 'ui.book_e_version'}
                    </Text>
                )}
            </Box>
            <Counter
                noBorder={true}
                value={props.amount}
                onIncrement={props.onIncrement}
                onDecrement={props.onDecrement}
            />
            <Text
                t
                type="h4"
                textTransform="uppercase"
                args={(props.amount * props.price) / 100}
            >
                ui.price
            </Text>
        </Row>
    );
}

class Cart extends React.Component<any> {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.context.popups.setHolydays(true);
    }
    componentDidUpdate = (prevProps: any) => {
        if (prevProps.match.url !== this.props.match.url) {
            window.scrollTo(0, 0);
        }
    };
    shouldComponentUpdate = (nextProps: any) => {
        if (
            nextProps.context.bag.books.length !==
            this.props.context.bag.books.length
        ) {
            return true;
        }

        return true;
    };
    render() {
        return (
            <Box>
                <PageHeader
                    count={this.props.context.bag.books.reduce(
                        (amount: any, book: any) => amount + book.amount,
                        0,
                    )}
                    title={`headers.cart`}
                />
                {this.props.context.bag.books.length > 0 ? (
                    <Box pt="magic.1" px="magic.1" pb="magic.2">
                        <Row pb="magic.0">
                            <Text t type="h4" textTransform="uppercase">
                                cart.item
                            </Text>
                            <Text t type="h4" textTransform="uppercase">
                                cart.description
                            </Text>
                            <Text t type="h4" textTransform="uppercase">
                                cart.amount
                            </Text>
                            <Text t type="h4" textTransform="uppercase">
                                cart.sum
                            </Text>
                        </Row>
                        {this.props.context.bag.books.map((book: any) => (
                            <CartBook
                                isPreorder={this.props.context.bag.preorderIds.includes(
                                    book.id,
                                )}
                                key={book.id}
                                {...book}
                                onIncrement={() =>
                                    this.props.context.bag.addBook(book)
                                }
                                onDecrement={() =>
                                    this.props.context.bag.decrementBook(book)
                                }
                                onRemove={() =>
                                    this.props.context.bag.removeBook(book)
                                }
                            />
                        ))}
                        <Row py="magic.1">
                            <Text
                                t
                                type="h4"
                                textTransform="uppercase"
                                style={{ gridColumn: '1/1' }}
                            >
                                ui.total
                            </Text>
                            <Text
                                t
                                type="h4"
                                style={{ gridColumn: '4/4' }}
                                textTransform="uppercase"
                                args={((total: number) =>
                                    this.props.context.bag.books.map(
                                        (_book: any) => {
                                            total += _book.price * _book.amount;
                                        },
                                    ) && total / 100)(0)}
                            >
                                ui.price
                            </Text>
                        </Row>
                        <Link to={`/${this.props.context.locale.value}/order`}>
                            <PayButton
                                color="white"
                                textAlign="center"
                                onClick={() => null}
                                py="3"
                                border="none"
                                bg="#1A67F6"
                                display="block"
                                padding="20px 0 16px"
                            >
                                <Text t type="h3" textTransform="uppercase">
                                    cart.pay_button
                                </Text>
                            </PayButton>
                        </Link>
                    </Box>
                ) : (
                    <React.Fragment>
                        <Box pt="magic.1" px="magic.1" pb="magic.2">
                            <Box py="magic.2">
                                <Text
                                    textAlign="center"
                                    t
                                    type="h4"
                                    textTransform="uppercase"
                                >
                                    cart.empty_cart
                                </Text>
                            </Box>
                        </Box>
                        {this.props.context.lastSee.items.length > 0 && (
                            <React.Fragment>
                                <PageDelimeter
                                    title="headers.are_seen"
                                    background="#EA3DF7"
                                    color="#fff"
                                />
                                <Grid rows={1} cols={4} direction="horizontal">
                                    {this.props.context.lastSee.items.map(
                                        (book: any) => {
                                            const _book = book.items
                                                ? book.items[0]
                                                : book;

                                            return (
                                                <Book
                                                    key={book.id}
                                                    {..._book}
                                                    withButtonPrice={true}
                                                />
                                            );
                                        },
                                    )}
                                </Grid>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Box>
        );
    }
}

export default withContext(withRouter(Cart));
