import React from 'react';

import PressHeading from 'Src/components/animations/PressHeadingMobile';

export default () => {
    return (
        <React.Fragment>
            <PressHeading />
        </React.Fragment>
    );
};
