import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box as BaseBox, Text } from 'Src/components/basic';
import { Header } from 'Src/components/mobile';

const Box = styled(BaseBox)<{ direction?: 'column' | 'row' }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: ${props => props.direction || 'column'};
`;

export default function ShopIndex() {
    return (
        <Box>
            <Header background="#F3FA75" title="404" />
            <Box mt="3vh" ml="20px">
                <Text type="mbody1">Такой страницы нет на сайте</Text>
                <Box direction="row" mt="5px">
                    <Text type="mbody1">Вы можете перейти на&nbsp;</Text>
                    <Link to="/">
                        <Text textDecoration="underline" type="mbody1">
                            главную
                        </Text>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}
