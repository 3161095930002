import _debounce from 'lodash/debounce';
import * as React from 'react';
import MediaQuery from 'react-responsive';
import { RouteComponentProps } from 'react-router';

import { AppContext } from 'Src/model';
import { Filtered as FilteredDesktop } from 'Src/pages';
import { Filtered as FilteredMobile } from 'Src/pages/mobile';
import { Filtered as FilteredLoader } from 'Src/pages/PageLoaders';
import { Filtered as MobileFilteredLoader } from 'Src/pages/PageLoaders/mobile';
import {
    getFiltersFromUri,
    getUriFromFilters,
    uri2obj,
} from 'Src/utils/filters2uri';

interface IShopIndexState {
    filter: IBooksFilter;
    filterValues: IBooksFilterValues | null;
    books: IBookShort[];
    view: 'grid' | 'list';
    popular: IBookShort[];
    pagination: IPagination;
    isPaginationFinished: boolean;
}
class Pagination implements IPagination {
    limit = 200;
    offset = 0;
}
class Filter implements IBooksFilter {
    q = '';
    topics = [];
    authors = [];
    languages = [];
}

export default class Filtered extends React.PureComponent<RouteComponentProps> {
    static contextType = AppContext;
    state: IShopIndexState = {
        filter: new Filter(),
        pagination: new Pagination(),
        books: [],
        view: 'grid',
        popular: [],
        isPaginationFinished: false,
        filterValues: null,
    };
    async fetchFilteredData(append = false) {
        const books = await this.context.books.get(
            this.state.filter,
            this.state.pagination,
        );

        this.setState(
            (state: IShopIndexState): IShopIndexState => ({
                ...state,
                books: append ? [...state.books, ...books] : books,
            }),
        );

        return books;
    }
    debouncedFetchData = _debounce(this.fetchFilteredData, 300);
    async fetchFilterableValues() {
        const filterValues = await this.context.filters.get();

        this.setState(state => ({ ...state, filterValues }));
    }
    async fetchPopular() {
        const popular = await this.context.popular.get();

        this.setState(
            (state: IShopIndexState): IShopIndexState => ({
                ...state,
                popular,
            }),
        );
    }
    setFilterValue(
        field: keyof IBooksFilter,
        value: any /* TODO: strong type*/,
    ) {
        this.setState(
            (state: IShopIndexState): IShopIndexState => ({
                ...state,
                filter: { ...state.filter, [field]: value },
                pagination: new Pagination(),
                isPaginationFinished: false,
            }),
            () => {
                this.debouncedFetchData();
                this.props.history.replace(
                    `/${this.context.locale.value}/books${getUriFromFilters(
                        this.state.filter,
                        this.state.view,
                    )}`,
                );
            },
        );
    }
    setView(view: IShopIndexState['view']) {
        this.setState(
            (state: IShopIndexState): IShopIndexState => ({ ...state, view }),
            () =>
                this.props.history.replace(
                    `/books${getUriFromFilters(
                        this.state.filter,
                        this.state.view,
                    )}`,
                ),
        );
    }
    getFiltersFromUri = (search: string) => {
        const filters = getFiltersFromUri(search);

        this.setState(
            () => ({ filter: filters, view: filters.view }),
            () => this.fetchFilteredData(),
        );
    };
    handleLoadMoreClick = () => {
        this.setState(
            (state: IShopIndexState) => ({
                ...state,
                pagination: {
                    ...state.pagination,
                    offset: state.pagination.offset + state.pagination.limit,
                },
            }),
            () => {
                this.fetchFilteredData(true).then(books => {
                    if (books.length < this.state.pagination.limit) {
                        this.setState((state: IShopIndexState) => ({
                            ...state,
                            isPaginationFinished: true,
                        }));
                    }
                });
            },
        );
    };
    componentDidMount() {
        this.fetchFilteredData();
        this.fetchFilterableValues();
        this.fetchPopular();
        this.getFiltersFromUri(this.props.location.search);

        this.setState({
            view: uri2obj(this.props.location.search).view || 'grid',
        });
    }
    public render() {
        const { view, books, popular, filter, filterValues } = this.state;
        let topicsSelect: ISelectItem[] = [];
        let authorsSelect: ISelectItem[] = [];
        let languagesSelect: ISelectItem[] = [];

        if (filterValues) {
            topicsSelect = filterValues.topics.map(item => ({
                value: item.topic_id,
                name: item.name,
            }));
            authorsSelect = filterValues.authors.map(item => ({
                value: item.authorId,
                name: item.name,
            }));
            languagesSelect = filterValues.languages.map(item => ({
                value: item.language,
                name: item.language,
            }));
        }

        return filterValues ? (
            <React.Fragment>
                <MediaQuery maxDeviceWidth={600}>
                    <FilteredMobile
                        view={view}
                        books={books}
                        popular={popular}
                        filter={filter}
                        filterValues={filterValues}
                        topicsSelect={topicsSelect}
                        authorsSelect={authorsSelect}
                        languagesSelect={languagesSelect}
                        setFilterValue={this.setFilterValue.bind(this)}
                        setView={this.setView.bind(this)}
                        onLoadMoreClick={this.handleLoadMoreClick}
                        isMaxBooks={this.state.isPaginationFinished}
                    />
                </MediaQuery>
                <MediaQuery minDeviceWidth={601}>
                    <FilteredDesktop
                        view={view}
                        books={books}
                        popular={popular}
                        filter={filter}
                        filterValues={filterValues}
                        topicsSelect={topicsSelect}
                        authorsSelect={authorsSelect}
                        languagesSelect={languagesSelect}
                        setFilterValue={this.setFilterValue.bind(this)}
                        setView={this.setView.bind(this)}
                        onLoadMoreClick={this.handleLoadMoreClick}
                        isMaxBooks={this.state.isPaginationFinished}
                    />
                </MediaQuery>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <MediaQuery minDeviceWidth={601}>
                    <FilteredLoader />
                </MediaQuery>
                <MediaQuery maxDeviceWidth={600}>
                    <MobileFilteredLoader />
                </MediaQuery>
            </React.Fragment>
        );
    }
}
