import React, { useState } from 'react';
import styled from 'styled-components';

import { Box as BaseBox, Text as BaseText } from 'Src/components/basic';

const Text = styled(BaseText)`
    user-select: none;
`;
const Box = styled(BaseBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Heading = styled(Box)`
    border: solid rgb(0, 0, 0);
    border-width: 2px 0 0;
    height: 34rem;
    grid-template-columns: none;
    grid-column: none;
    display: flex;
    &:hover {
        cursor: pointer;
    }
    > *:not(*:last-of-type) {
        border-right: 2px solid rgb(0, 0, 0);
    }
`;

export default React.memo(function PressHeading() {
    const [isVisible, showText] = useState(false);

    return (
        <Heading
            onMouseEnter={() => showText(true)}
            onMouseLeave={() => showText(false)}
        >
            {isVisible ? (
                <Text type="body1" className="visual-text" t margin="0 2.5rem">
                    texts.about
                </Text>
            ) : (
                <Box>
                    <Text type="h1" textTransform="uppercase" t>
                        headers.strelka_press
                    </Text>
                </Box>
            )}
        </Heading>
    );
});
