import React from 'react';

import { Box, ListHeader } from 'Src/components/basic';
import { Book } from 'Src/components/mobile';

export default class extends React.PureComponent<IBlock> {
    state: IBlock = this.props;
    render() {
        return (
            <Box>
                <ListHeader
                    background={this.state.background}
                    color={this.state.color}
                >
                    {this.state.title}
                </ListHeader>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="15px 20px"
                    flexWrap="wrap"
                >
                    {this.state.children.map((_book: IBookShort) => (
                        <Book {..._book} key={_book.id} />
                    ))}
                </Box>
            </Box>
        );
    }
}
