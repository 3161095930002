import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { Box, Text as BaseText } from 'Src/components/basic';

const borderMoving = keyframes`
  0% {
    background-position: 0px 0px, 110% 100%, 0px 205%, 100% 0px;
  }
  100% {
    background-position: 110% 0px, 0px 100%, 0px 0%, 100% 205%;
  }
`;
const Container = styled(Box)`
    display: grid;
    grid-template-columns: 1.1fr 0.8fr 1.1fr;
    width: 120px;
    position: relative;
    margin-right: 15px;
`;
const Text = styled(BaseText)`
    text-align: center;
    user-select: none;
`;
const Border = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid black;
    z-index: -1;
`;
const AnimatedBorder = styled(Border)`
    border: none;
    background: linear-gradient(90deg, black 50%, transparent 50%),
        linear-gradient(90deg, black 50%, transparent 50%),
        linear-gradient(0deg, black 50%, transparent 50%),
        linear-gradient(0deg, black 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-position: 0px 0px, 100% 100%, 0px 100%, 100% 0px;
    background-size: 6px 2px, 6px 2px, 2px 6px, 2px 6px;
    animation: ${borderMoving} 4s infinite linear;
    z-index: -1;
`;
const TextLeft = styled(Text)`
    text-align: right;
    &:hover {
        cursor: pointer;
    }
`;

const TextRight = styled(Text)`
    text-align: left;
    &:hover {
        cursor: pointer;
    }
`;

export default class Counter extends React.PureComponent<any> {
    state: any = {
        value: this.props.value || 1,
        isHoveredItem: false,
    };
    componentDidUpdate(prevProps: any) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }
    increment = () =>
        this.setState(
            (state: any) => ({ value: state.value + 1 }),
            () => {
                this.props.onChange
                    ? this.props.onChange(this.state.value)
                    : this.props.onIncrement();
            },
        );
    decrement = () =>
        this.setState(
            (state: any) => ({
                value: state.value === 1 ? 1 : state.value - 1,
            }),
            () => {
                this.props.onChange
                    ? this.props.onChange(this.state.value)
                    : this.props.onDecrement();
            },
        );
    render() {
        return (
            <Container
                // {...(this.props.withBorder ? { border: '0' } : {})}
                className={this.props.className}
            >
                <TextLeft type="h4" py="2" onClick={this.decrement}>
                    {'−'}
                </TextLeft>
                <Text type="h4" py="2">
                    {this.state.value}
                </Text>
                <TextRight type="h4" py="2" onClick={this.increment}>
                    {'+'}
                </TextRight>
                <Text>{this.props.noBorder}</Text>
                {this.props.noBorder ? (
                    <Text />
                ) : this.state.isHoveredItem ? (
                    <AnimatedBorder />
                ) : (
                    <Border />
                )}
            </Container>
        );
    }
}
