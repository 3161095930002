import React, { PureComponent } from 'react';
import { Link as BaseLink, withRouter } from 'react-router-dom';
import { createI18n } from 'react-router-i18n';

import { AppContext } from 'Src/model';

import translations from './data';

const locales = ['ru', 'en'];

export const stripLocale = (pathname: string, locale?: string): string => {
    if (!locale) {
        return pathname;
    }

    return pathname.replace(`${locale}/`, '');
};

export const getLocale = (locale: string): string | null => {
    switch (locale) {
        case undefined:
        case 'ru':
            return 'русский';
        case 'en':
            return 'english';
        default:
            return null;
    }
};

class BaseLocaleLink extends PureComponent<any> {
    static contextType = AppContext;
    state: any;
    render() {
        return (
            <BaseLink to={`/${this.context.locale.value}${this.props.to}`}>
                {this.props.children}
            </BaseLink>
        );
    }
}

export const Link = withRouter(BaseLocaleLink);

export default createI18n(locales, translations, '');
