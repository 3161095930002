import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';
import Card from 'Src/pages/PageLoaders/Card';

const Content = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
    grid-template-areas: 'items items items items';
`;

const GridView = styled.div`
    grid-area: items;
    display: grid;
    grid-row-gap: 4rem;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
`;

const getCards = function(finish: any) {
    const cards = [];

    for (let i = 0; i < finish; i++) {
        cards[i] = <Card />;
    }

    return cards;
};

export default function() {
    return (
        <Box>
            <Box margin="217px 61.5px 4rem" position="relative">
                <Content>
                    <GridView>{getCards(16)}</GridView>
                </Content>
            </Box>
            <Box backgroundColor="#D8D8D8" width="100%" height="43px" />
            <Box margin="59px 61.5px">
                <GridView>{getCards(4)}</GridView>
            </Box>
        </Box>
    );
}
