import React, { Fragment, PureComponent } from 'react';

import { Box, Text } from 'Src/components/basic';
import { Book, Header } from 'Src/components/mobile';
import { SearchContext } from 'Src/model/search';

export default class extends PureComponent<any> {
    static contextType = SearchContext;
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Fragment>
                <Header title="РЕЗУЛЬТАТЫ ПОИСКА" background="#F3FA75" />
                <Box margin="20px 20px 0">
                    {this.context.query.length === 0 ? (
                        <Text type="mbody1" t>
                            ui.nothing_found
                        </Text>
                    ) : (
                        <Text>
                            Найдено{' '}
                            {
                                this.props.books.filter((_book: any) =>
                                    _book.title
                                        .toLowerCase()
                                        .includes(
                                            this.context.query.toLowerCase(),
                                        ),
                                ).length
                            }{' '}
                            результатов по запросу «{this.context.query}»:
                        </Text>
                    )}
                </Box>
                <Box
                    margin="20px 20px 0"
                    minHeight="250px"
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                >
                    {this.context.query.length > 0 &&
                        this.props.books
                            .filter((_book: any) =>
                                _book.title
                                    .toLowerCase()
                                    .includes(this.context.query.toLowerCase()),
                            )
                            .map((_book: any, _index: number) => (
                                <Book {..._book} key={_index} />
                            ))}
                </Box>
            </Fragment>
        );
    }
}
