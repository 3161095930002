import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { /* BookCommentTextarea,*/ Box, Text } from 'Src/components/basic';
import Grid from 'Src/components/blocks/Grid';
import BookGallery from 'Src/components/BookGallery';
import Collapse from 'Src/components/Collapse';
// import GoToAuth from 'Src/components/GoToAuth';
// import Icon from 'Src/components/Icon';
import PageDelimeter from 'Src/components/PageDelimeter';
import PageHeader from 'Src/components/PageHeader';
import Book from 'Src/components/stickers/Book';
import { AppContext } from 'Src/model';

const Content = styled(Box)`
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 0px;
`;

const Actions = styled(Box)`
    > * {
        font-size: 2rem;
        margin-left: 2rem;
    }
`;

// const IconBox = styled(Box)`
//   font-family: UniversLTCYR;
//   font-size: 15px;
//   position: absolute;
//   white-space: nowrap;
//   bottom: -75%;
//   left: -50%;
// `;
// const Anchor = styled.a`
//     color: #0c43ed;
//     font-family: UniversRoman;
//     font-size: 22px;
// `;

// const Tag = styled(Text)`
//     display: inline-block;
//     border-radius: 10px;
//     color: white;
//     cursor: pointer;
//     font-family: Fugue;
//     text-transform: uppercase;
//     font-size: 15px;
// `;
// const Comment = (props: any) => (
//     <Box>
//         <Text fontFamily="1" fontSizgie="16px" mb="14px">
//             {`@${props.userName.slice(0, props.userName.indexOf('@'))}`}
//         </Text>
//         <Text fontFamily="4" fontSize="16px" lineHeight="27px" mb="30px">
//             {props.comment}
//         </Text>
//     </Box>
// );
const LeftSideCollapse: React.FunctionComponent<{
    title: string;
    t?: any;
}> = function(props) {
    return (
        <Box width={'magic.5'}>
            <Collapse t={props.t} title={props.title}>
                {props.children}
            </Collapse>
        </Box>
    );
};

class BookPage extends React.PureComponent<any> {
    static contextType = AppContext;
    state = {
        isFavorite: this.props.book.isFavorite,
        heartIconHover: false,
        bookAmount: 1,
        comment: '',
        id: this.props.match.params.id,
    };
    handleSetFavoriteClick = (id: number) => {
        this.props.onFavoriteSet(id);
    };
    handleUnsetFavoriteClick = (id: number) => {
        this.props.onFavoriteUnset(id);
    };
    handleCommentSend = (event: any) => {
        if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
            this.props.onCommentSend(this.state.comment, this.props.book.id);
            this.setState({ comment: '' });
        }
    };
    handleCommentChange = (event: any) => {
        this.setState({ comment: event.target.value });
    };
    handleTagClick = (id: string) => {
        this.props.history.push(`/books?topics=${id}`);
    };
    handleChangeBookAmount = (amount: number) => {
        this.setState({ bookAmount: amount });
    };
    handleTypeChange = (id: string) => this.setState({ id: id });
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ id: this.props.match.params.id });
    }
    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (prevProps.book.isFavorite !== this.state.isFavorite) {
            this.setState({ isFavorite: prevProps.book.isFavorite });
        }

        if (prevProps.match.url !== this.props.match.url) {
            window.scrollTo(0, 0);
        }

        if (prevState.id !== this.state.id) {
            this.props.history.push(
                `/${this.context.locale.value}/items/${this.state.id}`,
            );
        }
    };
    render() {
        const { book, popular, isLoading } = this.props;

        const _book =
            book.find(
                (__book: any) =>
                    __book.id.toString() === this.state.id.toString(),
            ) || book[0];

        // const _variants = book.map((__book: any) => ({
        //     id: __book.id.toString(),
        //     isDigital: __book.isDigital,
        //     price: __book.price,
        // }));

        const authors = _book.authors
            .map((author: any) => author.name)
            .join(', ');

        const aboutAuthor = _book.authors.reduce((sum: any, current: any) => {
            if (current.description !== '') {
                return ++sum;
            }

            return sum;
        }, 0);

        return (
            <React.Fragment>
                <PageHeader
                    onClick={() => this.props.history.push('/books')}
                    title={'headers.strelka_press_books'}
                />
                {_book ? (
                    <Content
                        pt="magic.1"
                        px="magic.1"
                        pb="magic.1"
                        key={_book.id}
                    >
                        <Box>
                            <BookGallery
                                images={
                                    _book.images.length > 0
                                        ? _book.images
                                        : [
                                              book.find(
                                                  (__book: any) =>
                                                      !__book.isDigit,
                                              ).images[0],
                                          ] || [book[0].images[0]]
                                }
                                margin="0 0 0 0"
                            />
                            <Box mt="-30px">
                                {false && (
                                    <LeftSideCollapse t title="book.aboutItem">
                                        <Box mt="10px">
                                            {_book.year && (
                                                <Text
                                                    type="body2"
                                                    mb="10px"
                                                    args={_book.year}
                                                    t
                                                >
                                                    book.year
                                                </Text>
                                            )}
                                            {_book.isbn && (
                                                <Text
                                                    type="body2"
                                                    mb="10px"
                                                    args={_book.isbn}
                                                    t
                                                >
                                                    book.ISBN
                                                </Text>
                                            )}
                                            {_book.pages && (
                                                <Text
                                                    type="body2"
                                                    mb="10px"
                                                    args={_book.pages}
                                                    t
                                                >
                                                    book.pagesAmount
                                                </Text>
                                            )}
                                            {_book.language && (
                                                <Text
                                                    fontFamily="2"
                                                    fontSize="15px"
                                                    mb="10px"
                                                    args={_book.language}
                                                    t
                                                >
                                                    book.language
                                                </Text>
                                            )}
                                            {_book.coverType && (
                                                <Text
                                                    type="body2"
                                                    mb="10px"
                                                    args={_book.coverType}
                                                    t
                                                >
                                                    book.coverType
                                                </Text>
                                            )}
                                        </Box>
                                    </LeftSideCollapse>
                                )}
                                {/* <LeftSideCollapse t title="book.delivery">
                                    <Box mt="10px">
                                        <Text type="body2" mb="10px" t>
                                            texts.delivery1
                                        </Text>
                                        <Text type="body2" mb="10px" t>
                                            texts.delivery2
                                        </Text>
                                        <Text type="body2" mb="10px" t>
                                            texts.delivery3
                                        </Text>
                                        <Text type="body2" mb="10px" t>
                                            texts.delivery4
                                        </Text>
                                    </Box>
                                </LeftSideCollapse>*/}
                                {/* <Box mt="20px">
                                    {book.tags.map((tag: any, index: any) => (
                                        <Tag
                                            backgroundColor={
                                                tagColors[index % 6]
                                            }
                                            onClick={() =>
                                                this.handleTagClick(tag.id)
                                            }
                                            px="4"
                                            py="2"
                                            key={tag}
                                            mr="3"
                                            mb="3"
                                        >
                                            {tag.tag}
                                        </Tag>
                                    ))}
                                </Box>*/}
                            </Box>
                        </Box>
                        <Box>
                            <Text type="h2" textTransform="uppercase">
                                {_book.title}
                            </Text>
                            <Text
                                textTransform="uppercase"
                                mt="30px"
                                mb="40px"
                                type="h4"
                            >
                                {authors}
                            </Text>
                            {/* <Box display="flex">
                                {_book.isInStore ? (
                                    <>
                                        <Ebook
                                            variants={_variants}
                                            current={this.props.match.params.id}
                                            onChange={this.handleTypeChange}
                                        />
                                        <Box
                                            paddingLeft="8px"
                                            paddingRight="8px"
                                        />
                                        <AddAndPrice
                                            item={_book}
                                            isPreorder={this.props.preorderBooksList.includes(
                                                _book.id,
                                            )}
                                            onClick={() =>
                                                this.context.bag.addBook(
                                                    book.find(
                                                        (b: any) =>
                                                            b.id.toString() ===
                                                            this.state.id,
                                                    ),
                                                    this.state.bookAmount,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <Text textTransform="uppercase" type="h4">
                                        нет в наличии
                                    </Text>
                                )}
                            </Box> */}
                            {_book.summary && (
                                <Text mt="magic.1" mb="30px" type="body1">
                                    {_book.summary}
                                </Text>
                            )}
                            {_book.description && (
                                <Collapse t title="book.description">
                                    <Text
                                        type="body2"
                                        my="10px"
                                        dangerouslySetInnerHTML={{
                                            __html: _book.description,
                                        }}
                                    ></Text>
                                </Collapse>
                            )}
                            {/* <Collapse t title="book.delivery">
                                <Box mt="10px">
                                    <Text type="body2" mb="10px" t>
                                        texts.delivery1
                                    </Text>
                                    <Text type="body2" mb="10px" t>
                                        texts.delivery2
                                    </Text>
                                    <Text type="body2" mb="10px" t>
                                        texts.delivery3
                                    </Text>
                                    <Text type="body2" mb="10px" t>
                                        texts.delivery4
                                    </Text>
                                </Box>
                            </Collapse> */}
                            {aboutAuthor > 0 && (
                                <Collapse t title="book.aboutAuthor">
                                    {_book.authors.map(
                                        (a: any) =>
                                            a.description && (
                                                <Box key={a.id} mt="10px">
                                                    <Text
                                                        mb="10px"
                                                        type="body2"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                a.description,
                                                        }}
                                                    />
                                                </Box>
                                            ),
                                    )}
                                </Collapse>
                            )}
                            <Actions
                                display="flex"
                                justifyContent="flex-end"
                                py="magic.0"
                            >
                                <Text
                                    type="h4"
                                    color="#1A67F7"
                                    onClick={() =>
                                        this.state.isFavorite
                                            ? this.handleUnsetFavoriteClick(
                                                  _book.id,
                                              )
                                            : this.handleSetFavoriteClick(
                                                  _book.id,
                                              )
                                    }
                                ></Text>
                            </Actions>
                            {/* <Text
                                textTransform="uppercase"
                                type="h4"
                                mb="40px"
                                args={book.comments.length}
                                t
                            >
                                book.comments
                            </Text>
                            {book.comments.length > 0 && (
                                <Box>
                                    {book.comments.map(
                                        (comment: any, index: number) => (
                                            <Comment {...comment} key={index} />
                                        ),
                                    )}
                                </Box>
                            )}
                            {this.context.auth.isAuthorized ? (
                                <BookCommentTextarea
                                    value={this.state.comment}
                                    onChange={this.handleCommentChange}
                                    placeholder={
                                        this.props.match.params.locale === 'en'
                                            ? 'Add a comment...'
                                            : 'Добавить комментарий...'
                                    }
                                    onKeyDown={this.handleCommentSend}
                                />
                            ) : (
                                <GoToAuth>
                                    <Text
                                        mt="-20px"
                                        color="#1A67F7"
                                        type="body2"
                                        t
                                    >
                                        order.sign_in_to_comment
                                    </Text>
                                </GoToAuth>
                            )}*/}
                        </Box>
                    </Content>
                ) : isLoading ? (
                    'loading'
                ) : (
                    'товар не найден'
                )}
                <PageDelimeter
                    title="headers.also_read"
                    background="#5ECA91"
                    color="#fff"
                />
                <Grid rows={1} cols={4} direction="horizontal">
                    {popular.map((popularBook: any) => (
                        <Book key={popularBook.id} {...popularBook} />
                    ))}
                </Grid>
            </React.Fragment>
        );
    }
}

export default withRouter(BookPage);
