import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import Logout from 'Src/components/Logout';

const StyledText = styled(Text)`
    display: inline;
`;

const LinkText = styled(StyledText)`
    text-decoration: underline;
`;

export default class extends PureComponent<any> {
    render() {
        return (
            <Box>
                <StyledText type="body1" args={this.props.data.username} t>
                    profile.hello
                </StyledText>
                <Logout>
                    <Link to="/">
                        <StyledText type="body1">
                            (
                            <LinkText type="body1" t>
                                profile.log_out
                            </LinkText>
                            )
                        </StyledText>
                    </Link>
                </Logout>
                <Text mt="4" type="body1">
                    <StyledText t>profile.in_account_you_may_view</StyledText>
                    <Link to="/profile/orders">
                        <LinkText t>profile.to_last_orders</LinkText>
                    </Link>
                    ,{' '}
                    <Link to="/profile/favorites">
                        <LinkText t>profile.to_favorites</LinkText>
                    </Link>
                    ,{' '}
                    <Link to="/profile/comments">
                        <LinkText t>profile.to_comments</LinkText>
                    </Link>
                    <StyledText t>profile.and</StyledText>
                    <Link to="/profile/personal">
                        <LinkText t>profile.to_edit_data</LinkText>
                    </Link>
                    .
                </Text>
            </Box>
        );
    }
}
