import React from 'react';
import Slider from 'react-slick';
import styled, { keyframes } from 'styled-components';

import { Box, Dots } from 'Src/components/basic';

const animations = [
    {
        animationName: 'left',
        animation: keyframes`
      0% {
        transform: rotate(-15deg);
        z-index: 11;
      }
      50% {
        transform: rotate(0deg);
        z-index: 11;
      }
      100% {
        transform: rotate(-15deg);
        z-index: 11;
      }
    `,
    },
    {
        animationName: 'leftToBig',
        animation: keyframes`
      0% {
        transform: rotate(-15deg) scale(1);
        z-index: 11;
      }
      50% {
        transform: rotate(0deg) scale(1.05);
        z-index: 11;
      }
      51% {
        z-index: 20;
      }
      100% {
        transform: rotate(-15deg) scale(1.1);
        z-index: 20;
      }
    `,
    },
    {
        animationName: 'leftFromBig',
        animation: keyframes`
      0% {
        transform: rotate(-15deg) scale(1.1);
        z-index: 20;
      }
      50% {
        transform: rotate(0deg) scale(1.05);
        z-index: 20;
      }
      51% {
        z-index: 11;
      }
      100% {
        transform: rotate(-15deg) scale(1);
        z-index: 11;
      }
    `,
    },
    {
        animationName: 'middle',
        animation: keyframes`
      0% {
        transform: rotate(5deg);
      }
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(5deg);
      }
    `,
    },
    {
        animationName: 'middleToBig',
        animation: keyframes`
      0% {
        transform: rotate(5deg) scale(1);
        z-index: 12;
      }
      50% {
        transform: rotate(0deg) scale(1.05);
        z-index: 12;
      }
      51% {
        z-index: 12;
      }
      100% {
        transform: rotate(5deg) scale(1.10);
        z-index: 20;
      }
    `,
    },
    {
        animationName: 'middleFromBig',
        animation: keyframes`
      0% {
        transform: rotate(5deg) scale(1.10);
        z-index: 20;
      }
      50% {
        transform: rotate(0deg) scale(1.05);
        z-index: 20;
      }
      51% {
        z-index: 12;
      }
      100% {
        transform: rotate(5deg) scale(1);
        z-index: 12;
      }
    `,
    },
    {
        animationName: 'right',
        animation: keyframes`
      0% {
        transform: rotate(15deg);
      }
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(15deg);
      }
    `,
    },
    {
        animationName: 'rightToBig',
        animation: keyframes`
      0% {
        transform: rotate(15deg) scale(1);
        z-index: 13;
      }
      50% {
        transform: rotate(0deg) scale(1.05);
        z-index: 13;
      }
      51% {
        z-index: 20;
      }
      100% {
        transform: rotate(15deg) scale(1.10);
        z-index: 20;
      }
    `,
    },
    {
        animationName: 'rightFromBig',
        animation: keyframes`
      0% {
        transform: rotate(15deg) scale(1.10);
        z-index: 20;
      }
      50% {
        transform: rotate(0deg) scale(1.05);
        z-index: 20;
      }
      51% {
        z-index: 13;
      }
      100% {
        transform: rotate(15deg) scale(1);
        z-index: 13;
      }
    `,
    },
];

const inner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotateY(60deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const CoverBox = styled(Box)<{ withAnimation?: boolean }>`
    .slider {
        padding-top: ${props => (props.withAnimation ? '60%' : 0)};
        .slick-track {
            margin: 0 0.5rem;
            .slick-slide {
                padding: 0 0.5rem 0 0;
            }
        }
    }
`;

function animation(animationName: string) {
    let _animation = {};

    animations.map(item => {
        if (animationName === item.animationName) {
            _animation = item.animation;
        }
    });

    return _animation;
}

const PromoParent = styled.div<{
    animationNameLeft: string;
    animationNameMiddle: string;
    animationNameRight: string;
}>`
    position: relative;
    margin-top: 30px;
    width: 100%;
    height: 100%;
    .promo-visual-0 {
        transform: rotate(-15deg) scale(1.1);
        top: 0.5rem;
        left: 6%;
        z-index: 20;
        animation: ${props => animation(props.animationNameLeft)} 2s forwards;
    }

    .promo-visual-1 {
        transform: rotate(5deg);
        top: 5%;
        left: 32%;
        z-index: 12;
        animation: ${props => animation(props.animationNameMiddle)} 2s forwards;
    }

    .promo-visual-2 {
        transform: rotate(15deg);
        top: 0.5rem;
        left: 60%;
        z-index: 13;
        animation: ${props => animation(props.animationNameRight)} 2s forwards;
    }
`;

const Visual = styled.div`
    position: absolute;
    width: 8.32rem;
    height: 13rem;
    @media (max-width: 415px) {
        width: 7.35rem;
        height: 11.5rem;
    }
    @media (max-width: 350px) {
        width: 6.39rem;
        height: 10rem;
    }
    transition: 0.8s ease;
    transform-style: preserve-3d;
    perspective: 500px;
`;

let PromoInner = styled.div<{ imageUrl: string }>`
    width: 100%;
    height: 100%;
    box-shadow: -1px 4px 8px rgb(0, 0, 0);
    background-size: cover;
    transition: 0.5s ease;
    background-image: url(${props => props.imageUrl});
`;

export default class extends React.PureComponent<
    { items: any; component: any; withAnimation?: boolean },
    {
        animationNameLeft: string;
        animationNameMiddle: string;
        animationNameRight: string;
        animationNameInner: string;
    }
> {
    // todo: add interface
    constructor(props: any) {
        super(props);
        this.state = {
            animationNameLeft: '',
            animationNameMiddle: '',
            animationNameRight: '',
            animationNameInner: '',
        };
    }
    render() {
        // const that = this;
        const changeHandler = (event: any) => {
            // slick: any, currentSlide: any, nextSlide: any
            // console.log(event);
            PromoInner = styled.div<{ imageUrl: string }>`
                width: 100%;
                height: 100%;
                box-shadow: -1px 4px 8px rgb(0, 0, 0);
                background-size: cover;
                background-image: url(${props => props.imageUrl});
                animation: ${inner} 2s;
            `;
            switch (event) {
                case 0:
                    this.setState({
                        animationNameLeft: 'leftFromBig',
                        animationNameMiddle: 'middleToBig',
                        animationNameRight: 'right',
                    });
                    break;
                case 1:
                    this.setState({
                        animationNameLeft: 'left',
                        animationNameMiddle: 'middleFromBig',
                        animationNameRight: 'rightToBig',
                    });
                    break;
                case 2:
                    this.setState({
                        animationNameLeft: 'leftToBig',
                        animationNameMiddle: 'middle',
                        animationNameRight: 'rightFromBig',
                    });
                    break;
            }
        };
        const settings = {
            dots: true,
            infinite: true,
            autoplaySpeed: 3000,
            autoplay: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            // centerMode: true,
            // centerPadding: '20px',
            appendDots: (dots: any) => (
                <Dots isMobile>
                    {dots.map((dot: any, index: number) => (
                        <li
                            key={index}
                            className={dot.props.className}
                            onClick={dot.props.children.props.onClick}
                        >
                            {dot.props.children.props.children}
                        </li>
                    ))}
                </Dots>
            ),
            dotsClass: 'null',
            beforeChange: changeHandler,
        };

        const SliderComponent = this.props.component;

        // console.log(this.props);

        return (
            <CoverBox pb="25px" withAnimation={this.props.withAnimation}>
                {this.props.withAnimation && (
                    <PromoParent
                        animationNameLeft={this.state.animationNameLeft}
                        animationNameMiddle={this.state.animationNameMiddle}
                        animationNameRight={this.state.animationNameRight}
                    >
                        <Visual className="promo-visual-common promo-visual-2">
                            <PromoInner
                                className="promo-visual-inner"
                                imageUrl={this.props.items[0].images[0].path} // todo: check isCover image
                            />
                        </Visual>
                        <Visual className="promo-visual-common promo-visual-1">
                            <PromoInner
                                className="promo-visual-inner"
                                imageUrl={this.props.items[1].images[0].path}
                            />
                        </Visual>
                        <Visual className="promo-visual-common promo-visual-0">
                            <PromoInner
                                className="promo-visual-inner"
                                imageUrl={this.props.items[2].images[0].path}
                            />
                        </Visual>
                    </PromoParent>
                )}
                <Slider {...settings} className="slider">
                    {this.props.items.map(
                        (_item: IBlockPromoItemProps, _index: number) => {
                            return <SliderComponent {..._item} key={_index} />;
                        },
                    )}
                </Slider>
            </CoverBox>
        );
    }
}
