import React from 'react';
import { Link } from 'react-router-dom';

import { Cover } from 'Src/components/ResizedImage';

import { Box, Text } from '../basic';
import { Carousel } from './';

// const cutString = (str: string, max: number): string =>
//   `${Array.from({ length: max }, (_: never, index: number) => str[index]).join(
//     '',
//   )}...`;

export default class extends React.PureComponent<any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <Box padding="15px 20px 0">
                <Carousel
                    items={this.props.features}
                    component={(_props: any) => {
                        return (
                            <Box>
                                <Link to={`/items/${_props.id}`}>
                                    <Box display="flex" justifyContent="center">
                                        <Cover
                                            path={
                                                _props.images[0]
                                                    ? _props.images[0].path
                                                    : ''
                                            }
                                            height={175}
                                            width="100%"
                                            objectFit="cover"
                                        />
                                    </Box>
                                </Link>
                                <Text fontWeight={100} type="mbody1" my="20px">
                                    {_props.summary}
                                </Text>
                                {_props.isInStore ? (
                                    <Text
                                        type="mh2"
                                        textTransform="uppercase"
                                        args={_props.price / 100}
                                        t
                                    >
                                        ui.price
                                    </Text>
                                ) : (
                                    <Text type="mh2" textTransform="uppercase">
                                        нет в наличии
                                    </Text>
                                )}
                            </Box>
                        );
                    }}
                />
            </Box>
        );
    }
}
