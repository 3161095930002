import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
    Box,
    Button as BaseButton,
    CountButton,
    Text,
} from 'Src/components/basic';
import Icon from 'Src/components/Icon';
// import PageDelimeter from 'Src/components/PageDelimeter';
import { Cover } from 'Src/components/ResizedImage';
import { AppContext } from 'Src/model';

const Button = styled(BaseButton)`
    padding: 13px 0;
`;

// const MOCK_BOOK = {
//   id: 72,
//   slug: 'digital-tarkovsky-ru',
//   createdAt: '2018-10-25T12:16:41.000Z',
//   title: 'Digital Tarkovsky',
//   summary:
//     'Краткая история наступления технологий на город и его жителей. История кинематографа, стекла, смартфонов и текучей архитектуры киберпространства в книге австралийского медиатеоретика Скотта Маккуайра.',
//   images: [
//     {
//       path:
//         'https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/images/24484/n-a.png',
//       isCover: true,
//     },
//   ],
//   authors: [
//     {
//       name: 'Metahaven',
//       authorId: 28,
//       description: '',
//     },
//   ],
//   tags: [],
//   categories: [
//     {
//       path: 'books.evens',
//       category: 'evens',
//     },
//     {
//       path: 'books.evens.fours',
//       category: 'fours',
//     },
//   ],
//   price: 50000,
// };

class BagItem extends PureComponent<any> {
    state: any;
    constructor(props: any) {
        super(props);
        this.state = {
            amount: this.props.amount,
        };
    }
    handleIncrementCount = () =>
        this.setState(
            (state: any) => ({ amount: state.amount + 1 }),
            () => this.props.onAdd(),
        );
    handleDecrementCount = () =>
        this.setState(
            (state: any) => ({
                amount: state.amount <= 1 ? state.amount : state.amount - 1,
            }),
            () => this.props.onDecrement(),
        );
    render() {
        const { images, title, price, amount } = this.props;

        return (
            <Box
                display="flex"
                justifyContent="space-between"
                borderBottom="1px solid #000"
                marginBottom={'20px'}
                paddingBottom={'20px'}
            >
                <Cover
                    height={145}
                    width={136}
                    margin="0"
                    path={images.find((_image: any) => _image.isCover).path}
                />

                <Box width="40vw" marginLeft="10px">
                    {this.props.isPreorder && (
                        <Text
                            t
                            type="mh2"
                            textTransform="uppercase"
                            color={'#1A67F6'}
                        >
                            ui.preorder
                        </Text>
                    )}
                    <Text
                        letterSpacing="0.1375px"
                        textTransform="uppercase"
                        type="mh2"
                    >
                        {title}
                    </Text>
                    {this.props.type === 'books' && (
                        <Text t type="mh3" textTransform="uppercase" mr="90px">
                            {this.props.isDigital
                                ? 'ui.book_e_version'
                                : 'ui.book_paper_version'}
                        </Text>
                    )}
                    <Box marginTop="10px" display="flex" alignItems="center">
                        <CountButton
                            marginLeft="-10px"
                            // margin="5px 5px 5px 0"
                            onClick={this.handleDecrementCount}
                        >
                            <Text type="mh2">{'−'}</Text>
                        </CountButton>
                        <Text
                            type="mh2"
                            // margin="5px 5px 5px 0"
                        >
                            {this.state.amount}
                        </Text>
                        <CountButton
                            // margin="5px 5px 5px 0"
                            onClick={this.handleIncrementCount}
                        >
                            <Text type="mh2">{'+'}</Text>
                        </CountButton>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    <Icon
                        size={12}
                        onClick={this.props.onDelete}
                        name="cross"
                    />
                    <Text
                        whiteSpace="nowrap"
                        args={(price / 100) * amount}
                        type="mh2"
                        textTransform="uppercase"
                        t
                    >
                        ui.price
                    </Text>
                </Box>
            </Box>
        );
    }
}

export default class extends PureComponent<any> {
    state: any; // todo: add state interface
    constructor(props: any) {
        super(props);
        this.state = {
            booksCount: [],
        };
    }

    render() {
        return (
            <Box>
                <AppContext.Consumer>
                    {(model: any) => {
                        return (
                            <Fragment>
                                {/* <Header
                                    args={model.bag.books.length}
                                    title="headers.cart"
                                    background="#F3FA75"
                                    margin="0 0 30px"
                                    mobile
                                    t
                                /> */}
                                {model.bag.books.length > 0 ? (
                                    <Box margin="0 15px 20px">
                                        {model.bag.books.map(
                                            (_book: any, _index: number) => (
                                                <BagItem
                                                    isPreorder={model.bag.preorderIds.includes(
                                                        _book.id,
                                                    )}
                                                    key={_index}
                                                    {..._book}
                                                    onAdd={() =>
                                                        model.bag.addBook(_book)
                                                    }
                                                    onDelete={() =>
                                                        model.bag.removeBook(
                                                            _book,
                                                        )
                                                    }
                                                    onDecrement={
                                                        _book.amount > 1
                                                            ? () =>
                                                                  model.bag.decrementBook(
                                                                      _book,
                                                                  )
                                                            : () => null
                                                    }
                                                />
                                            ),
                                        )}
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Text
                                                type="mh2"
                                                textTransform="uppercase"
                                                t
                                            >
                                                ui.total
                                            </Text>
                                            <Text
                                                type="mh2"
                                                args={((total: number) =>
                                                    model.bag.books.map(
                                                        (_book: any) => {
                                                            total +=
                                                                _book.price *
                                                                _book.amount;
                                                        },
                                                    ) && total / 100)(0)}
                                                textTransform="uppercase"
                                                t
                                            >
                                                ui.price
                                            </Text>
                                        </Box>
                                        <Link
                                            to={`/${model.locale.value}/order`}
                                        >
                                            <Button
                                                withoutBorder
                                                margin="30px 0"
                                                color="#fff"
                                                bg="#1A67F6"
                                                padding="13px 0 10px !important"
                                            >
                                                <Text
                                                    type="mh2"
                                                    textTransform="uppercase"
                                                    t
                                                >
                                                    cart.pay_button
                                                </Text>
                                            </Button>
                                        </Link>
                                    </Box>
                                ) : (
                                    <Fragment>
                                        <Box margin="0 15px 30px">
                                            <Text
                                                textTransform="uppercase"
                                                textAlign="center"
                                                type="mh2"
                                                t
                                            >
                                                cart.empty_cart
                                            </Text>
                                        </Box>
                                        {/* <PageDelimeter
                                            title="headers.are_seen"
                                            background="#EA3DF7"
                                            color="#fff"
                                            mobile
                                        />
                                        <Carousel
                                            items={((
                                                arr: any[],
                                                initial: any[],
                                            ) =>
                                                arr.map(
                                                    (
                                                        _item: any,
                                                        _index: any,
                                                        _arr: any[],
                                                    ) =>
                                                        _arr.length < 2
                                                            ? initial.push([
                                                                  _item,
                                                              ])
                                                            : _index % 2 ===
                                                                  0 &&
                                                              initial.push(
                                                                  arr[
                                                                      _index + 1
                                                                  ]
                                                                      ? [
                                                                            _item,
                                                                            arr[
                                                                                _index +
                                                                                    1
                                                                            ],
                                                                        ]
                                                                      : [_item],
                                                              ),
                                                ) && initial)(
                                                model.lastSee.items,
                                                [],
                                            )}
                                            component={(_props: any) => {
                                                const _book = book.items
                                                    ? book.items[0]
                                                    : book;
                                                return (
                                                    <Box
                                                        display="flex !important"
                                                        flexDirection="row"
                                                        justifyContent="space-between"
                                                        margin="0 20px"
                                                    >
                                                        <Book {..._props[0]} />
                                                        {_props[1] ? (
                                                            <Book {..._props[1]} />
                                                        ) : null}
                                                    </Box>
                                                )
                                            }
                                            }
                                        />*/}
                                    </Fragment>
                                )}
                            </Fragment>
                        );
                    }}
                </AppContext.Consumer>
            </Box>
        );
    }
}
