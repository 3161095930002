import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { Box, Text as BaseText } from 'Src/components/basic';
import BaseIcon from 'Src/components/Icon';
import { AppContext } from 'Src/model';

const Icon = styled(BaseIcon)`
    cursor: pointer;
`;
const Text = styled(BaseText)`
    color: black;
    animation-delay: 1.1s;
    margin-right: 10px;

    @keyframes in {
        from {
            transform: translateY(180px);
        }
        to {
            transform: translateY(0px);
        }
    }
`;
const TextBox = styled(Box)<{ isMobile: boolean }>`
    display: flex;
    width: ${props => (props.isMobile ? '70%' : 'auto')};
    a {
        text-decoration: underline;
    }
`;
const PopupBox = styled(Box)<{ isMobile: boolean }>`
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    transform: translateY(180px);
    animation: in 0.4s forwards;
    animation-delay: 1s;

    ${props =>
        props.isMobile &&
        `
            padding: 20px;
            height: 165px;
            flex-direction: column;
            ${Icon} {
              margin-left: auto;
            }
        `}
    @keyframes in {
        from {
            transform: translateY(180px);
        }
        to {
            transform: translateY(0px);
        }
    }
    @keyframes out {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(180px);
        }
    }

    &.unmounting {
        animation: out 0.4s forwards;

        ${Text} {
            animation: out 0.3s forwards;
            animation-delay: 0.2s;
        }
        ${Icon} {
            animation: out 0.3s forwards;
        }
    }
`;

const ErrorPopupBox = styled(PopupBox)`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    overflow-y: scroll;
    background-color: #f3fa75;
    padding: ${props => (props.isMobile ? '30px' : '40px 100px 40px 60px')};
    animation: none;
    transform: none;
    max-width: 1680px;
    margin: auto;

    @media (min-width: 1681px) {
        max-width: 1676px;
    }

    .close_icon {
        position: absolute;
        top: ${props => (props.isMobile ? '20px' : '26px')};
        right: ${props => (props.isMobile ? '30px' : '62px')};
    }

    @keyframes in {
        from {
            transform: translateY(1);
        }
        to {
            transform: translateY(0px);
        }
    }
    @keyframes out {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(50%);
        }
    }

    &.unmounting {
        animation: out 0.4s forwards;
        display: none;

        ${Text} {
            animation: out 0.3s forwards;
            animation-delay: 0.2s;
        }
        ${Icon} {
            animation: out 0.3s forwards;
        }
    }
`;

export default function() {
    const context: any = useContext(AppContext);
    // const [isUnmounting, updateUnmount] = useState(false);
    // const isPopupAlreadyShown = document.cookie
    //     .split('; ')
    //     .map((cookie: string) => cookie.split('='))
    //     .find((cookie: string[]) => cookie[0] === 'isPopupHide');

    function update() {
        // updateUnmount(true);
        // context.popups.setPandemia(true);
        // console.log(123);
        context.popups.setHolydays(false);
    }

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    // return isPopupAlreadyShown ? null : (
    return (
        context.popups.holydays && (
            <ErrorPopupBox isMobile={isMobile} className={''}>
                <Box>
                    {/* {isMobile && (
                        <HeaderText type="mh2" whiteSpace="nowrap">
                            STRELKA SHOP
                        </HeaderText>
                    )} */}

                    <TextBox isMobile={isMobile}>
                        <Text
                            type={isMobile ? 'mbody1' : 'body1'}
                            tD={'texts.newsletter_popup'}
                        />
                    </TextBox>
                </Box>
                <Icon
                    onClick={update}
                    className="close_icon"
                    name="cross"
                    size={isMobile ? '20px' : '36px'}
                />
            </ErrorPopupBox>
        )
    );
}
