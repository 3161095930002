import React, { PureComponent } from 'react';

import * as api from 'Src/api';
import { AppContext } from 'Src/model';

export default class extends PureComponent<any> {
    static contextType = AppContext;
    async handleLogout() {
        await api.signOut();

        document.cookie.split(';').forEach(function(c) {
            document.cookie = c
                .replace(/^ +/, '')
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });

        this.context.auth.signout();
        this.context.bag.getCart();
    }
    handleLogoutClick = () => {
        this.handleLogout();
    };
    render() {
        return (
            <span onClick={this.handleLogoutClick}>{this.props.children}</span>
        );
    }
}
