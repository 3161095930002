import React from 'react';

// import DeliveryPopup from 'Src/components/DeliveryPopup';
import NewsletterPopup from 'Src/components/NewsletterPopup';
// import SpecialDeliveryPopup from 'Src/components/SpecialDeliveryPopup';

export default class extends React.PureComponent<any> {
    state: any = {};
    render() {
        // return this.props.context.popups.pandemia ? (
        //     this.props.context.popups.shops === null ||
        //     this.props.context.popups.shops.length === 0 ? (
        //         <DeliveryPopup />
        //     ) : (
        //         <SpecialDeliveryPopup shops={this.props.context.popups.shops} />
        //     )
        // ) : (
        //     this.props.context.popups.newsletter && <NewsletterPopup />
        // );
        return this.props.context.popups.newsletter && <NewsletterPopup />;
    }
}
