import React from 'react';

export default class extends React.PureComponent {
    constructor(props: any) {
        super(props);
    }
    render() {
        return 123;
    }
}
