import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import { Cover } from 'Src/components/ResizedImage';

const EmptyText = styled(Text)`
    font-family: WilliamDisplay;
    font-size: 36px;
    line-height: 42px;
`;

const cutString = (str: string, max: number) =>
    str
        .split('')
        .filter((_, index: number) => index < max)
        .join('') + (str.length > max ? '...' : '');

const Comment = (props: any) => (
    <Box display="flex" mt="50px" width="60%">
        <Cover
            margin="0"
            path={props.item.images.find((image: any) => image.isCover).path}
            height={260}
        />
        <Box marginLeft="50px">
            <Text
                mb="6px"
                fontFamily="1"
                fontSize="15px"
                textTransform="uppercase"
            >
                {props.item.title}
            </Text>
            <Text mb="20px" fontFamily="2" fontSize="15px">
                {props.item.authors
                    .map((author: any) => author.name)
                    .join(', ')}
            </Text>
            <Text
                mb="20px"
                fontFamily="WilliamDisplay"
                fontSize="22px"
                lineHeight="27px"
            >
                {cutString(props.comment, 250)}
            </Text>
            <u>
                <Link to={`/items/${props.itemId}`}>
                    <Text t fontFamily="2" fontSize="15px">
                        ui.go_to_item
                    </Text>
                </Link>
            </u>
        </Box>
    </Box>
);

export default class extends PureComponent<any> {
    componentDidMount() {
        // this.props.getComments()
    }
    getWordEnd = (length: number) => {
        if (length === 1) {
            return 'й';
        } else if (length === 2) {
            return 'я';
        } else {
            return 'ев';
        }
    };
    render() {
        return this.props.comments.length === 0 ? (
            <React.Fragment>
                <EmptyText t>profile.no_comments</EmptyText>
                <Link to="/items">
                    <u>
                        <Text t>ui.go_to_shop</Text>
                    </u>
                </Link>
            </React.Fragment>
        ) : (
            <Box>
                <EmptyText
                    t
                    args={{
                        count: this.props.comments.length,
                        completion: this.getWordEnd(this.props.comments.length),
                    }}
                >
                    profile.comments
                </EmptyText>
                {this.props.comments.map((comment: any, index: number) => (
                    <Comment {...comment} key={index} />
                ))}
            </Box>
        );
    }
}
