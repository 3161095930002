export default {
    ru: {
        language: {
            ru: 'русский',
            en: 'english',
        },
        forms: {
            name: 'Имя',
            surname: 'Фамилия',
            email: 'Email',
            phone: 'Мобильный телефон',
            comment:
                'Ваш комментарий: номер подъезда, квартиры, код домофона, удобное время доставки',
            city: 'Город доставки',
            address: 'Адрес доставки',
        },
        tags: {
            11: 'ДИЗАЙН',
            10: 'СОЦИОЛОГИЯ',
            8: 'АНТРОПОЛОГИЯ',
            12: 'БУДУЩЕЕ',
            5: 'ЭКОНОМИКА',
            17: 'МЕДИА',
            13: 'ТРУД',
            18: 'ТЕРРАФОРМИНГ',
            // 12: 'АРХИТЕКТУРА',
            // 12: 'ТЕХНОЛОГИИ',
            // 12: 'КОММЬЮНИТИ',
            // 12: 'НОВАЯ НОРМА',
            // 12: 'КУЛЬТУРОЛОГИЯ',
            // 12: 'УРБАНИЗМ',
        },
        cities: {
            moscow: 'Москва',
            spb: 'Санкт-Петербург',
            others: 'Регионы',
        },
        ui: {
            in: 'войти',
            books: 'книги',
            preorder: 'предзаказ',
            merch: 'мерч',
            paper: 'бумага',
            ebook: 'epub',
            and: 'и',
            profile: 'профиль',
            country: 'Cтрана',
            book_e_version: 'Электронная версия',
            book_paper_version: 'Печатная версия',
            find: 'поиск',
            drop_filters: 'Сбросить все фильтры',
            logo: 'strelka shop',
            go_to_shop: 'Перейти в магазин',
            go_to_item: 'Перейти на страницу товара',
            price: (price: number) => `${price} руб`,
            added: 'Добавлено',
            download: 'Скачать',
            in_bag: 'В корзину',
            book_title: (title: string) => `КНИГА: ${title}`,
            total: 'Итого',
            name: 'Имя',
            lastName: 'Фамилия',
            phone: 'Телефон',
            email: 'Email',
            city: 'Город доставки',
            address: 'Адрес доставки',
            comment:
                'Ваш комментарий: номер подъезда, квартиры, код домофона, удобное время доставки ',
            rub: (count: number) => `${count} РУБ`,
            empty: '',
            about: 'о товаре',
            more: 'Подробнее',
            add_comment: 'Добавьте комментарий...',
            subscribe: 'Подписка на новости',
            cart: 'корзина',
            see_all: 'смотреть всё',
            filter: 'фильтр',
            reset_filter: 'Сбросить фильтр',
            theme: 'Тема',
            author: 'Автор',
            lang: 'Язык',
            nothing_found: 'Ничего не найдено',
        },
        headers: {
            strelka_press_books: 'книги strelka press',
            strelka_press: 'strelka press',
            strelka_press_merch: 'мерч',
            cart: (count: number) => `корзина (${count})`,
            profile_main: 'личный кабинет',
            profile_orders: (count: number) => `заказы(${count})`,
            profile_favorites: (count: number) => `избранное(${count})`,
            profile_comments: (count: number) => `комментарии(${count})`,
            profile_personal: 'мои данные',
            sign_out: 'выйти',
            new: 'новинки',
            find_results: 'результаты поиска',
            popular: 'Популярное',
            about: 'об издательстве',
            order: 'оплата',
            merch: 'мерч',
            also_read: 'Читайте также',
            also_like: 'Вам может понравиться',
            are_seen: 'Вы просматривали',
        },
        profile: {
            hello: (name: string) => `Здравствуйте, ${name} `,
            log_out: 'Выйти',
            in_account_you_may_view:
                'В личном кабинете вы можете просматривать ',
            to_last_orders: 'последние заказы',
            to_favorites: 'избранное',
            to_comments: 'комментарии',
            and: ' и ',
            to_edit_data: 'редактировать данные своего аккаунта',
            no_comments: 'У вас нет комментариев.',
            no_favorites: 'У вас нет избранных товаров.',
            comments: (args: any) =>
                `У вас ${args.count || 0} комментари${args.completion || 'ев'}`,
        },
        cart: {
            item: 'Товар',
            description: 'Описание',
            amount: 'Количество',
            sum: 'Сумма',
            pay_button: 'Перейти к оплате',
            empty_cart: 'Ваша корзина пуста.',
        },
        order: {
            pay_by: 'Выберите способ доставки',
            pick_up: 'Самовывоз институт «Стрелка»',
            address: 'Москва, Берсеневская наб. 14, стр 5А, «Офис»',
            time: 'Понедельник–пятница, 12:00–19:00',
            ebooks: 'Введите данные для получения электронной книги',
            sign_in: 'Есть аккаунт? Войти',
            courier: 'Доставка (от 300 руб)',
            your_cart: (count: number) => `Ваша корзина (${count})`,
            amount: (count: number) => `Количество: ${count}`,
            total: 'Общая сумма',
            delivery: 'Доставка',
            pay: 'Оплатить',
            agreement1: 'Я согласен с ',
            agreement2: 'Условиями использования',
            agreement3: 'Политикой обработки персональных данных',
            i_agree: 'Я соглашаюсь с ',
            terms_and_conditions: 'Условиями использования',
            and: ', а также с ',
            privacy_policy: 'Политикой обработки персональных данных',
            sign_in_to_comment: 'Чтобы оставить комментарий, авторизуйтесь',
            book: (book: string) => `Книга: ${book})`,
        },
        texts: {
            about: `Strelka Press выпускает книги об архитектуре, дизайне, технологиях и медиа. Издательская программа воссоздает  интеллектуальную традицию в области городского развития.
            Мы печатаем труды классиков, переводы новейших исследований, сборники статей российских экспертов и эссе преподавателей Института. Книги Strelka Press выходят на русском и английском языках как в печатном, так и в электронном формате.`,
            delivery1: `Самовывоз. Вы можете забрать свой заказ по будням с 11:00 до 18:30 в офисе Института «Стрелка» по адресу — Берсеневская набережная, дом 14, строение 5 А.`,
            delivery2: `Доставка по Москве в пределах МКАД. Ваш заказ доставит курьерская служба в течение пяти рабочих дней. Время и точная дата доставки зависит от загруженности курьерской службы. Сумма доставки 500 руб. с НДС.`,
            delivery3: `Доставка в регионы России. Заказы в регионы доставляем Почтой России.`,
            delivery4: `Доступна международная доставка почтой.`,
            delivery_popup:
                'Мы вынуждены остановить выдачу товаров в пункте самовывоза до 16 апреля 2020 года. Следите за нашими обновлениями',
            pandemia_popup_1:
                'К сожалению, из-за обострившейся эпидемиологической обстановки мы вынуждены приостановить работу онлайн-магазина.',
            pandemia_popup_2:
                'Мы уверены, что в это непростое время нужно поддержать малый и средний бизнес, который пострадает сильнее всего. Вот список небольших независимых магазинов, в которых вы можете купить книги Strelka Press:',
            newsletter_popup:
                'Strelka Shop не работает. Книги Strelka Press можно найти в книжных магазинах вашего города.',
            holiday_popup:
                'Заказы, сделанные после 25 декабря, будут отправлены после 10 января 2021 года.',
        },
        book: {
            description: 'Описание',
            reviews: 'Рецензии',
            aboutItem: 'О товаре',
            year: (year: number) => `Год выпуска: ${year}`,
            pagesAmount: (count: number) => `Количество страниц: ${count}`,
            language: (lang: string) => `Язык: ${lang}`,
            coverType: (cover: string) => `Тип обложки: ${cover}`,
            delivery: 'Доставка',
            aboutDelivery:
                'Купить книгу можно онлайн с доставкой по России или каждый день с 12:00 до 20:00 в нашем летнем магазине на «Стрелке»',
            aboutAuthor: 'Об авторе',
            comments: (amount: number) => `Комментарии (${amount})`,
            show_all: 'Показать все',
            ISBN: 'ISBN',
        },
        footer: {
            contact_us: 'контакты',
            find_us: 'пункт самовывоза',
            address:
                'Институт «Стрелка», Берсеневская набережная 14, стр. 5а, Москва, 119072, Россия',
            follow_us: 'соцсети',
            follow: 'Подписка на новости↗',
            strelka: `©${new Date().getFullYear()} Strelka`,
            terms_and_conditions: 'Пользовательское соглашение',
            terms_and_conditions1: 'Пользовательское',
            terms_and_conditions2: ' соглашение',
            privacy_policy: 'Персональные данные',
        },
        popup: {
            go_to_cart: 'Перейти в корзину',
            item_is_added: 'Товар был добавлен в Вашу корзину.',
            total_sum: (amount: number) =>
                `Общая сумма покупок: ${amount} руб.`,
            amount: (amount: number) => `Количество товаров: ${amount}`,
            go_to_pay: 'Перейти к оплате',
        },
        orderResponse: {
            your_order: 'Ваш заказ № ',
            is_success: ' успешно оплачен! ',
            pick_up:
                'Теперь вам осталось только забрать его в офисе Института «Стрелка» по адресу Берсеневская наб., 14с5. Пункт выдачи работает по будням с 11:00 до 18:30.',
            delivery:
                'Мы уже начали его собирать и совсем скоро передадим в курьерскую службу. Доставка займёт до 5 дней.',
        },
    },
    en: {
        language: {
            ru: 'русский',
            en: 'english',
        },
        ui: {
            in: 'Log in',
            books: 'Books',
            merch: 'Merch',
            preorder: 'preorder',
            profile: 'Account',
            find: 'Search',
            logo: 'strelka shop',
            paper: 'paper',
            ebook: 'epub',
            book_e_version: 'Digital version',
            book_paper_version: 'Paper version',
            go_to_shop: '',
            go_to_item: 'Go to the product’s page',
            price: (price: number) => `${price} rub`,
            added: 'Added',
            download: 'Download',
            in_bag: 'Add to cart',
            book_title: (title: string) => `BOOK: ${title}`,
            drop_filters: 'Drop all filters',
            total: 'Subtotal',
            name: 'First Name',
            lastName: 'Last name',
            and: 'and',
            phone: 'Mobile phone',
            email: 'Email',
            country: 'Country',
            city: 'City',
            address: 'Address',
            comment: 'Your comment: house number, doorbell, timing, etc.',
            rub: (count: number) => `${count} RUB`,
            add_comment: 'Add a comment...',
            empty: '',
            about: 'details',
            more: 'Read more',
            subscribe: 'Subscribe',
            cart: 'Cart',
            see_all: 'view all',
            filter: 'filter',
            reset_filter: 'Reset filter',
            theme: 'Subject',
            author: 'Author',
            lang: 'Language',
            test: '<p>123</p>',
            nothing_found: 'Nothing is found',
        },
        headers: {
            strelka_press_books: 'strelka press books',
            strelka_press_merch: 'merch',
            cart: (count: number) => `Cart (${count})`,
            profile_main: 'Dashboard',
            profile_orders: `Paymant`,
            profile_favorites: (count: number) => `Favorites(${count})`,
            profile_comments: (count: number) => `My comments(${count})`,
            profile_personal: 'my data',
            new: 'new',
            about: 'About us',
            sign_out: 'Log out',
            order: 'payment',
            merch: 'merch',
            also_read: 'Related',
            find_results: 'find results',
            popular: 'Best sellers',
            also_like: 'Related',
            are_seen: 'Recently viewed',
        },
        profile: {
            hello: (name: string) => `Hello ${name} `,
            log_out: 'Log out',
            in_account_you_may_view:
                'From your account dashboard you can view your ',
            to_last_orders: 'recent orders',
            to_favorites: 'favorites',
            to_comments: 'comments',
            and: ', and ',
            to_edit_data: 'edit your account data',
            no_comments: 'You have no comments.',
            no_favorites: 'You have no favourites.',
            comments: (args: any) =>
                `You have ${args.count || 0} comment${args.completion || 's'}`,
        },
        cart: {
            item: 'Product',
            description: 'Description',
            amount: 'Quantity',
            sum: 'Price',
            pay_button: 'Checkout',
            empty_cart: 'Your cart is empty.',
        },
        order: {
            pay_by: 'Choose a delivery method',
            pick_up: 'Pick up from "Strelka" institute ',
            address: 'Moscow, Bersenevskaya Embarkment 14, bldng. 5A, “Office”',
            time: 'Monday–Friday, 12:00–19:00',
            ebooks: 'Enter email & name',
            sign_in: 'Returning customer? Log in',
            courier: 'home delivery (from 300 rub)',
            your_cart: (count: number) => `Your cart(${count})`,
            amount: (count: number) => `Quantity: ${count}`,
            total: 'Subtotal',
            delivery: 'Delivery',
            pay: 'Pay',
            agreement1: 'With your order you agree to our',
            agreement2: 'Terms and conditions',
            agreement3: 'Privacy policy',
            i_agree: 'With your order you agree to our ',
            terms_and_conditions: 'Terms and conditions',
            and: ' and ',
            privacy_policy: 'Privacy policy',
            sign_in_to_comment: 'Please sign in to comment',
            book: (book: string) => `Book: ${book})`,
        },
        texts: {
            about: `Publishing books on architecture, design, technologies and media, Strelka Press is  reviving a tradition of intellectual literature in urban development. Among our publications are classic writings, translations of the latest research, collections of articles by Russian experts and essays by the Faculty of Strelka Institute. At Strelka Press, we release printed and digital books in Russian and in English.`,
            delivery1: `Pick-up. You can pick up your order on weekdays between 11:00 and 18:30 at the Strelka Institute office. Address: Bersenevskaya Embankment, Building 14, 5A.`,
            delivery2: `Delivery in Moscow, within Moscow Ring Road. Your order will be delivered by courier service within five working days. The time and exact date of delivery depends on the workload of the courier service. The cost of delivery is 500 RUB, including VAT.`,
            delivery3: `Delivery to other Russian cities. Orders are delivered by Russian Post.`,
            delivery4: `International postal delivery is available.`,
            delivery_popup:
                'Our pick-up point is closed for quarantine until April 16, 2020. Follow our updates',
            newsletter_popup: 'Strelka Shop is currently closed.',
            holiday_popup:
                'Orders placed after December 25 will be shipped after January 10, 2021.',
        },
        book: {
            description: 'Description',
            reviews: 'Reviews',
            aboutItem: 'Details',
            year: (year: number) => `Год выпуска: ${year}`,
            pagesAmount: (count: number) => `Количество страниц: ${count}`,
            language: (lang: string) => `Язык: ${lang}`,
            delivery: 'Delivery',
            aboutDelivery:
                'Купить книгу можно онлайн с доставкой по России или каждый день с 12:00 до 20:00 в нашем летнем магазине на «Стрелке»',
            aboutAuthor: 'Author',
            comments: (amount: number) => `Comments (${amount})`,
            show_all: 'Show all',
            ISBN: 'ISBN',
        },
        footer: {
            contact_us: 'Contact us',
            email: 'books@strelka.com',
            phone: '+7 (495) 268 06 19',
            find_us: 'Find us',
            address:
                'Strelka Institute, Bersenevskaya Embarkment 14, bldng. 5A,  Moscow, 119072, Russia',
            follow_us: 'Follow us',
            follow: 'Subscribe↗',
            strelka: '©2o21 Strelka',
            terms_and_conditions: 'Terms and conditions',
            privacy_policy: 'Privacy policy',
        },
        popup: {
            go_to_cart: 'Go to cart',
            item_is_added: 'The item was added to your cart.',
            total_sum: (amount: number) => `Subtotal: ${amount} rub.`,
            amount: (amount: number) => `Number of items: ${amount}`,
            go_to_pay: 'Checkout',
        },
        orderResponse: {
            your_order: 'You have successfully completed your order № ',
            is_success: '. ',
            pick_up:
                'You can pick up your order at the Strelka Institute office, located at Bersenevskaya Embankment, Building 14, 5A. The office is open Mon-Fri from 11:00 to 18:30.',
            delivery:
                'Your order will be delivered by courier service within five working days.',
        },
    },
};
