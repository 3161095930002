export function PaytureWidget(e) {
    let n;
    let r;
    let d;
    let o;
    let s;
    const a = 'https://merchantgateway.payture.com/?';
    const c = {
        Domain: '1',
        Key: '',
        Amount: 0,
        Product: '',
        CustomParams: void 0,
        ChequeParams: void 0,
    };
    const u = this;
    let l = !1;
    let i = !1;
    let m = void 0;

    function p() {
        n && ((n.style.display = 'none'), (n.style.opacity = 0)),
            r && (r.style.visibility = 'hidden'),
            o && (o.style.visibility = 'visible'),
            s && (s.style.visibility = 'hidden'),
            document.body.removeChild(n);

        const e = document.getElementById('viewport-widget-device-width');

        e && document.head.removeChild(e);
    }
    function t(e) {
        if (
            'CLOSE_PAYTURE_WIDGET_SUCCESS' == e.data ||
            'CLOSE_PAYTURE_WIDGET_ERROR' == e.data
        ) {
            (i = !0),
                'CLOSE_PAYTURE_WIDGET_SUCCESS' == e.data
                    ? (m = !0)
                    : 'CLOSE_PAYTURE_WIDGET_ERROR' == e.data && (m = !1);

            const t = document.createEvent('Event');

            t.initEvent('transactionCompleted', !0, !1),
                n.dispatchEvent(t),
                p();
        }
    }
    (this.GetTransactionSuccess = function() {
        return m;
    }),
        (this.OnTransactionCompleted = function(t) {
            n.addEventListener(
                'transactionCompleted',
                function(e) {
                    t(), n.removeEventListener('transactionCompleted', t);
                },
                !1,
            );
        }),
        (l = navigator.userAgent.match(
            /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i,
        )),
        (function() {
            (n = document.createElement('div')).setAttribute(
                'id',
                'payture-widget-wrapper',
            );

            const e = document.createElement('div');

            e.setAttribute('id', 'payture-widget-shadow');

            const t = document.createElement('div');

            t.setAttribute('id', 'payture-widget-layout'),
                n.appendChild(e),
                n.appendChild(t),
                (o = document.createElement('div')).setAttribute(
                    'id',
                    'payture-widget-loader',
                );

            const i = document.createElement('div');

            if (
                (i.setAttribute('id', 'payture-widget-iframe-wrapper'),
                (s = document.createElement('div')).setAttribute(
                    'id',
                    'payture-widget-close',
                ),
                (d = document.createElement('div')).setAttribute(
                    'id',
                    'payture-widget-close-button',
                ),
                s.appendChild(d),
                (r = document.createElement('iframe')).setAttribute(
                    'id',
                    'payture-widget-iframe',
                ),
                r.setAttribute('frameborder', '0'),
                r.setAttribute('scrolling', 'yes'),
                r.setAttribute('allowfullscreen', 'true'),
                i.appendChild(s),
                i.appendChild(r),
                t.appendChild(o),
                t.appendChild(i),
                document.body.appendChild(n),
                l &&
                    0 ==
                        (function() {
                            function e(e, t, i) {
                                return (
                                    e.hasAttribute(t) && e.getAttribute(t) == i
                                );
                            }
                            for (
                                var t = !1,
                                    i = document.getElementsByTagName('meta'),
                                    a = 0;
                                a < i.length;
                                a++
                            ) {
                                const n = i[a];

                                if (
                                    e(n, 'name', 'viewport') &&
                                    e(
                                        n,
                                        'content',
                                        'width=device-width, initial-scale=1',
                                    )
                                ) {
                                    t = !0;
                                    break;
                                }
                            }

                            return t;
                        })())
            ) {
                const a = document.createElement('meta');

                a.setAttribute('id', 'viewport-widget-device-width'),
                    a.setAttribute('name', 'viewport'),
                    a.setAttribute(
                        'content',
                        'width=device-width, initial-scale=1',
                    ),
                    document.head.appendChild(a);
            }
        })(),
        (function(e) {
            for (var t in ((u.Data = {}), e)) {
                const i = e[t];

                ('string' !== typeof i &&
                    'number' !== typeof i &&
                    'object' !== typeof i) ||
                    (u.Data[t] = i),
                    'function' === typeof i &&
                        u.hasOwnProperty(t) &&
                        'function' === typeof u[t] &&
                        u[t](i);
            }
            for (var t in c) {
                u.Data.hasOwnProperty(t) || (u.Data[t] = c[t]);
            }
        })(e),
        n && ((n.style.display = 'block'), (n.style.opacity = 1)),
        r &&
            (r.onload = function() {
                i ||
                    ((r.className = 'loaded'),
                    (s.className = 'loaded'),
                    o && (o.style.visibility = 'hidden'),
                    s && (s.style.visibility = 'visible'),
                    r && (r.style.visibility = 'visible'));
            }),
        d &&
            (d.onclick = function() {
                p();
            }),
        window.addEventListener
            ? window.addEventListener('message', t, { once: !0 })
            : window.attachEvent('onmessage', t),
        (function() {
            let e = a;

            for (const t in u.Data) {
                const i = u.Data[t];

                ('string' !== typeof i &&
                    'number' !== typeof i &&
                    'boolean' !== typeof i) ||
                    (e += `${t.toLowerCase()}=${i}&`),
                    'object' === typeof i &&
                        ('ChequeParams' == t &&
                            (i.hasOwnProperty('CustomerContact') ||
                                (i.CustomerContact = ''),
                            i.hasOwnProperty('Message') ||
                                (i.Message = 'Payture Cheque')),
                        (e += `${t.toLowerCase()}=${JSON.stringify(i)}&`));
            }
            r && (r.src = e);
        })();
}
