import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Text as BaseText } from 'Src/components/basic';
import PageHeader from 'Src/components/PageHeader';
import BaseBook from 'Src/components/stickers/Book';
import { SearchContext } from 'Src/model/search';

const Container = styled.div`
    padding: 3rem 3.75rem;
`;
const Content = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
    grid-template-areas: 'side-bar items items items';
    grid-row-gap: 65px;
`;
const Book = styled(BaseBook)`
    grid-column: 1;
`;
const Text = styled(BaseText)`
    padding: 50px 60px 0;
`;

export default withRouter(
    class extends React.Component<any> {
        static contextType = SearchContext;
        componentDidMount() {
            window.scrollTo(0, 0);
        }
        componentDidUpdate = (prevProps: any) => {
            if (prevProps.match.url !== this.props.match.url) {
                window.scrollTo(0, 0);
            }
        };

        render() {
            return (
                <React.Fragment>
                    <PageHeader title="headers.find_results" t></PageHeader>
                    {this.context.query.length === 0 ? (
                        <Text type="body1" t>
                            ui.nothing_found
                        </Text>
                    ) : (
                        <Text type="body1">
                            Найдено{' '}
                            {
                                this.props.books.filter((_book: any) => {
                                    const _query = this.context.query.toLowerCase();

                                    return (
                                        _book.title
                                            .toLowerCase()
                                            .includes(_query) ||
                                        _book.authors
                                            .map((_a: any) =>
                                                _a.name.toLowerCase(),
                                            )
                                            .join(', ')
                                            .includes(_query) ||
                                        _book.tags
                                            .map((_a: any) =>
                                                _a.tag.toLowerCase(),
                                            )
                                            .join(', ')
                                            .includes(_query)
                                    );
                                }).length
                            }{' '}
                            результатов по запросу «{this.context.query}»:
                        </Text>
                    )}
                    <Container>
                        <Content>
                            {this.context.query.length > 0 &&
                                this.props.books
                                    .filter((_book: any) => {
                                        const _query = this.context.query.toLowerCase();

                                        return (
                                            _book.title
                                                .toLowerCase()
                                                .includes(_query) ||
                                            _book.authors
                                                .map((_a: any) =>
                                                    _a.name.toLowerCase(),
                                                )
                                                .join(', ')
                                                .includes(_query) ||
                                            _book.tags
                                                .map((_a: any) =>
                                                    _a.tag.toLowerCase(),
                                                )
                                                .join(', ')
                                                .includes(_query)
                                        );
                                    })
                                    .map((_book: any, _index: number) => (
                                        <Book {..._book} key={_index} />
                                    ))}
                        </Content>
                    </Container>
                </React.Fragment>
            );
        }
    },
);
