import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FindInput } from 'Src/components';
import { Box, Text as BaseText } from 'Src/components/basic';
import { useTranslation } from 'Src/Context/Translations';
import { Link } from 'Src/locales';
// import GoToAuth from 'Src/components/GoToAuth';
import { getLocale, stripLocale } from 'Src/locales';
import { SearchContext } from 'Src/model/search';

const LogoText = styled(BaseText)`
    min-width: 215px;
    text-transform: uppercase;
    font-size: 30px;
    user-select: none;
`;
const FindBox = styled(Box)`
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    &.opened {
        width: 100%;
    }
`;
const Text = styled(BaseText)`
    text-transform: uppercase;
    margin-right: 40px;
    user-select: none;
`;

const LangText = styled(Text)`
    cursor: pointer;
`;

function SelectLanguageButton({ language, onClick }: any) {
    const { state, actions } = useTranslation();

    const currentLanguage = language || state.language || 'ru';
    const newLanguage = currentLanguage === 'ru' ? 'en' : 'ru';

    return (
        <LangText
            onClick={() => {
                actions.setLanguage(newLanguage);
                onClick();
            }}
        >
            {getLocale(newLanguage)}
        </LangText>
    );
}

export default withRouter(
    class extends React.Component<any> {
        static contextType = SearchContext;
        state: any = {
            query: '',
            isFindOpened: false,
            locale: this.props.match.params.locale,
        };
        constructor(props: any) {
            super(props);
        }

        toggleFindHover = () => {
            this.setState(() => ({ isFindOpened: true }));
        };

        handleFindSubmit(e: any) {
            if (e.keyCode === 13) {
                const _locale = this.props.match.params.locale || 'ru';

                this.context.updateQuery(this.state.query);
                this.props.history.push(`/${_locale}/find`);
            }
        }
        handleBack = () => {
            this.setState({
                query: '',
                isFindOpened: false,
            });
            this.context.updateQuery('');
            // this.props.history.goBack();
        };
        switchLocale = () => {
            const { locale } = this.state;

            this.setState(
                { locale: !locale || locale === 'ru' ? 'en' : 'ru' },
                () => {
                    this.props.history.push(
                        (this.state.locale ? `/${this.state.locale}` : '') +
                            stripLocale(
                                this.props.location.pathname,
                                this.props.match.params.locale,
                            ),
                    );
                    this.props.app.locale.set(this.state.locale);
                },
            );
        };
        componentDidUpdate() {
            // if (this.state.isFindOpened === true) {
            //     document.onclick = () => this.setState({ isFindOpened: false });
            // } else {
            //     document.onclick = () => null
            // }
        }
        render() {
            const { props } = this;
            const { isFindOpened } = this.state;
            const isFindPage = props.location.pathname.includes('/find');
            const { locale } = this.props.match.params;

            return (
                <Box display="flex" alignItems="center" padding="25px 60px">
                    {/* <CartPopup /> */}
                    <Link to="/">
                        <LogoText t fontFamily="0">
                            ui.logo
                        </LogoText>
                    </Link>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        flexGrow={1}
                        ml="4"
                    ></Box>
                    {!isFindOpened && (
                        <>
                            <Link to="/books">
                                <Text t>ui.books</Text>
                            </Link>
                            <Link to="/merch">
                                <Text t>ui.merch</Text>
                            </Link>
                        </>
                    )}

                    <FindBox
                        onClick={() => this.toggleFindHover()}
                        className={isFindOpened ? 'opened' : ''}
                    >
                        {isFindOpened ? (
                            <FindInput
                                margin="0px 30px"
                                value={this.state.query}
                                onChange={(query: string) =>
                                    this.setState({ query })
                                }
                                onEnterClick={this.handleFindSubmit.bind(this)}
                                onKeyDown={this.handleFindSubmit.bind(this)}
                                onBlur={() =>
                                    this.setState({ isFindOpened: false })
                                }
                                onBack={this.handleBack}
                                isFindPage={isFindPage}
                            />
                        ) : (
                            <Text t>ui.find</Text>
                        )}
                    </FindBox>
                    <SelectLanguageButton
                        onClick={this.switchLocale}
                        language={locale}
                    />
                    {/* <Link to="/cart">
                        <Box display="flex">
                            <Text mr="2px !important" t>
                                ui.cart
                            </Text>
                            <Text
                                fontSize="15px"
                                mr="2px !important"
                                fontFamily="0"
                            >
                                (
                                {((_total: number) =>
                                    this.props.app.bag.books.map(
                                        (_book: any) => {
                                            _total += _book.amount;
                                        },
                                    ) && _total)(0)}
                                )
                            </Text>
                        </Box>
                    </Link> */}
                    {/* <Icon name="heart" />*/}
                    {/* {this.context.auth.isAuthorized ? (
                        <Link to="/profile">
                            <Text t>ui.profile</Text>
                        </Link>
                    ) : (
                        <GoToAuth to="/signin">
                            <Text t>ui.in</Text>
                        </GoToAuth>
                    )} */}
                </Box>
            );
        }
    },
);
