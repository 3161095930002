import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

// import styled from 'styled-components';
import {
    Box,
    /* CommentInput,*/ Href,
    /* Tag,*/ Text,
} from 'Src/components/basic';
import BookGallery from 'Src/components/BookGallery';
import Collapse from 'Src/components/Collapse';
// import Icon from 'Src/components/Icon';
import { Book, Carousel, Header } from 'Src/components/mobile';
import PageDelimeter from 'Src/components/PageDelimeter';
import { AppContext } from 'Src/model';

const Review = (props: any) => (
    <Box mt="20px 10px">
        <Href href={props.sourceLink}>
            <Text type="mbody1" textDecoration="underline">
                {props.source}
            </Text>
        </Href>
        <Text margin="10px 0 10px" type="mbody1">
            {props.text}
        </Text>
    </Box>
);

// const Comment = (props: any) => (
//     <Box margin="30px 0 30px">
//         <Text
//             type="mbody2"
//             margin="0 0 15px"
//             color={props.userName ? '#000' : '#999'}
//         >
//             {props.userName || 'Анонимный пользователь'}
//         </Text>
//         <Text type="mbody2">{props.comment}</Text>
//     </Box>
// );

// const tagColors = [
//     '#0C43ED',
//     '#EA3DF7',
//     '#F3FA75',
//     '#5ECA91',
//     '#DE9FF3',
//     '#FF450D',
// ];

// const StyledTag = styled(Tag)<{ bg: string }>`
//     background-color: ${(props: any) => props.bg};
//     font-family: Fugue;
//     font-size: 10px;
//     line-height: 12px;
//     text-transform: uppercase;
//     cursor: pointer;
//     &:hover {
//         background: black;
//         color: white;
//     }
// `;
const Author = (props: any) => (
    <Box margin="20px 0 10px">
        <Text
            type="mbody1"
            dangerouslySetInnerHTML={{
                __html: props.description,
            }}
        ></Text>
    </Box>
);

// const FlexBox = styled(Box)`
//     display: flex;
//     flex-flow: wrap;
// `;

class BookPage extends PureComponent<any> {
    state: any = {
        id: this.props.match.params.id,
    }; // todo: add state interface
    static contextType = AppContext;

    constructor(props: any) {
        super(props);
    }

    handleTagClick = (id: string) => {
        this.props.history.push(
            `/${this.context.locale.value}/books?topics=${id}`,
        );
    };
    handleVariantClick = (id: string) => this.setState({ id });
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.match.url !== this.props.match.url) {
            window.scrollTo(0, 0);
        }

        if (this.state.id !== prevState.id) {
            this.props.history.push(
                `/${this.context.locale.value}/items/${this.state.id}`,
            );
        }
    }

    render() {
        const { book, popular } = this.props;

        const _book =
            book.find(
                (__book: any) =>
                    __book.id.toString() === this.state.id.toString(),
            ) || book[0];
        // const _variants = book.map((__book: any) => ({
        //     isDigital: __book.isDigital,
        //     price: __book.price,
        //     id: __book.id,
        //     hiddenLink: __book.hiddenLink,
        // }));

        const authors = _book.authors.map((a: any) => a.name).join(', ');

        const aboutAuthor = _book.authors.reduce((sum: any, current: any) => {
            if (current.description !== '') {
                return ++sum;
            }

            return sum;
        }, 0);

        return (
            <Box>
                <Header
                    title="headers.strelka_press_books"
                    t
                    background="#F3FA75"
                    onClick={() =>
                        this.props.history.push(
                            `/${this.context.locale.value}/books`,
                        )
                    }
                />
                <Box margin="0px 20px 50px">
                    <Text
                        textTransform="uppercase"
                        margin="20px 0px 15px 0px"
                        letterSpacing="0.5px" // strange, but works
                        type="mh1"
                    >
                        {_book.title}
                    </Text>
                    <Text
                        textTransform="uppercase"
                        margin="10px 0px 20px 0"
                        type="mh2"
                    >
                        {authors}
                    </Text>
                    <BookGallery
                        size={[_book.width, _book.height]}
                        images={_book.images}
                        toLeft
                        withoutAnimation
                        mobile
                    />
                    {/* <Box margin="-10px 0 20px">
                        {_book.isInStore ? (
                            <AppContext.Consumer>
                                {(model: any) => (
                                    <Price
                                        width="100%"
                                        height="36px"
                                        value={_book.price}
                                        isPreorder={this.props.preorderBooksList.includes(
                                            _book.id,
                                        )}
                                        selected={this.state.id}
                                        type="book"
                                        onBagClick={() =>
                                            model.bag.addBook(
                                                book.find(
                                                    (_searchBook: any) =>
                                                        _searchBook.id.toString() ===
                                                        this.state.id.toString(),
                                                ),
                                            )
                                        }
                                        onVariantClick={this.handleVariantClick}
                                        fontType="mh2"
                                        mobile
                                        variants={_variants}
                                    />
                                )}
                            </AppContext.Consumer>
                        ) : (
                            <Text type="mh2" textTransform="uppercase">
                                нет в наличии
                            </Text>
                        )}
                    </Box> */}
                    <Text type="mbody1" mb="30px">
                        {_book.summary}
                    </Text>
                    {_book.description && (
                        <Collapse t mobile small title="book.description">
                            <Text
                                margin="20px 0 10px"
                                type="mbody1"
                                dangerouslySetInnerHTML={{
                                    __html: _book.description,
                                }}
                            ></Text>
                        </Collapse>
                    )}
                    {_book.reviews.length > 0 && (
                        <Collapse t mobile small title="book.reviews">
                            <Box mt="20px">
                                {_book.reviews.length > 0 && (
                                    <Review {..._book.reviews[0]} />
                                )}
                                {_book.reviews.length > 1 && (
                                    <Collapse
                                        t
                                        mobile
                                        small
                                        title="book.show_all"
                                        border="little"
                                    >
                                        {_book.reviews.map(
                                            (_review: any, _index: number) =>
                                                _index > 0 && (
                                                    <Review
                                                        key={_index}
                                                        {..._review}
                                                    />
                                                ),
                                        )}
                                    </Collapse>
                                )}
                            </Box>
                        </Collapse>
                    )}
                    {/* <Box
                        margin="20px 0"
                        display="flex"
                        justifyContent="flex-end"
                    >r
                        <Icon name="heartWhite" margin="0 10px" />
                        <Icon name="share" />
                    </Box> */}
                    {(_book.about.item ||
                        _book.about.isbn ||
                        _book.about.pages ||
                        _book.about.language ||
                        _book.about.coverType) && (
                        <Collapse t mobile small title="book.aboutItem">
                            <Box mt="20px" mb="10px">
                                {_book.about.item && (
                                    <Text
                                        type="mbody1"
                                        mb="2px"
                                        args={_book.about.year}
                                        t
                                    >
                                        book.year
                                    </Text>
                                )}
                                {_book.about.isbn && (
                                    <Text
                                        type="mbody1"
                                        mb="2px"
                                        args={_book.about.isbn}
                                        t
                                    >
                                        book.ISBN
                                    </Text>
                                )}
                                {_book.about.pages && (
                                    <Text
                                        type="mbody1"
                                        mb="2px"
                                        args={_book.about.pages}
                                        t
                                    >
                                        book.pagesAmount
                                    </Text>
                                )}
                                {_book.about.language && (
                                    <Text
                                        type="mbody1"
                                        mb="2px"
                                        args={_book.about.language}
                                        t
                                    >
                                        book.language
                                    </Text>
                                )}
                                {_book.about.coverType && (
                                    <Text
                                        type="mbody1"
                                        mb="2px"
                                        args={_book.about.coverType}
                                        t
                                    >
                                        book.coverType
                                    </Text>
                                )}
                            </Box>
                        </Collapse>
                    )}
                    <Collapse mobile small title="book.delivery" t>
                        <Box mt="20px" mb="10px">
                            <Text type="mbody1" mb="13px" t>
                                texts.delivery1
                            </Text>
                            <Text type="mbody1" mb="13px" t>
                                texts.delivery2
                            </Text>
                            <Text type="mbody1" mb="13px" t>
                                texts.delivery3
                            </Text>
                            <Text type="mbody1" mb="13px" t>
                                texts.delivery4
                            </Text>
                        </Box>
                    </Collapse>
                    {aboutAuthor > 0 && (
                        <Collapse mobile small title="book.aboutAuthor" t>
                            {_book.authors.map(
                                (_author: any, _index: number) => (
                                    <Author {..._author} key={_index} />
                                ),
                            )}
                        </Collapse>
                    )}
                    {/* <FlexBox mt="20px">
                        {book.tags.map((_tag: any, _index: number) => (
                            <StyledTag
                                key={_index}
                                bg={tagColors[_index % 6]}
                                margin="0px 7px 7px 0px"
                                onClick={() => this.handleTagClick(_tag.id)}
                            >
                                {_tag.tag}
                            </StyledTag>
                        ))}
                    </FlexBox>*/}
                    {/* <Text
                        margin="30px 0 20px"
                        textTransform="uppercase"
                        type="mh3"
                    >
                        Комментарии ({book.comments.length})
                    </Text>
                    {book.comments.map((_comment: any, _index: number) => (
                        <Comment {..._comment} key={_index} />
                    ))}
                    <CommentInput
                        placeholder="Добавьте комментарий..."
                        fontSize="12px"
                        fontFamily="1"
                        rows={2}
                    />*/}
                </Box>
                <PageDelimeter
                    title="headers.also_read"
                    background="#5ECA91"
                    color="#fff"
                    mobile
                />
                <Box margin="35px 0px 0px">
                    <Carousel
                        items={((total: any[]) =>
                            total.map((_: any, index: number) => [
                                popular[index * 2],
                                popular[index * 2 + 1],
                            ]))([0, 0])}
                        component={(_props: any) => (
                            <Box
                                display="flex !important"
                                flexDirection="row"
                                justifyContent="space-between"
                                margin="0 20px"
                            >
                                <Book {..._props[0]} />
                                <Book {..._props[1]} />
                            </Box>
                        )}
                    />
                </Box>
            </Box>
        );
    }
}

export default withRouter(BookPage);
