export function validateEmail(email = '', isRequired = true) {
    let error = '';

    if (!email) {
        if (isRequired) {
            error = 'Введите Email';
        }
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        error = 'Проверьте правильность введенного Email';
    }

    return error;
}

export function validateLogin(login = '', isRequired = true) {
    let error = '';

    if (login.length === 0) {
        if (isRequired) {
            error = 'Введите имя пользователя';
        }
    }

    return error;
}

export const cutString = (title: string, max: number): string => {
    if (title.length <= max) {
        return title;
    }

    return ((acc: string, cutted: any) => {
        return `${
            title
                .split(' ')
                .map((word: string) => {
                    acc += `${word} `;

                    if (acc.length > max) {
                        return cutted;
                    } else {
                        cutted = acc;

                        return false;
                    }
                })
                .filter((value: any) => !!value)[0]
        }...`;
    })('', '');
    // return 'test'
};
