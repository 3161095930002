import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Text } from 'Src/components/basic';
import { validateEmail } from 'Src/utils/validate';

const Img = styled.img`
    width: 100%;
    height: 40%;
    object-fit: contain;
    margin: 27px 0;
`;
const H1 = styled.h1`
    font-family: Fugue;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.3125px;
    text-transform: uppercase;
    color: #000000;
    margin: 15px 0;
`;
const H2 = styled.h2`
    font-family: Fugue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.1375px;
    text-transform: uppercase;
    color: #000000;
    margin: 14px 0;
`;
const P = styled.p`
    font-family: UniversRoman;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    mix-blend-mode: normal;
    margin: 17px 0 13px;
`;
const Error = styled.p`
    @keyframes in {
        from {
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            opacity: 1;
        }
    }
    font-family: UniversRoman;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    mix-blend-mode: normal;
    margin: 10px 0 13px;
    color: #fa3f00;
    animation: in 0.3s;
`;
const Hr = styled.hr`
    height: 1px;
    border: 0;
    background: black;
    margin: 0;
`;
const Button = styled.button`
    font-family: Fugue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1375px;
    text-transform: uppercase;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1a67f7;
    color: #ffffff;
    cursor: pointer;
    padding-top: 1px;
    border: 0;
    border-radius: 0;
    outline: none;
    appearance: none;
    &:disabled {
        background: #bababa;
    }
    &.error {
        background: #fa3f00;
    }
`;
const Input = styled.input`
    &[type='email'] {
        background: #f2f2f2;
        border: 0;
        &.notValid {
            box-shadow: 0px 2px 2px #ff450d;
        }
    }
    &[type='email']::placeholder,
    &[type='email'] {
        font-family: Fugue;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.1375px;
        text-transform: uppercase;
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 !important;
    }
    &[type='email']::placeholder {
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.5;
        height: 40px;
    }
`;

const Counter = styled.div`
    display: flex;
    margin-left: -29px;

    .counter-button {
        padding: 10px 30px;
        margin: 5px 0;
    }
    h2.amount {
        margin: 15px 0;
    }
`;
const Block = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Header = styled.div`
    background: #f3fa75;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Fugue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1375px;
    text-transform: uppercase;
    color: #000000;
`;
const Content = styled.div`
    padding: 0 22px;
`;
const ErrorText = styled(Text)`
    margin: 20px;
`;

interface InstantCartState {
    email: string;
    error: boolean;
    isEmailValid: boolean;
    isEmailTouched: boolean;
}

export default withRouter(
    class extends React.Component<any, InstantCartState> {
        state: InstantCartState = {
            email: '',
            error: false,
            isEmailValid: false,
            isEmailTouched: false,
        };

        componentDidMount() {
            this.props.context.getCart(this.props.match.params.cart);
        }
        handleIncrementCount(bookId: number) {
            this.props.context.increment(bookId);
        }
        handleDecrementCount(bookId: number) {
            this.props.context.decrement(bookId);
        }
        handleProceedClick = () => {
            setInterval(() => this.setState({ error: true }), 1000);
        };

        handleEmailClick = () => this.setState({ isEmailTouched: true });
        handleEmailChange = (e: any) =>
            this.setState({ email: e.target.value }, () =>
                this.setState({
                    isEmailValid: validateEmail(this.state.email),
                }),
            );
        handleCreateOrder = () => {
            this.props.context.createOrder({
                name: 'test',
                surname: 'test',
                phone: 'test',
                email: this.state.email,
                isDelivering: false,
            });
        };

        render() {
            const {
                items,
                cartId,
                total,
                isRequesting,
                error,
            } = this.props.context;

            return error !== null ? (
                <>
                    <Header>YOUR CART</Header>
                    <ErrorText type="mbody1">
                        404. This book is not available
                    </ErrorText>
                </>
            ) : isRequesting ? (
                <Text>loading</Text>
            ) : (
                <>
                    <Header>YOUR CART({items.length})</Header>
                    <Content>
                        <Img
                            height="30%"
                            src={
                                items[0].images.find(
                                    (image: any) => image.isSpecial,
                                ).path
                            }
                        />
                        <H1>{items[0].title}</H1>
                        <Hr />
                        <Block>
                            <Counter>
                                <H2
                                    onClick={
                                        items[0].amount === 1
                                            ? undefined
                                            : () =>
                                                  this.handleDecrementCount(
                                                      items[0].id,
                                                  )
                                    }
                                    className="counter-button"
                                >
                                    –
                                </H2>
                                <H2 className="amount">{items[0].amount}</H2>
                                <H2
                                    onClick={() =>
                                        this.handleIncrementCount(items[0].id)
                                    }
                                    className="counter-button"
                                >
                                    +
                                </H2>
                            </Counter>
                            <H2>{items[0].price / 100}</H2>
                        </Block>
                        <Hr />
                        <Block>
                            <H2>TOTAL</H2>
                            <H2>{total / 100}</H2>
                        </Block>
                        <Input type="hidden" name="cartId" value={cartId} />
                        <br />
                        <Input
                            className={
                                this.state.isEmailTouched &&
                                this.state.email.length > 0
                                    ? this.state.isEmailValid
                                        ? ''
                                        : 'notValid'
                                    : ''
                            }
                            type="email"
                            name="email"
                            value={this.state.email}
                            placeholder="ENTER YOUR EMAIL"
                            id="emailInput"
                            onClick={this.handleEmailClick}
                            onChange={this.handleEmailChange}
                        />
                        <P>
                            {
                                "You'll get the order number on this email\naddress, please verify carefully."
                            }
                        </P>
                        <Button
                            className={this.state.error ? 'error' : ''}
                            disabled={!this.state.isEmailValid}
                            onClick={this.handleCreateOrder}
                        >
                            Proceed to payment
                        </Button>
                        {this.state.error && (
                            <Error>Server is temporally unavailable</Error>
                        )}
                    </Content>
                </>
            );
        }
    },
);
