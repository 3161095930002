import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import { Cover } from 'Src/components/ResizedImage';
import { cutString } from 'Src/utils/helpers';

const LinkBlock = styled(Link)`
    display: block;
`;

export default function(props: any) {
    // const authors = props.authors.map((a: any) => a.name).join(', ');
    const cover = props.images.find((img: any) => Boolean(img.isCover));
    const coverImageUrl = cover ? cover.path : '';
    const link = `/items/${props.id}`;

    if (props.view === 'one') {
        return (
            <LinkBlock to={link}>
                <Box mb="40px" mt="20px">
                    <Box display="flex" justifyContent="center">
                        <Cover
                            height={280}
                            path={coverImageUrl}
                            margin="auto"
                        />
                    </Box>
                    <Box mx="20px" mt="20px">
                        <Text
                            mt="10px"
                            mb={2}
                            type="mh3"
                            textTransform="uppercase"
                        >
                            {props.title}
                        </Text>
                        <Text
                            pt={2}
                            type="mh3"
                            mb="40px"
                            args={props.price / 100}
                            t
                        >
                            ui.price
                        </Text>
                    </Box>
                </Box>
            </LinkBlock>
        );
    } else {
        return (
            <LinkBlock to={link}>
                <Box width="40vw">
                    <Cover
                        centered
                        height={140}
                        path={coverImageUrl}
                        margin="0"
                    />
                    <Text pt={3} type="mh3" textTransform="uppercase">
                        {cutString(
                            props.title,
                            window.outerWidth > 355 ? 50 : 35,
                        )}
                    </Text>
                    <Text
                        pt={2}
                        type="mh3"
                        mb="40px"
                        args={props.price / 100}
                        t
                    >
                        ui.price
                    </Text>
                </Box>
            </LinkBlock>
        );
    }
}
