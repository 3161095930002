// import { themeGet } from '@styled-system/theme-get';
import * as React from 'react';
import MediaQuery from 'react-responsive';

import { AppContext } from 'Src/model';
import { MerchShop as MerchDesktop } from 'Src/pages';
import { MerchPage as MerchMobile } from 'Src/pages/mobile';
import { MerchAll as MerchLoader } from 'Src/pages/PageLoaders';
import { MerchAll as MobileMerchLoader } from 'Src/pages/PageLoaders/mobile';
import { obj2uri, uri2obj } from 'Src/utils/filters2uri';

export default class extends React.PureComponent<any> {
    static contextType = AppContext;
    state: any = {
        items: [],
        popular: [],
        view: uri2obj(this.props.location.search).view || 'grid8',
    }; // todo: add state interface

    async fetchMerch() {
        const merch = await this.context.merch.get();

        this.setState({ items: merch });
    }
    async fetchPopular() {
        const popular = await this.context.popular.get();

        this.setState({ popular });
    }

    setView(view: string) {
        this.setState({ view: view }, () =>
            this.props.history.replace(
                `/${this.context.locale.value}/merch${obj2uri({
                    view: this.state.view,
                })}`,
            ),
        );
    }

    componentDidMount() {
        this.fetchMerch();
        this.fetchPopular();
    }
    render() {
        return this.state.items.length === 0 ? (
            <React.Fragment>
                <MediaQuery maxDeviceWidth={600}>
                    <MobileMerchLoader />
                </MediaQuery>
                <MediaQuery minDeviceWidth={601}>
                    <MerchLoader />
                </MediaQuery>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <MediaQuery maxDeviceWidth={600}>
                    <MerchMobile {...this.state} context={this.context} />
                </MediaQuery>
                <MediaQuery minDeviceWidth={601}>
                    <MerchDesktop
                        setView={this.setView.bind(this)}
                        {...this.state}
                        context={this.context}
                    />
                </MediaQuery>
            </React.Fragment>
        );
    }
}
