import React, { Fragment, useContext, useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { getDeliveryPrice } from 'Src/api';
import { AppContext } from 'Src/model';
import { Order as OrderDesktop } from 'Src/pages';
import { Order as OrderMobile } from 'Src/pages/mobile';
import { filterObject, obj2uri, uri2obj } from 'Src/utils/filters2uri';

interface OrderViewState {
    name?: string;
    surname?: string;
    email?: string;
    phone?: string;
    country?: string;
    city?: string;
    address?: string;
    comment?: string;
    deliveryPrice?: number | null;
    isDelivering?: boolean;
    isEbooks?: boolean;
    isChecked?: boolean;
    isValid?: boolean;
}

export default function OrderView() {
    const context = useContext<any>(AppContext);
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    const [state, setState] = useState<OrderViewState>({
        name: '',
        surname: '',
        email: '',
        phone: '',
        country: 'Россия',
        city: '',
        address: '',
        comment: '',
        isDelivering: true,
        isEbooks: false,
        deliveryPrice: null,
        isChecked: false,
        isValid: false,
    });

    useEffect(() => {
        setState(oldState => ({
            ...oldState,
            ...uri2obj(location.search),
        }));
    }, [location.search.length > 0]);

    useEffect(() => {
        getDeliveryPrice(context.bag.id, state.country, state.city).then(
            (res: any) => {
                if (res.deliveryPrice) {
                    setState(oldState => ({
                        ...oldState,
                        deliveryPrice: Math.floor(res.deliveryPrice / 100),
                    }));
                } else {
                    setState(oldState => ({
                        ...oldState,
                        deliveryPrice: null,
                    }));
                }
            },
        );
    }, [state.country, state.city]);

    useEffect(() => {
        setState(oldState => ({
            ...oldState,
            isValid:
                typeof state.name !== 'undefined' &&
                state.name.length > 0 &&
                typeof state.surname !== 'undefined' &&
                state.surname.length > 0 &&
                typeof state.email !== 'undefined' &&
                state.email.length > 0 &&
                state.isChecked,
        }));

        history.push(
            // @ts-ignore
            match.url +
                obj2uri(
                    filterObject(state, [
                        'name',
                        'surname',
                        'email',
                        'phone',
                        'city',
                        'address',
                        'comment',
                    ]),
                ),
        );
    }, [state.name, state.surname, state.email, state.isChecked]);

    const handleChange = (field: any, value: any) => {
        setState(oldState => ({ ...oldState, [field]: value }));
    };

    const handleSubmit = () => {
        const order = !state.isDelivering
            ? {
                  name: state.name,
                  surname: state.surname,
                  email: state.email,
                  phone: state.phone,
                  isDelivering: state.isDelivering,
              }
            : {
                  name: state.name,
                  surname: state.surname,
                  email: state.email,
                  phone: state.phone,
                  city: state.city,
                  country: state.country,
                  address: state.address,
                  comment: state.comment,
                  isDelivering: state.isDelivering,
              };

        context.order.createNewOrder(order, history.push);
    };

    return (
        <Fragment>
            <MediaQuery maxDeviceWidth={600}>
                <OrderMobile
                    {...state}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                />
            </MediaQuery>
            <MediaQuery minDeviceWidth={601}>
                <OrderDesktop
                    {...state}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                />
            </MediaQuery>
        </Fragment>
    );
}
