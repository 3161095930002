import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';
import Icon from 'Src/components/Icon';
import { Book, Carousel, Header, Merch } from 'Src/components/mobile';
import PageDelimeter from 'Src/components/PageDelimeter';

const Topbar = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 15px 0;
`;
const Content = styled.div`
    margin-top: 20px;
`;
const ViewIcon = styled(Icon)<{ activeName: string }>`
    opacity: ${props => (props.name === props.activeName ? 1 : 0.25)};
`;

export default withRouter(function(props: any) {
    const [view, setView] = useState('square');

    return (
        <React.Fragment>
            <Header
                background="#F3FA75"
                title="ui.merch"
                t
                onClick={() => props.history.push('/merch')}
            />
            <Box margin="0 20px">
                <Topbar>
                    <Box display="flex">
                        <ViewIcon
                            size="24px"
                            activeName={view}
                            name="grid4"
                            onClick={() => setView('grid4')}
                        />
                        <ViewIcon
                            size="24px"
                            activeName={view}
                            name="square"
                            margin="0 0 0 15px"
                            onClick={() => setView('square')}
                        />
                    </Box>
                </Topbar>
                <Content>
                    <Box
                        display="flex"
                        flexDirection={view === 'square' ? 'column' : 'row'}
                        flexWrap="wrap"
                        justifyContent="space-between"
                    >
                        {props.items.map((book: any) => (
                            <Merch
                                key={book.id}
                                {...book}
                                view={view === 'grid4' ? 'grid' : 'one'}
                                withButtonPrice={true}
                            />
                        ))}
                    </Box>
                </Content>
            </Box>
            <PageDelimeter
                title="headers.popular"
                background="#EA3DF7"
                color="#fff"
                mobile
            />
            <Carousel
                items={((total: any) =>
                    total.map((_: never, index: number) => [
                        props.popular[index * 2],
                        props.popular[index * 2 + 1],
                    ]))([0, 0])}
                component={(_props: any) => (
                    <Box
                        display="flex !important"
                        flexDirection="row"
                        justifyContent="space-between"
                        margin="35px 20px 0"
                    >
                        <Book {..._props[0]} />
                        <Book {..._props[1]} />
                    </Box>
                )}
            />
        </React.Fragment>
    );
});
