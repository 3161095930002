import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ListHeader, Text } from 'Src/components/basic';
import { Box } from 'Src/components/basic';
import Icon from 'Src/components/Icon';
import { Book, Carousel, Features, Grid, Signup } from 'Src/components/mobile';

const ButtonSeeAll = styled.button`
    width: 100%;
    background: #fff;
    outline: 0;
`;
const StyledIcon = styled(Icon)`
    width: 10px;
    height: 10px;
`;
const InlineText = styled(Text)`
    display: inline;
`;

const Main: React.FunctionComponent<any> = config => {
    return (
        <Box position="relative">
            <Features features={config.props.promoItems} />
            <ListHeader bt background="#fff" color="#000" to="/books">
                <Text type="mh2" textTransform="uppercase" t>
                    headers.strelka_press_books
                </Text>
            </ListHeader>
            <Grid {...config.blocks[0]} booksAmount={4} />
            <Link to="/books">
                <Box margin="0 0 20px">
                    <ButtonSeeAll>
                        <InlineText type="mh3" textTransform="uppercase" t>
                            ui.see_all
                        </InlineText>
                        <StyledIcon margin="0 0 -1px 5px" name="arrowLink" />
                    </ButtonSeeAll>
                </Box>
            </Link>
            <ListHeader bt background="#fff" color="#000" to="/merch">
                <Text type="mh2" textTransform="uppercase" t>
                    headers.strelka_press_merch
                </Text>
            </ListHeader>
            <Grid {...config.blocks[1]} booksAmount={4} />
            <Link to="/merch">
                <Box margin="0 0 20px">
                    <ButtonSeeAll>
                        <InlineText type="mh3" textTransform="uppercase" t>
                            ui.see_all
                        </InlineText>
                        <StyledIcon margin="0 0 -1px 5px" name="arrowLink" />
                    </ButtonSeeAll>
                </Box>
            </Link>
            <ListHeader bt background="#fff" color="#000" margin="0px 0px 30px">
                <Text type="mh2" textTransform="uppercase" t>
                    headers.popular
                </Text>
            </ListHeader>
            <Carousel
                items={((total: any) =>
                    total.map((_: never, index: number) => [
                        config.blocks[2].children[index * 2],
                        config.blocks[2].children[index * 2 + 1],
                    ]))([0, 0, 0])}
                component={(_props: any) => (
                    <Box
                        display="flex !important"
                        flexDirection="row"
                        justifyContent="space-between"
                        margin="0 20px"
                    >
                        <Book {..._props[0]} />
                        <Book {..._props[1]} />
                    </Box>
                )}
            />
            <Signup />
        </Box>
    );
};

export default Main;
