import React, { Fragment } from 'react';
import styled from 'styled-components';

// import { order } from 'styled-system';
import { Box, Button, Text } from 'Src/components/basic';
import Collapse from 'Src/components/Collapse';
// import GoToAuth from 'Src/components/GoToAuth';
import {
    Header,
    OrderEbooks,
    OrderFormCourier,
    OrderFormSelf,
} from 'Src/components/mobile';
import RadioItem from 'Src/components/RadioItem';
import { Cover } from 'Src/components/ResizedImage';
import { AppContext } from 'Src/model';

const InlineText = styled(Text)`
    display: inline;
    white-space: normal;
`;

export default function Order(props: any) {
    return (
        <AppContext.Consumer>
            {(model: any) => (
                <Box>
                    <Header
                        title="headers.order"
                        background="#F3FA75"
                        margin="0 0 35px"
                        t
                    />
                    {model.bag.books.every((book: any) => book.isDigital) ? (
                        <>
                            <Text
                                type="mh2"
                                textTransform="uppercase"
                                mb="25px"
                                t
                            >
                                order.ebooks
                            </Text>
                            <OrderEbooks {...props} />
                        </>
                    ) : (
                        <>
                            <Box margin="0 20px">
                                <Text
                                    type="mh2"
                                    textTransform="uppercase"
                                    mb="25px"
                                    t
                                >
                                    order.pay_by
                                </Text>
                                {
                                    <RadioItem
                                        size="17px"
                                        value={!props.isDelivering}
                                        onChange={() =>
                                            props.onChange(
                                                'isDelivering',
                                                false,
                                            )
                                        }
                                        mobile
                                    >
                                        <Fragment>
                                            <Text
                                                type="mh2"
                                                textTransform="uppercase"
                                                mb="2"
                                                t
                                            >
                                                order.pick_up
                                            </Text>
                                            <Text type="mbody2" t>
                                                order.address
                                            </Text>
                                        </Fragment>
                                    </RadioItem>
                                }
                                {/* {!props.isDelivering && (
                                        <GoToAuth>
                                            <Text
                                                mb="20px"
                                                mt="10px"
                                                color="#1A67F6"
                                                t
                                                type="mbody2"
                                            >
                                                order.sign_in
                                            </Text>
                                        </GoToAuth>
                                    )} */}
                                {!props.isDelivering && (
                                    <OrderFormSelf {...props} />
                                )}
                                {/* <RadioItem
                                    size="17px"
                                    value={props.isDelivering}
                                    onChange={() =>
                                        props.onChange('isDelivering', true)
                                    }
                                    mobile
                                >
                                    <Text
                                        type="mh2"
                                        textTransform="uppercase"
                                        t
                                    >
                                        order.courier
                                    </Text>
                                </RadioItem> */}
                                {/* {props.isDelivering && (
                                        <GoToAuth>
                                            <Text
                                                mb="20px"
                                                mt="-10px"
                                                color="#1A67F6"
                                                t
                                                type="mbody2"
                                            >
                                                order.sign_in
                                            </Text>
                                        </GoToAuth>
                                    )} */}
                                {props.isDelivering && (
                                    <OrderFormCourier {...props} />
                                )}
                            </Box>
                        </>
                    )}
                    <Box>
                        <Box
                            padding="8px 20px 5px"
                            borderTop="1px solid #000"
                            borderBottom="1px solid #000"
                        >
                            <Collapse
                                mobile
                                fontType="mh2"
                                args={((_total: number) =>
                                    model.bag.books.map((_book: any) => {
                                        _total += _book.amount;
                                    }) && _total)(0)}
                                title="order.your_cart"
                                border="little"
                                small
                                t
                            >
                                {model.bag.books.map((book: any) => (
                                    <Box
                                        key={book.id}
                                        my="20px"
                                        display="flex"
                                        alignItems="end"
                                    >
                                        <Cover
                                            width={90}
                                            height={90}
                                            path={book.images[0].path}
                                            margin="0"
                                        />
                                        <Box
                                            flexGrow={1}
                                            width="165px"
                                            mx="30px"
                                        >
                                            {model.bag.preorderIds.includes(
                                                book.id,
                                            ) && (
                                                <Text
                                                    t
                                                    type="mh3"
                                                    textTransform="uppercase"
                                                    color="#1A67F6"
                                                >
                                                    ui.preorder
                                                </Text>
                                            )}
                                            <Text
                                                t={book.type === 'books'}
                                                type="mh3"
                                                textTransform="uppercase"
                                                args={book.title}
                                            >
                                                {book.type === 'books'
                                                    ? 'ui.book_title'
                                                    : `${
                                                          book.title
                                                      }, ${Object.values(
                                                          book.variants,
                                                      )}`}
                                            </Text>
                                            {book.type === 'books' && (
                                                <Text
                                                    t
                                                    type="mh3"
                                                    textTransform="uppercase"
                                                    mr="90px"
                                                >
                                                    {book.isDigital
                                                        ? 'ui.book_e_version'
                                                        : 'ui.book_paper_version'}
                                                </Text>
                                            )}
                                            <Text
                                                type="mh3"
                                                py="2"
                                                textTransform="uppercase"
                                                args={
                                                    (book.amount * book.price) /
                                                    100
                                                }
                                                t
                                            >
                                                ui.price
                                            </Text>
                                            <Text
                                                type="mh3"
                                                textTransform="uppercase"
                                                args={book.amount}
                                                t
                                            >
                                                order.amount
                                            </Text>
                                        </Box>
                                    </Box>
                                ))}
                            </Collapse>
                        </Box>
                        <Box margin="0 20px 50px">
                            {props.isDelivering && (
                                <Box py="magic.0">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Text
                                            type="mh2"
                                            textTransform="uppercase"
                                            t
                                        >
                                            order.total
                                        </Text>
                                        <Text
                                            type="mh2"
                                            textTransform="uppercase"
                                            args={((total: number) =>
                                                model.bag.books.map(
                                                    (_book: any) => {
                                                        total +=
                                                            _book.price *
                                                            _book.amount;
                                                    },
                                                ) && total / 100)(0)}
                                            t
                                        >
                                            ui.price
                                        </Text>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        mt="magic.0"
                                    >
                                        <Text
                                            type="mh2"
                                            textTransform="uppercase"
                                            t
                                        >
                                            order.delivery
                                        </Text>
                                        <Text
                                            type="mh2"
                                            textTransform="uppercase"
                                            args={props.deliveryPrice}
                                            t
                                        >
                                            {props.deliveryPrice === null
                                                ? 'ui.delivery_load'
                                                : 'ui.price'}
                                        </Text>
                                    </Box>
                                </Box>
                            )}
                            <Box
                                py="20px"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Text type="mh2" textTransform="uppercase" t>
                                    ui.total
                                </Text>
                                <Text
                                    type="mh2"
                                    textTransform="uppercase"
                                    args={
                                        ((total: number) =>
                                            model.bag.books.map(
                                                (_book: any) => {
                                                    total +=
                                                        _book.price *
                                                        _book.amount;
                                                },
                                            ) && total / 100)(0) +
                                        (props.isDelivering
                                            ? props.deliveryPrice
                                            : 0)
                                    }
                                    t
                                >
                                    ui.price
                                </Text>
                            </Box>
                            <RadioItem
                                mobile
                                value={props.isChecked}
                                onChange={(checked: boolean) =>
                                    props.onChange('isChecked', checked)
                                }
                                size="16px"
                            >
                                <Box flexWrap="wrap" marginTop="0px">
                                    <InlineText
                                        t
                                        whiteSpace="nowrap"
                                        type="mbody2"
                                        lineHeight="15px !important"
                                    >
                                        order.agreement1
                                    </InlineText>
                                    &nbsp;
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/rules.pdf"
                                    >
                                        <InlineText
                                            t
                                            whiteSpace="nowrap"
                                            type="mbody2"
                                            lineHeight="15px !important"
                                            textDecoration="underline"
                                        >
                                            order.agreement2
                                        </InlineText>
                                    </a>
                                    &nbsp;
                                    <InlineText
                                        t
                                        whiteSpace="nowrap"
                                        type="mbody2"
                                        lineHeight="15px !important"
                                    >
                                        ui.and
                                    </InlineText>
                                    &nbsp;
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/privacy-policy.pdf"
                                    >
                                        <InlineText
                                            t
                                            whiteSpace="nowrap"
                                            type="mbody2"
                                            lineHeight="15px !important"
                                            textDecoration="underline"
                                        >
                                            order.agreement3
                                        </InlineText>
                                    </a>
                                </Box>
                            </RadioItem>
                            <Button
                                backgroundColor="blue"
                                color="white"
                                style={{ border: 'none', gridColumn: 'span 2' }}
                                padding="13px 0"
                                margin="30px 0 0"
                                onClick={props.onSubmit}
                                disabled={!props.isValid}
                                fontFamily="Fugue"
                                fontSize="15px"
                            >
                                <Text type="mh2" textTransform="uppercase" t>
                                    order.pay
                                </Text>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </AppContext.Consumer>
    );
}
