import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// import { withLocale } from 'Src/locales'
import { Box, Text } from 'Src/components/basic';
// import Price from 'Src/components/Price';
import { Cover as ResizedCover } from 'Src/components/ResizedImage';
import { AppContext } from 'Src/model';
// import { cutString } from 'Src/utils/helpers';

const LinkBlock = styled(Box)<{ withHover?: boolean }>`
    display: block;
    transition: 0.1s;
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
    &.line {
        display: flex;
        padding: 37px 27px;
    }
`;
const LineWrapper = styled(Box)`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 27px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    width: calc(100% + 54px);
    margin-left: -27px;
`;

class Book extends React.PureComponent<any> {
    static contextType = AppContext;
    state: any = {
        isHovered: false,
        isSuccessfullyAdded: false,
        time: 0,
    };
    handleBookClick = (book: any) => {
        this.context.bag.addBook(book);
        this.setState(
            () => ({ isSuccessfullyAdded: true }),
            () =>
                setTimeout(() => {
                    this.setState({ isSuccessfullyAdded: false });
                }, 3000),
        );
    };
    handleMouseDown = (e: any) => {
        this.setState({ time: e.timeStamp });
    };
    handleMouseUp = (e: any, link: string) => {
        if (e.timeStamp - this.state.time < 350) {
            this.props.history.push(link);
        }
    };
    toggleHover = (isHovered: boolean) => this.setState({ isHovered });
    render() {
        // debugger;
        const cover = this.props.images.find((img: any) =>
            Boolean(img.isCover),
        );
        const coverImageUrl = cover ? cover.path : '';
        const link = `/${this.context.locale.value}/items/${this.props.id}`;

        // const isMerch = this.props.categories
        //     .map((cat: any) => cat.category)
        //     .includes('merch');

        // const isBook = this.props.categories.some(
        //     (cat: any) => cat.category === 'books',
        // );

        const isOneVariant =
            this.props.variants &&
            Object.keys(this.props.variants).length === 0;

        if (this.props.presentation === 'line') {
            // debugger
            return (
                <LineWrapper>
                    <LinkBlock
                        onMouseDown={(e: any) => this.handleMouseDown(e)}
                        onMouseUp={(e: any) => this.handleMouseUp(e, link)}
                        withHover={true}
                        className="line"
                    >
                        <ResizedCover
                            path={coverImageUrl}
                            height={213}
                            objectFit={isOneVariant ? 'contain' : 'contain'}
                            margin="0 50px 0 0"
                            line
                        />
                        <Box flexGrow={1} mr="magic.3">
                            <Text textTransform="uppercase" type="h4">
                                {this.props.title}
                            </Text>
                            <Text mt="10px" textTransform="uppercase" type="h4">
                                {this.props.authors
                                    .map((_author: any) => _author.name)
                                    .join(', ')}
                            </Text>
                            <Text pt={3} type="body2">
                                {this.props.summary}
                            </Text>
                        </Box>
                    </LinkBlock>
                    <Box
                        p="37px 0"
                        flexShrink={0}
                        onClick={e => e.preventDefault()}
                    >
                        {this.props.isInStore && (
                            // ? (
                            //     // {
                            //         /*                            <Price
                            //         value={this.props.price}
                            //         width="230px"
                            //         height="36px"
                            //         fontType="h5"
                            //         id={this.props.id}
                            //         onBagClick={() =>
                            //             this.handleBookClick(this.props)
                            //         }
                            //     />*/
                            //     // }
                            // ) : (
                            <Text
                                type="h5"
                                t
                                args={this.props.price / 100}
                                mt="28px"
                            >
                                нет в наличии
                            </Text>
                        )
                        // )
                        }
                    </Box>
                </LineWrapper>
            );
        } else if (this.props.presentation === 'grid3') {
            return (
                <Box
                    width="magic.6"
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    minHeight="600px"
                    onMouseEnter={() => this.toggleHover(true)}
                    onMouseLeave={() => this.toggleHover(false)}
                >
                    <LinkBlock
                        onMouseDown={(e: any) => this.handleMouseDown(e)}
                        onMouseUp={(e: any) => this.handleMouseUp(e, link)}
                    >
                        <ResizedCover
                            path={coverImageUrl}
                            height={480}
                            objectFit={isOneVariant ? 'contain' : 'contain'}
                            maxWidth={370}
                            margin="0"
                        />
                        <Box>
                            <Text pt="20px" type="h5" textTransform="uppercase">
                                {this.props.title}
                            </Text>
                        </Box>
                    </LinkBlock>
                    {!this.props.isInStore ? (
                        <Text
                            type="h5"
                            args={this.props.price / 100}
                            textTransform="uppercase"
                            mt="28px"
                        >
                            нет в наличии
                        </Text>
                    ) : this.state.isSuccessfullyAdded ||
                      this.state.isHovered ||
                      this.props.withButtonPrice ? (
                        <Box pt="18px" onClick={e => e.preventDefault()}>
                            {/*                            <Price
                                className="buttonPrice"
                                value={this.props.price}
                                title={!isOneVariant ? 'ui.more' : 'ui.in_bag'}
                                fontType="h5"
                                height="37px"
                                id={this.props.id}
                                onBagClick={
                                    !isOneVariant
                                        ? () => this.props.history.push(link)
                                        : () => this.handleBookClick(this.props)
                                }
                            />*/}
                        </Box>
                    ) : (
                        <Text
                            type="h5"
                            t
                            args={this.props.price / 100}
                            mt="28px"
                        >
                            ui.rub
                        </Text>
                    )}
                </Box>
            );
        } else {
            return (
                <Box
                    width="magic.5"
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    minHeight="500px"
                    onMouseEnter={() => this.toggleHover(true)}
                    onMouseLeave={() => this.toggleHover(false)}
                >
                    <LinkBlock
                        onMouseDown={(e: any) => this.handleMouseDown(e)}
                        onMouseUp={(e: any) => this.handleMouseUp(e, link)}
                    >
                        <ResizedCover
                            path={coverImageUrl}
                            height={360}
                            objectFit={isOneVariant ? 'contain' : 'contain'}
                            maxWidth={250}
                            width={250}
                            margin="0"
                        />
                        <Text pt="20px" type="h5" textTransform="uppercase">
                            {this.props.title}
                        </Text>
                        {!this.props.isInStore ? (
                            <Text
                                type="h5"
                                args={this.props.price / 100}
                                textTransform="uppercase"
                                mt="28px"
                            >
                                нет в наличии
                            </Text>
                        ) : (
                            <Text
                                type="h5"
                                t
                                args={this.props.price / 100}
                                mt="12px"
                            >
                                ui.rub
                            </Text>
                        )}
                    </LinkBlock>
                    {(this.state.isSuccessfullyAdded ||
                        this.state.isHovered ||
                        this.props.withButtonPrice) && (
                        <Box pt="18px" onClick={e => e.preventDefault()}>
                            {/*                            <Price
                                className="buttonPrice"
                                value={this.props.price}
                                item={this.props}
                                type={isOneVariant ? 'book' : 'merch'}
                                fontType="h5"
                                id={this.props.id}
                                onBagClick={
                                    !isOneVariant
                                        ? () => this.props.history.push(link)
                                        : () => this.handleBookClick(this.props)
                                }
                            />*/}
                        </Box>
                    )}
                </Box>
            );
        }
    }
}

export { Book };
export default withRouter(Book);
