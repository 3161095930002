import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import Icon from 'Src/components/Icon';

const Input = styled.input`
    margin-left: 4px;
    border-bottom: 1px solid #000;
    width: 100%;
    font-size: 11px;
    font-family: UniversLight;
    padding: 0;
    outline: 0;
`;

export default (props: any) => (
    <Box
        margin={props.margin}
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
    >
        <Icon name="glass" size={24} />
        <Box width="100%">
            <Link to="/find">
                <Input
                    value={props.value}
                    onChange={({ target: { value } }) => props.onChange(value)}
                />
            </Link>
        </Box>
        <Text
            type="mh2"
            ml="20px"
            mb="-4px"
            onClick={props.onLocaleClick}
            textTransform="uppercase"
        >
            {props.locale}
        </Text>
    </Box>
);
