import * as React from 'react';
import styled from 'styled-components';

import { Box, Text as BaseText } from 'Src/components/basic';
import { AppContext } from 'Src/model';

const RenderLink = (
    mobile: boolean,
    link: string,
    fontType: string,
    height: string,
) => (
    <AddContainer height="38px">
        <Cover>
            <a href={link} rel="noopener" target="__blank">
                <Text
                    mobile={mobile}
                    type={fontType || 'h4'}
                    py={mobile && height !== '28px' ? '11px' : '2'}
                    className={'price-text'}
                    t
                >
                    ui.download
                </Text>
            </a>
        </Cover>
    </AddContainer>
);

const Container = styled(Box)<{ oneSize?: boolean }>`
    height: ${props => (props.oneSize ? '39px' : '78px')};
`;
const VariantsContainer = styled(Box)`
    padding: 0 17px;
    color: #000;
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row;
    height: 38px;
    width: 100%;
`;
const AddContainer = styled(Box)<{ height?: any }>`
    display: flex;
    background: transparent;
    cursor: pointer;
    height: ${(props: any) => props.height || 'auto'};
    text-decoration: none !important;
    vertical-align: middle;
    .text {
        width: 100%;
        z-index: 2;
        transition: 0.35s ease-in-out;
    }
    .price-text {
        width: 100px;
        color: #000;
        z-index: 2;
        transition: 0.35s ease-in-out;
    }

    .price,
    .cart {
        transition: 0.35s ease-in-out;
    }

    .price .text {
        color: rgb(255, 255, 255);
    }

    .cart .text {
        color: rgb(0, 0, 0);
    }

    .bg {
        position: absolute;
        transition: 0.3s ease-in-out;
        height: 100%;
        top: 0;
        z-index: -1;
    }

    .priceWhite {
        background: black;
        width: 100%;
        left: 0;
    }

    .priceBlack {
        background: rgb(255, 255, 255);
        right: 0;
        width: 0;
    }

    .cartBlack {
        background: rgb(255, 255, 255);
        width: 100%;
        right: 0;
    }

    .cartWhite {
        background: black;
        left: 0;
        width: 0;
    }

    .clicked.price {
        width: 0;
        .text {
            font-size: 0;
        }
    }

    .clicked.cart {
        width: 100%;
        .cartWhite {
            width: 0;
        }
        .cartBlack {
            width: 100%;
        }
        .text {
            color: rgb(0, 0, 0);
        }
    }

    &:hover {
        .text {
            transition: 0.35s ease-in-out;
        }
        .price .text {
            color: rgb(0, 0, 0);
        }

        .cart .text {
            color: rgb(255, 255, 255);
        }

        .bg {
            transition: 0.3s ease-in-out;
        }

        .priceWhite,
        .cartBlack {
            width: 0;
        }

        .priceBlack,
        .cartWhite {
            width: 100%;
        }

        .clicked.price {
            width: 0;
            .text {
                font-size: 0;
            }
        }

        .clicked.cart {
            width: 100%;
            .cartWhite {
                width: 0;
            }
            .cartBlack {
                width: 100%;
            }
            .text {
                color: rgb(0, 0, 0);
            }
        }
    }
`;
const Text = styled(BaseText)<{ mobile: boolean }>`
    text-transform: uppercase;
    user-select: none;
    height: 100%;
`;

const Cover = styled.div`
    padding: 0 17px;
    position: relative;
    width: 100%;
    display: flex;
`;

const VariantsWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 38px;
`;
const Variant = styled(Box)<{
    disabled?: boolean;
    isSelected: boolean;
    deactive?: boolean;
}>`
    align-items: center;
    display: flex;
    height: 38px;
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 4px;
    ${props =>
        props.deactive &&
        `
        cursor: default;
        color: #797979;
        `}
    color: ${props => (props.isSelected ? '#1A67F7' : '000')};
`;

// value: number;
//   className?: string;
//   onBagClick?: () => void;
//   small?: any;
//   width?: number; todo: add I

const BookVariants = (props: any) => {
    const _digital = props.variants.find((_variant: any) => _variant.isDigital);

    const _paper = props.variants.find((_variant: any) => !_variant.isDigital);

    return (
        <VariantsContainer>
            <Variant
                isSelected={
                    _paper
                        ? props.selected.toString() === _paper.id.toString()
                        : false
                }
                onClick={
                    _paper
                        ? () => props.onClick(_paper.id.toString())
                        : () => null
                }
                deactive={!_paper}
            >
                бумага /{' '}
            </Variant>
            <Variant
                isSelected={
                    _digital
                        ? props.selected.toString() === _digital.id.toString()
                        : false
                }
                onClick={
                    _digital
                        ? () => props.onClick(_digital.id.toString())
                        : () => null
                }
                deactive={!_digital}
            >
                epub
            </Variant>
        </VariantsContainer>
    );
};
const MerchVariants = (props: any) => {
    return (
        <VariantsContainer>
            {props.variants.map((_variant: any, _index: number) => (
                <Variant
                    key={`${_variant.size}_${_index}`}
                    isSelected={
                        props.selected.toString() === _variant.id.toString()
                    }
                    deactive={!_variant.isInStore}
                    onClick={() =>
                        _variant.isInStore &&
                        props.onClick(_variant.id.toString())
                    }
                >
                    {_variant.size}{' '}
                    {`${_index + 1 === props.variants.length ? '' : '/  '}`}
                </Variant>
            ))}
        </VariantsContainer>
    );
};

export default class Price extends React.PureComponent<any> {
    static contextType = AppContext;
    state: any = {
        isSuccessfullyAdded: false,
        selectedVariant: null,
    };
    handleBagClick = () =>
        this.setState(
            () => ({ isSuccessfullyAdded: true }),
            () => {
                setTimeout(() => {
                    this.setState({ isSuccessfullyAdded: false });
                }, 3000);
                this.props.onBagClick();
            },
        );
    handleVariantClick = (id: string) => this.props.onVariantClick(id);
    render() {
        const {
            className,
            mobile,
            variants,
            height,
            fontType,
            value,
        } = this.props;

        const more1size =
            this.props.type === 'merch' && this.props.variants.length > 1;
        const withFreeDownload = variants.find(
            (_variant: any) =>
                _variant.hiddenLink &&
                _variant.isDigital &&
                _variant.price === 0,
        );

        return (
            <Container
                width={this.props.width || 'magic.5'}
                className={className}
                oneSize={!(this.props.type === 'book' || more1size)}
                border={
                    this.props.mobile ? '1px solid black' : '2px solid black'
                }
                // {...{ className }}
            >
                {(this.props.type === 'book' || more1size) && (
                    <VariantsWrapper>
                        {this.props.type === 'book' ? (
                            <BookVariants
                                onClick={this.handleVariantClick}
                                selected={this.props.selected}
                                variants={this.props.variants}
                            />
                        ) : (
                            more1size && (
                                <MerchVariants
                                    onClick={this.handleVariantClick}
                                    selected={this.props.selected}
                                    variants={this.props.variants}
                                />
                            )
                        )}
                    </VariantsWrapper>
                )}
                {withFreeDownload ? (
                    RenderLink(
                        mobile,
                        withFreeDownload.hiddenLink,
                        fontType,
                        height,
                    )
                ) : (
                    <AddContainer height="38px">
                        <Cover
                            className={
                                this.state.isSuccessfullyAdded
                                    ? 'clicked cart'
                                    : 'cart'
                            }
                            onClick={
                                this.state.isSuccessfullyAdded
                                    ? () => null
                                    : this.handleBagClick
                            }
                        >
                            <Text
                                mobile={mobile}
                                type={fontType || 'h4'}
                                py={mobile && height !== '28px' ? '11px' : '2'}
                                className={'price-text'}
                                args={value / 100}
                                t
                            >
                                ui.price
                            </Text>
                            <Text
                                mobile={mobile}
                                type={fontType || 'h4'}
                                py={mobile && height !== '28px' ? '11px' : '2'}
                                className={'text'}
                                t
                            >
                                {this.state.isSuccessfullyAdded
                                    ? 'ui.added'
                                    : this.props.isPreorder
                                    ? 'ui.preorder'
                                    : 'ui.in_bag'}
                            </Text>
                            <Box className="cartBlack bg cart"></Box>
                            <Box className="cartWhite bg cart"></Box>
                        </Cover>
                    </AddContainer>
                )}
            </Container>
        );
    }
}
