import React, { PureComponent } from 'react';
import styled from 'styled-components';

import {
    Box as BaseBox,
    CoverImage as BaseCoverImage,
} from 'Src/components/basic';

// todo: done true gray placeholder
const CoverImage = styled(BaseCoverImage)<any>`
    background: #ededed;
    border: none !important;
    outline: none;
    object-fit: ${(props: any) => props.objectFit};
    ${(props: any) => props.width && `width: ${props.width};`}
`;

const Box = styled(BaseBox)<{ centered?: boolean }>`
    ${props =>
        props.centered &&
        `
        display: flex;
        justify-content: center;
        `}
`;

export class Cover extends PureComponent<any> {
    state: any = {
        isImageLoad: false,
        width: this.props.height * 0.7,
    };
    componentDidMount() {
        this.getImage();
    }

    async getImage() {
        const image: any = new Image();
        const setState = this.setState.bind(this);
        const height = this.props.height;

        image.onload = function() {
            setState({
                width: `${Math.round(height / (this.height / this.width))}px`,
                isImageLoad: true,
            });
        };
        image.src = this.props.path;
    }
    render() {
        const width = String(this.props.width).includes('%')
            ? this.props.width
            : Number(String(this.state.width).replace(/[a-z]/g, '')) >
              this.props.height
            ? this.props.height * 0.8
            : this.state.width;
        const margin = this.props.line
            ? `0 ${213 - Math.round(parseInt(width))}px` + ` 0 0`
            : this.props.margin;

        return (
            <Box
                centered={this.props.centered}
                maxWidth={this.props.width || this.props.height}
                width={
                    this.props.mobile
                        ? this.props.width ||
                          (Number(
                              String(this.state.width).replace(/[a-z]/g, ''),
                          ) > this.props.height
                              ? this.props.height * 0.8
                              : this.state.width)
                        : this.props.width || this.props.height
                }
                height={this.props.height}
                maxHeight={this.props.height}
            >
                <CoverImage
                    src={!this.state.isImageLoad ? '' : this.props.path}
                    width={width}
                    height={`${this.props.height}px`}
                    withShadow={this.props.withShadow}
                    margin={margin}
                    onClick={this.props.onClick}
                    isLoading={!this.state.isImageLoad}
                    maxWidth={this.props.maxWidth}
                    objectFit={this.props.objectFit}
                    centered
                    withCursor
                />
            </Box>
        );
    }
}
