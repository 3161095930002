import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import Grid from 'Src/components/blocks/Grid';
import Icon from 'Src/components/Icon';
import PageDelimeter from 'Src/components/PageDelimeter';
import PageHeader from 'Src/components/PageHeader';
import Book from 'Src/components/stickers/Book';

const Container = styled.div`
    padding: 3rem 3.75rem;
`;

const Content = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
    grid-template-areas: 'items items items items';
    grid-row-gap: 65px;
`;

const IconBox = styled(Box)`
    position: absolute;
    right: 60px;
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: -51px;
`;

const ViewIcon = styled(Icon)<{ activeName: string; width: string }>`
    opacity: ${props => (props.name === props.activeName ? 1 : 0.6)};
    height: 26px;
    width: ${props => props.width};
`;

const Grid4View = styled.div`
    grid-area: items;
    display: grid;
    grid-row-gap: 4rem;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
    grid-template-rows: repeat(4, auto);
`;

const Grid3View = styled.div`
    grid-area: items;
    display: grid;
    grid-row-gap: 4rem;
    justify-content: space-between;
    grid-template-columns: repeat(3, 25rem);
    grid-template-rows: repeat(3, auto);
`;

const EmptyText = styled(Text)`
    padding: 50px 60px 0;
    grid-column-start: 1;
    grid-column-end: 23;
`;

export default withRouter(
    class extends React.PureComponent<any> {
        componentDidMount() {
            window.scrollTo(0, 0);
        }
        componentDidUpdate = (prevProps: any) => {
            if (prevProps.match.url !== this.props.match.url) {
                window.scrollTo(0, 0);
            }
        };
        render() {
            const { popular, view, setView } = this.props;

            const View = view === 'grid8' ? Grid4View : Grid3View;
            const bookPresentation = view === 'grid8' ? 'grid' : 'grid3';

            return (
                <React.Fragment>
                    <PageHeader
                        onClick={() => this.props.history.go('/books')}
                        title={'headers.strelka_press_merch'}
                    >
                        <IconBox>
                            <ViewIcon
                                width="54px"
                                marginRight="1rem"
                                activeName={view}
                                name="grid8"
                                onClick={() => setView('grid8')}
                            />
                            <ViewIcon
                                width="40px"
                                activeName={view}
                                name="grid"
                                onClick={() => setView('grid')}
                            />
                        </IconBox>
                    </PageHeader>
                    <Container>
                        <Content>
                            <View>
                                {this.props.items.length === 0 ? (
                                    <EmptyText type="body1" t>
                                        ui.nothing_found
                                    </EmptyText>
                                ) : (
                                    this.props.items.map(
                                        (_book: any, _index: number) => (
                                            <Book
                                                key={_index}
                                                {..._book}
                                                presentation={bookPresentation}
                                                withButtonPrice={false}
                                            />
                                        ),
                                    )
                                )}
                            </View>
                        </Content>
                    </Container>
                    <PageDelimeter
                        title="headers.popular"
                        margin="0 0 20px"
                        color="#fff"
                        background="#EA3DF7"
                    />
                    <Grid rows={1} cols={4} direction="horizontal">
                        {popular.map((book: any) => (
                            <Book key={book.id} {...book} />
                        ))}
                    </Grid>
                </React.Fragment>
            );
        }
    },
);
