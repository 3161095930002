import 'react-dadata/dist/react-dadata.css';

import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { AddressSuggestions } from 'react-dadata';

import { Box, Input, Textarea } from 'Src/components/basic';
import { useTranslation } from 'Src/Context/Translations';

function CountrySuggestion({ onChange, ...props }: any) {
    const { state } = useTranslation();

    const currentLanguage = state.language || 'ru';

    return (
        <AddressSuggestions
            token={'8cab8df7c3ffec55219ee5169285dd07a8068c63'} // todo
            inputProps={props}
            filterLanguage={currentLanguage}
            filterFromBound={'country'}
            filterToBound={'country'}
            filterLocations={[{ country_iso_code: '*' }]}
            customInput={Input}
            delay={500}
            onChange={suggestion => {
                onChange({
                    target: {
                        name: 'country',
                        value: suggestion ? suggestion.value : '',
                    },
                });
            }}
        />
    );
}

function CitySuggestion({ onChange, ...props }: any) {
    const { state } = useTranslation();
    const {
        values: { country },
    } = useFormikContext();
    const [inputValue, setInputValue] = useState('');

    const currentLanguage = state.language || 'ru';

    const inputProps = {
        ...props,
        onChange: (e: any) => {
            setInputValue(e.target.value);
            onChange(e);
        },
    };

    return (
        <AddressSuggestions
            token={'8cab8df7c3ffec55219ee5169285dd07a8068c63'} // todo
            inputProps={inputProps}
            filterLanguage={currentLanguage}
            filterFromBound={'city'}
            filterToBound={'city'}
            filterLocations={[{ country }]}
            customInput={Input}
            delay={500}
            onChange={suggestion => {
                onChange({
                    target: {
                        name: 'city',
                        value: suggestion ? suggestion.data.city : inputValue,
                    },
                });
            }}
        />
    );
}

const OrderFormCourier: React.FunctionComponent = function OrderFormCourier(
    props: any,
) {
    return (
        <Formik
            initialValues={{
                name: '',
                surname: '',
                email: '',
                country: '',
                phone: '',
                city: '',
                address: '',
                comment: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
            }}
            validate={(values: any) => {
                const errors: any = {};

                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email,
                    )
                ) {
                    errors.email = 'Invalid email address';
                }

                if (!values.name) {
                    errors.name = 'Required';
                }

                if (!values.surname) {
                    errors.surname = 'Required';
                }

                if (!values.city) {
                    errors.city = 'Required';
                }

                if (!values.country) {
                    errors.country = 'Required';
                }

                if (!values.address) {
                    errors.address = 'Required';
                }

                if (!values.comment) {
                    errors.comment = 'Required';
                }

                if (!values.phone) {
                    errors.phone = 'Required';
                } else if (
                    !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                        values.phone,
                    )
                ) {
                    errors.phone = 'Invalid phone number';
                }

                return errors;
            }}
        >
            {({ handleChange, errors, touched }) => (
                <Form>
                    <Box display="flex" mb="3">
                        <Box width="100%">
                            <Field
                                mr="3"
                                type="text"
                                name="name"
                                as={Input}
                                placeholder="ui.name"
                                value={props.name}
                                error={
                                    errors.name && touched.name && errors.name
                                }
                                onChange={(e: any) => {
                                    handleChange(e);
                                    props.onChange(
                                        e.target.name,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Box>
                        <Box width="100%">
                            <Field
                                type="text"
                                name="surname"
                                as={Input}
                                placeholder="ui.lastName"
                                value={props.surname}
                                error={
                                    errors.surname &&
                                    touched.surname &&
                                    errors.surname
                                }
                                onChange={(e: any) => {
                                    handleChange(e);
                                    props.onChange(
                                        e.target.name,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Box>
                    </Box>
                    <Field
                        mb="3"
                        type="email"
                        name="email"
                        as={Input}
                        placeholder="ui.email"
                        error={errors.email && touched.email && errors.email}
                        value={props.email}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                    />
                    <Field
                        mb="3"
                        type="tel"
                        name="phone"
                        as={Input}
                        error={errors.phone && touched.phone && errors.phone}
                        placeholder="ui.phone"
                        value={props.phone}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                    />
                    <Field
                        mb="3"
                        type="text"
                        name="country"
                        as={CountrySuggestion}
                        error={
                            errors.country && touched.country && errors.country
                        }
                        placeholder="ui.country"
                        value={props.country}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                    />
                    <Field
                        mb="3"
                        type="text"
                        name="city"
                        as={CitySuggestion}
                        error={errors.city && touched.city && errors.city}
                        placeholder="ui.city"
                        value={props.city}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                    />
                    <Field
                        mb="3"
                        type="text"
                        name="address"
                        error={
                            errors.address && touched.address && errors.address
                        }
                        as={Input}
                        placeholder="ui.address"
                        value={props.address}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                    />
                    <Field
                        mb="3"
                        type="textarea"
                        name="comment"
                        as={Textarea}
                        error={
                            errors.comment && touched.comment && errors.comment
                        }
                        placeholder="ui.comment"
                        value={props.comment}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default OrderFormCourier;
