import * as React from 'react';

// import styled from 'styled-components';
import { Box } from 'Src/components/basic';

export default () => (
    <Box width="230px">
        <Box height="358px" marginBottom="14px" backgroundColor="#EDEDED" />
        <Box height="96px" backgroundColor="#D8D8D8" />
    </Box>
);
