import React from 'react';
import { withRouter } from 'react-router-dom';

import { Box, ListHeader, Text } from 'Src/components/basic';
import Icon from 'Src/components/Icon';

const Header = (props: any) => (
    <Box>
        <ListHeader background={props.background} margin={props.margin}>
            <Icon
                mt="-3px"
                name="arrowNavi"
                onClick={
                    props.to
                        ? () => props.history.push(props.to)
                        : props.history.goBack
                }
            />
            <Text
                args={props.args}
                type="mh2"
                t={props.t}
                marginTop="-2px"
                textTransform="uppercase"
                onClick={props.onClick || (() => null)}
            >
                {props.title}
            </Text>
        </ListHeader>
    </Box>
);

export default withRouter(Header);
