import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, PayButton, Text } from 'Src/components/basic';
import OrderEbooks from 'Src/components/OrderEbooks';
// import GoToAuth from 'Src/components/GoToAuth';
import OrderFormCourier from 'Src/components/OrderFormCourier';
import OrderFormSelf from 'Src/components/OrderFormSelf';
import PageHeader from 'Src/components/PageHeader';
import RadioItem from 'Src/components/RadioItem';
import { Cover } from 'Src/components/ResizedImage';
import { AppContext } from 'Src/model';

const Container = styled(Box)`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 125px;
`;
const ScrollBox = styled(Box)`
    max-height: 535px;
    overflow-y: auto;
`;

const StyledText = styled(Text)`
    user-select: none;
`;

const InlineText = styled(StyledText)`
    display: inline;
`;

const TranslatedInlineText = (props: any) => (
    <InlineText t type="body2" lineHeight="28px !important" {...props}>
        {props.children}
    </InlineText>
);

class Order extends React.PureComponent<any> {
    static contextType = AppContext;
    componentDidMount() {
        window.scrollTo(0, 0);
        this.context.popups.setHolydays(true);
    }
    componentDidUpdate = (prevProps: any) => {
        if (prevProps.match.url !== this.props.match.url) {
            window.scrollTo(0, 0);
        }
    };
    render() {
        return (
            <>
                <PageHeader title={`headers.order`} />
                <Container
                    pt="magic.1"
                    px="magic.1"
                    pb="magic.2"
                    display="flex"
                >
                    <Box>
                        {this.context.bag.books.every(
                            (book: any) => book.isDigital,
                        ) ? (
                            <>
                                <Text
                                    t
                                    type="h3"
                                    textTransform="uppercase"
                                    mb="magic.1"
                                >
                                    order.ebooks
                                </Text>
                                <OrderEbooks {...this.props} />
                                <RadioItem
                                    value={this.props.isChecked}
                                    onChange={(checked: boolean) =>
                                        this.props.onChange(
                                            'isChecked',
                                            checked,
                                        )
                                    }
                                >
                                    <Box>
                                        <TranslatedInlineText whiteSpace="nowrap">
                                            order.agreement1
                                        </TranslatedInlineText>
                                        &nbsp;
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/rules.pdf"
                                        >
                                            <TranslatedInlineText
                                                textDecoration="underline"
                                                whiteSpace="nowrap"
                                            >
                                                order.agreement2
                                            </TranslatedInlineText>
                                        </a>
                                        &nbsp;
                                        <TranslatedInlineText whiteSpace="nowrap">
                                            ui.and
                                        </TranslatedInlineText>
                                        &nbsp;
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/privacy-policy.pdf"
                                        >
                                            <TranslatedInlineText textDecoration="underline">
                                                order.agreement3
                                            </TranslatedInlineText>
                                        </a>
                                    </Box>
                                    {/* <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/rules.pdf"
                                    >
                                        <StyledText
                                            t
                                            type="body2"
                                            textDecoration="underline"
                                        >
                                            order.conditions
                                        </StyledText>
                                    </a> */}
                                </RadioItem>
                            </>
                        ) : (
                            <>
                                <Text
                                    t
                                    type="h3"
                                    textTransform="uppercase"
                                    mb="magic.1"
                                >
                                    order.pay_by
                                </Text>
                                <RadioItem
                                    value={!this.props.isDelivering}
                                    size="29px"
                                    onChange={() =>
                                        this.props.onChange(
                                            'isDelivering',
                                            false,
                                        )
                                    }
                                >
                                    <StyledText
                                        t
                                        type="h4"
                                        textTransform="uppercase"
                                        mb="3"
                                    >
                                        order.pick_up
                                    </StyledText>
                                    <Text t type="body2">
                                        order.address
                                    </Text>
                                    <Text t type="body2">
                                        order.time
                                    </Text>
                                </RadioItem>
                                {/* {!this.props.isDelivering && (
                                    <GoToAuth>
                                        <Text
                                            mb="7px"
                                            mt="22px"
                                            color="#1A67F6"
                                            t
                                            type="body2"
                                        >
                                            order.sign_in
                                        </Text>
                                    </GoToAuth>
                                )} */}
                                {!this.props.isDelivering && (
                                    <OrderFormSelf {...this.props} />
                                )}
                                {/* <RadioItem
                                    size="29px"
                                    value={this.props.isDelivering}
                                    onChange={() =>
                                        this.props.onChange(
                                            'isDelivering',
                                            true,
                                        )
                                    }
                                >
                                    <StyledText
                                        t
                                        type="h4"
                                        textTransform="uppercase"
                                    >
                                        order.courier
                                    </StyledText>
                                </RadioItem> */}
                                {/* {this.props.isDelivering && (
                                    <GoToAuth>
                                        <StyledText
                                            mb="7px"
                                            mt="-10px"
                                            color="#1A67F6"
                                            t
                                            type="body2"
                                        >
                                            order.sign_in
                                        </StyledText>
                                    </GoToAuth>
                                )} */}
                                {this.props.isDelivering && (
                                    <OrderFormCourier {...this.props} />
                                )}
                                <RadioItem
                                    value={this.props.isChecked}
                                    onChange={(checked: boolean) =>
                                        this.props.onChange(
                                            'isChecked',
                                            checked,
                                        )
                                    }
                                >
                                    <Box>
                                        <TranslatedInlineText whiteSpace="nowrap">
                                            order.agreement1
                                        </TranslatedInlineText>
                                        &nbsp;
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/rules.pdf"
                                        >
                                            <TranslatedInlineText
                                                textDecoration="underline"
                                                whiteSpace="nowrap"
                                            >
                                                order.agreement2
                                            </TranslatedInlineText>
                                        </a>
                                        &nbsp;
                                        <TranslatedInlineText whiteSpace="nowrap">
                                            ui.and
                                        </TranslatedInlineText>
                                        &nbsp;
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/privacy-policy.pdf"
                                        >
                                            <TranslatedInlineText textDecoration="underline">
                                                order.agreement3
                                            </TranslatedInlineText>
                                        </a>
                                    </Box>
                                    {/* <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/rules.pdf"
                                    >
                                        <StyledText
                                            t
                                            type="body2"
                                            textDecoration="underline"
                                        >
                                            order.conditions
                                        </StyledText>
                                    </a> */}
                                </RadioItem>
                            </>
                        )}
                    </Box>
                    <Box>
                        <Text
                            t
                            type="h3"
                            textTransform="uppercase"
                            mb="magic.1"
                            args={((total: number) =>
                                this.context.bag.books.map((_book: any) => {
                                    total += _book.amount;
                                }) && total)(0)}
                        >
                            order.your_cart
                        </Text>
                        <ScrollBox>
                            {this.context.bag.books.map((book: any) => (
                                <Box
                                    key={book.id}
                                    mb="50px"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Cover
                                        margin="0"
                                        height={145}
                                        path={book.images[0].path}
                                    />
                                    <Box
                                        flexGrow={1}
                                        ml="20px"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        height="145px"
                                    >
                                        {this.context.bag.preorderIds.includes(
                                            book.id,
                                        ) && (
                                            <Text
                                                t
                                                type="h4"
                                                textTransform="uppercase"
                                                mr="90px"
                                                color="#1A67F6"
                                            >
                                                ui.preorder
                                            </Text>
                                        )}
                                        <Text
                                            t={book.type === 'books'}
                                            type="h4"
                                            textTransform="uppercase"
                                            args={book.title}
                                            mr="90px"
                                        >
                                            {book.type === 'books'
                                                ? 'ui.book_title'
                                                : `${
                                                      book.title
                                                  }, ${Object.values(
                                                      book.variants,
                                                  )}`}
                                        </Text>
                                        {book.type === 'books' && (
                                            <Text
                                                type="body2"
                                                t
                                                args={book.title}
                                                mt="20px"
                                            >
                                                {book.isDigital
                                                    ? 'ui.book_e_version'
                                                    : 'ui.book_paper_version'}
                                            </Text>
                                        )}
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            mb="50px"
                                        >
                                            <Text
                                                t
                                                type="body2"
                                                args={book.amount}
                                            >
                                                order.amount
                                            </Text>
                                            <Text
                                                t
                                                type="h4"
                                                textTransform="uppercase"
                                                whiteSpace="nowrap"
                                                args={
                                                    (book.amount * book.price) /
                                                    100
                                                }
                                            >
                                                ui.price
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </ScrollBox>
                        <Box borderTop="1" py="magic.0">
                            <Box display="flex" justifyContent="space-between">
                                <Text t type="h4" textTransform="uppercase">
                                    order.total
                                </Text>
                                <Text
                                    t
                                    type="h4"
                                    textTransform="uppercase"
                                    whiteSpace="nowrap"
                                    args={((total: number) =>
                                        this.context.bag.books.map(
                                            (_book: any) => {
                                                total +=
                                                    _book.price * _book.amount;
                                            },
                                        ) && total / 100)(0)}
                                >
                                    ui.price
                                </Text>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                mt="magic.0"
                            >
                                <Text t type="h4" textTransform="uppercase">
                                    order.delivery
                                </Text>
                                <Text
                                    t
                                    type="h4"
                                    textTransform="uppercase"
                                    whiteSpace="nowrap"
                                    args={
                                        this.props.isDelivering
                                            ? this.props.deliveryPrice
                                            : 0
                                    }
                                >
                                    {this.props.deliveryPrice === null
                                        ? 'ui.delivery_load'
                                        : 'ui.price'}
                                </Text>
                            </Box>
                        </Box>
                        <Box
                            pt="30px"
                            pb="50px"
                            borderTop="1"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Text t type="h4" textTransform="uppercase">
                                ui.total
                            </Text>
                            <Text
                                t
                                type="h4"
                                textTransform="uppercase"
                                whiteSpace="nowrap"
                                args={
                                    ((total: number) =>
                                        this.context.bag.books.map(
                                            (_book: any) => {
                                                total +=
                                                    _book.price * _book.amount;
                                            },
                                        ) && total / 100)(0) +
                                    (this.props.isDelivering
                                        ? this.props.deliveryPrice
                                        : 0)
                                }
                            >
                                ui.price
                            </Text>
                        </Box>
                    </Box>
                    <PayButton
                        backgroundColor="blue"
                        color="white"
                        style={{ border: 'none', gridColumn: 'span 2' }}
                        padding="20px 0 16px"
                        onClick={this.props.onSubmit}
                        disabled={!this.props.isValid}
                    >
                        <Text t type="h3" textTransform="uppercase">
                            order.pay
                        </Text>
                    </PayButton>
                </Container>
            </>
        );
    }
}

export default withRouter(Order);
