import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';
import Icon from 'Src/components/Icon';

const Input = styled.input`
    margin-left: 20px;
    border: 2px solid #000;
    font-size: 15px;
    font-family: UniversRoman;
    line-height: 19px;
    padding: 6px 10px;
    outline: 0;
    width: 100%;
`;

export default class extends React.PureComponent<any> {
    input: any;
    componentDidMount() {
        this.input.focus();
    }
    handleBlur = () => {
        setTimeout(() => {
            this.props.onBlur();
        }, 100);
    };
    handleBack = () => {
        this.props.onBack();
    };
    render() {
        return (
            <Box
                margin={this.props.margin}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box width="100%" marginRight="20px;">
                    <Input
                        ref={c => (this.input = c)}
                        value={this.props.value}
                        onChange={({ target: { value } }) =>
                            this.props.onChange(value)
                        }
                        onKeyDown={this.props.onKeyDown}
                        onBlur={this.handleBlur}
                    ></Input>
                </Box>
                <Icon
                    onClick={
                        this.props.isFindPage
                            ? this.handleBack
                            : () => this.props.onEnterClick({ keyCode: 13 })
                    }
                    name={this.props.isFindPage ? 'cross' : 'arrowFind'}
                    size={this.props.isFindPage ? '21px' : '25px'}
                    marginLeft="-40px"
                />
            </Box>
        );
    }
}
