import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';
import Card from 'Src/pages/PageLoaders/Card';

const GridView = styled.div`
    grid-area: items;
    display: grid;
    grid-row-gap: 4rem;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
`;

const getCards = function(finish: any) {
    const cards = [];

    for (let i = 0; i < finish; i++) {
        cards[i] = <Card />;
    }

    return cards;
};

export default function() {
    return (
        <Box>
            <Box display="flex" margin="50px 61px 54px">
                <Box
                    width="486px"
                    height="470px"
                    backgroundColor="#EDEDED"
                    marginRight="107px"
                />
                <Box width="100%">
                    <Box
                        height="79px"
                        marginBottom="20.5px"
                        backgroundColor="#D8D8D8"
                    />
                    <Box
                        height="55px"
                        marginBottom="29.5px"
                        backgroundColor="#D8D8D8"
                    />
                    <Box
                        height="175px"
                        marginBottom="25px"
                        backgroundColor="#D8D8D8"
                    />
                    <Box height="155px" backgroundColor="#D8D8D8" />
                </Box>
            </Box>
            <Box backgroundColor="#D8D8D8" width="100%" height="43px" />
            <Box margin="59px 61.5px">
                <GridView>{getCards(4)}</GridView>
            </Box>
        </Box>
    );
}
