export const getUriFromFilters = (filters: any, view?: string) => {
    let _uri = '?';

    [
        { name: 'topics', items: filters.topics },
        { name: 'authors', items: filters.authors },
        { name: 'languages', items: filters.languages },
        { name: 'view', value: view, items: [] },
    ].map((_filter: { name: string; items: any[]; value?: string }) => {
        if (_filter.name !== 'q') {
            if (_filter.value) {
                _uri += `view=${_filter.value}`;
            } else {
                if (_filter.items === undefined || _filter.items.length === 0) {
                    return;
                }
                _uri += `${(_uri === '?' ? '' : '&') + _filter.name}=`;
                _filter.items.map((_item: any, _index: number) => {
                    _uri += `${_item}${
                        _index === _filter.items.length - 1 ? '' : '%2C'
                    }`;
                });
                _uri += '&';
            }
        }
    });

    return _uri;
};

export const getFiltersFromUri = (uri: string) => {
    const filters: any = {
        q: '',
        authors: [],
        topics: [],
        languages: [],
    };

    uri.split(/[&?]/g).map((_filter: string, _index: number) => {
        if (_index > 0) {
            const _splitted: string[] = _filter.split('=');

            filters[_splitted[0]] = _splitted[1]
                .split('%2C')
                .map((_item: string) => Number(_item));
        }
    });

    return filters;
};

export const uri2obj = (search: string) =>
    ((_obj: any) =>
        search
            .replace('?', '')
            .split('&')
            .map((data: string) => data.split('='))
            .map((pair: string[]) => {
                _obj[pair[0]] = decodeURIComponent(pair[1]);
            }) && _obj)({});

export const obj2uri = (obj: any) =>
    ((uri: string) =>
        Object.keys(obj).map((key: string) => {
            uri += `${key}=${encodeURIComponent(obj[key])}&`;
        }) && uri)('?');

export const filterObject = (obj: any, include: string[]) =>
    ((_new: any) =>
        Object.keys(obj)
            .filter((key: string) => include.includes(key))
            .map((key: string) => {
                _new[key] = obj[key];
            }) && _new)({});
