import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { Box, Href, Text } from 'Src/components/basic';
import BookGallery from 'Src/components/BookGallery';
import Collapse from 'Src/components/Collapse';
// import Icon from 'Src/components/Icon';
import { Book, Carousel, Header } from 'Src/components/mobile';
import PageDelimeter from 'Src/components/PageDelimeter';
// import styled from 'styled-components';
import { AppContext } from 'Src/model';

const Review = (props: any) => (
    <Box>
        <Href href={props.sourceLink}>
            <Text type="mbody1">
                {props.source} {'—>'}
            </Text>
        </Href>
        <Text margin="20px 0 10px" type="mbody1">
            {props.text}
        </Text>
    </Box>
);

// const Comment = (props: any) => (
//     <Box margin="30px 0 30px">
//         <Text
//             fontSize="15px"
//             margin="0 0 15px"
//             fontFamily="2"
//             color={props.userName ? '#000' : '#999'}
//         >
//             {props.userName || 'Анонимный пользователь'}
//         </Text>
//         <Text fontSize="11px" lineHeight="14px" fontFamily="4">
//             {props.comment}
//         </Text>
//     </Box>
// );

class MerchPage extends PureComponent<any> {
    static contextType = AppContext;
    state: any;
    constructor(props: any) {
        super(props);
        this.state = {
            id: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const primary = this.props.book.items.find(
            (item: any) => String(item.id) === this.props.match.params.id,
        );

        this.setState({ id: primary.id });
        window.scrollTo(0, 0);
    }
    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (prevProps.match.url !== this.props.match.url) {
            window.scrollTo(0, 0);
        }

        if (prevState.id !== this.state.id) {
            this.props.history.push(
                `/${this.context.locale.value}/items/${this.state.id}`,
            );
        }
    };

    handleVariantClick = (id: number) => {
        this.setState({ id });
    };
    handleAddBook = (id: string) => {
        const _current = this.props.book.items.find(
            (item: any) => item.id.toString() === id.toString(),
        );
        const _withPrimary = {
            ...this.props.book.items.find(
                (item: any) => item.id === item.primary,
            ),
            variants: _current.variants,
            id: _current.id,
        };

        this.context.bag.addBook(_withPrimary);
    };

    render() {
        const { book, popular } = this.props;

        const primary = book.items.find(
            (item: any) => item.id === this.state.id || item.primary,
        );

        // const _variants = book.items.map((_variant: any) => ({
        //     id: _variant.id,
        //     price: _variant.price,
        //     size: _variant.variants.size,
        //     isInStore: _variant.isInStore,
        // }));

        return (
            <Box>
                <Header
                    title="ui.merch"
                    t
                    background="#F3FA75"
                    onClick={() =>
                        this.props.history.push(
                            `/${this.context.locale.value}/merch`,
                        )
                    }
                />
                <Box margin="0px 20px 50px">
                    <Text
                        textTransform="uppercase"
                        margin="20px 0px 15px 0px"
                        type="mh1"
                        letterSpacing="0.5px" // strange, but works
                    >
                        {primary.title}
                    </Text>
                    <BookGallery
                        size={[primary.width, primary.height]}
                        images={primary.images}
                        toLeft
                        withoutAnimation
                        mobile
                    />
                    {/* <Box margin="10px 0 45px">
                        <Price
                            width="100%"
                            value={primary.price}
                            onBagClick={() => this.handleAddBook(this.state.id)}
                            onVariantClick={this.handleVariantClick}
                            fontType="mh2"
                            type="merch"
                            variants={_variants}
                            selected={this.state.id}
                            mobile
                        />
                    </Box> */}
                    <Text type="mbody1" mb="30px">
                        {primary.summary}
                    </Text>
                    {primary.description && (
                        <Collapse mobile small title="book.description" t>
                            <Text
                                margin="20px 0 10px"
                                type="mbody1"
                                dangerouslySetInnerHTML={{
                                    __html: primary.description,
                                }}
                            ></Text>
                        </Collapse>
                    )}
                    {primary.reviews.lemgth > 0 && (
                        <Collapse mobile small title="book.reviews" t>
                            <Box margin="20px 0 10px">
                                {primary.reviews.length > 0 && (
                                    <Review {...primary.reviews[0]} />
                                )}
                                {primary.reviews.length > 1 && (
                                    <Collapse
                                        small
                                        title="book.show_all"
                                        border="little"
                                        mobile
                                        t
                                    >
                                        {primary.reviews.map(
                                            (_review: any, _index: number) =>
                                                _index > 0 && (
                                                    <Review
                                                        key={_index}
                                                        {..._review}
                                                    />
                                                ),
                                        )}
                                    </Collapse>
                                )}
                            </Box>
                        </Collapse>
                    )}
                    {/* <Box
                        margin="20px 0"
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Icon name="heartWhite" margin="0 10px" />
                        <Icon name="share" />
                    </Box> */}
                    <Collapse mobile small title="book.delivery" t>
                        <Box mt="20px">
                            <Text type="mbody1" mb="13px" t>
                                texts.delivery1
                            </Text>
                            <Text type="mbody1" mb="13px" t>
                                texts.delivery2
                            </Text>
                            <Text type="mbody1" mb="13px" t>
                                texts.delivery3
                            </Text>
                            <Text type="mbody1" mb="13px" t>
                                texts.delivery4
                            </Text>
                        </Box>
                    </Collapse>
                    {/* <Text
                        margin="30px 0 20px"
                        textTransform="uppercase"
                        type="mh3"
                    >
                        Комментарии ({primary.comments.length})
                    </Text>
                    {primary.comments.map((_comment: any, _index: number) => (
                        <Comment {..._comment} key={_index} />
                    ))}
                    <CommentInput
                        placeholder="Добавьте комментарий..."
                        fontSize="11px"
                        fontFamily="2"
                        rows={2}
                    /> */}
                </Box>
                <PageDelimeter
                    title="headers.also_like"
                    background="#5ECA91"
                    color="#fff"
                    mobile
                />
                <Box margin="35px 0px 0px">
                    <Carousel
                        items={((total: any[]) =>
                            total.map((_: any, index: number) => [
                                popular[index * 2],
                                popular[index * 2 + 1],
                            ]))([0, 0])}
                        component={(_props: any) => (
                            <Box
                                display="flex !important"
                                flexDirection="row"
                                justifyContent="space-between"
                                margin="0 20px"
                            >
                                <Book {..._props[0]} />
                                <Book {..._props[1]} />
                            </Box>
                        )}
                    />
                </Box>
            </Box>
        );
    }
}

export default withRouter(MerchPage);
