import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import { AppContext } from 'Src/model';

import Desktop from './Desktop';
import Mobile from './Mobile';

export default function() {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const app = useContext(AppContext);

    return isMobile ? <Mobile /> : <Desktop app={app} />;
}
