import React, { createContext } from 'react';

import * as api from 'Src/api';
import { PaytureWidget } from 'Src/external/paytureLib/payture.widget.min.js';
// import { serializeShops } from 'Src/utils/serializers';

export const AppContext = createContext({});

const getLocaleOrDefault = (path: string) => {
    if (path.split('/')[1] === 'ru' || path.split('/')[1] === 'en') {
        return path.split('/')[1];
    } else {
        return 'ru';
    }
};

export const withContext = (Data: any) => {
    return () => {
        const context = React.useContext(AppContext);

        return <Data context={context} />;
    };
};

class AppProvider extends React.PureComponent<any> {
    state = {
        bag: {
            hidePopup: () =>
                this.setState((state: any) => ({
                    bag: { ...state.bag, lastAdded: {} },
                })),
            getCart: () => this.getBag(),
            isRequested: false,
            preorderIds: [],
            isShowPopup: false,
            books: [],
            lastAdded: {},
            id: '',
            addBook: (book: any, amount?: number) =>
                this.addBookToCart(book, amount),
            removeBook: (book: any) =>
                this.removeBook(book.id, this.state.bag.id),
            decrementBook: (book: any) =>
                this.decrementBook(book.id, this.state.bag.id),
        },
        popups: {
            isRequesting: false,
            pandemia: false,
            holydays: true,
            newsletter: true,
            shops: null,
            setPandemia: (is: boolean) =>
                this.setState((state: any) => ({
                    popups: { ...state.popups, pandemia: is },
                })),
            updatePandemiaPopup: (id: any) => this.updatePandemiaPopup(id),
            setHolydays: (is: boolean) =>
                this.setState((state: any) => ({
                    ...state,
                    popups: { ...state.popups, holydays: is },
                })),
            hide: () =>
                this.setState((state: any) => ({
                    popups: { ...state.popups, pandemia: false },
                })),
        },
        merch: { get: () => this.getMerch() },
        find: {
            query: '',
            updateQuery: (_query: string) =>
                this.setState((state: any) => ({
                    find: {
                        ...state.find,
                        query: _query,
                    },
                })),
        },
        popular: {
            get: (cat?: string) => this.getPopular(cat),
        },
        filters: {
            get: () => this.getFilters(),
        },
        order: {
            id: '',
            isDelivering: false,
            createNewOrder: (order: any, push?: any) =>
                this.createNewOrder(
                    this.state.bag.id,
                    order.isDelivering,
                    order,
                    push,
                ),
        },
        locale: {
            value: getLocaleOrDefault(document.location.pathname),
            set: (locale: string) =>
                this.setState(
                    (state: any) => ({
                        locale: { ...state.locale, value: locale },
                    }),
                    () => {
                        location.reload();
                    },
                ),
            withLocale: (method: any, args?: any[]) =>
                args
                    ? method(...args, this.state.locale.value)
                    : method(this.state.locale.value),
        },
        lastSee: {
            items: [],
            add: (book: any) =>
                this.setState((state: any) => ({
                    lastSee: {
                        ...state.lastSee,
                        items: ((_books: any[], _book: any) =>
                            _books.find(__book => __book.id === _book.id)
                                ? [
                                      ..._books.filter(
                                          __book => __book.id !== _book.id,
                                      ),
                                      _book,
                                  ]
                                : _books.length < 4
                                ? [..._books, _book]
                                : [..._books.splice(1, 999), _book])(
                            state.lastSee.items,
                            book,
                        ),
                    },
                })),
        },
        auth: {
            sid: null,
            profile: {},
            isAuthorized: false,
            authorize: (profile: any) =>
                this.setState((state: any) => ({
                    auth: { ...state.auth, isAuthorized: true, profile },
                })),
            signout: () =>
                this.setState((state: any) => ({
                    auth: { ...state.auth, isAuthorized: false, profile: {} },
                })),
        },
        profile: {
            data: {
                commentsCount: 0,
                favoritesCount: 0,
                ordersCount: 0,
                username: '',
            },
            orders: [],
            favorites: [],
            comments: [],
            personal: {},
            getProfile: () => this.getProfile(),
            getOrders: () => this.getOrders(),
            getFavorites: () => this.getFavorites(),
            getPersonal: () => this.getPersonal(),
            getComments: () => this.getComments(),
        },
        mainPage: {
            get: () => this.getMainPage(),
            data: null,
        },
        favorites: {
            set: (id: number) => this.setFavorite(id),
            unset: (id: number) => this.unsetFavorite(id),
        },
        comments: {
            send: (text: string, book: string) => this.sendComment(text, book),
        },
        book: {
            item: {},
            get: (id: number) => this.getBook(id),
        },
        books: {
            get: (filter?: any, pagination?: any) =>
                this.getBooks(filter, pagination),
            getAll: () => this.getPopular(),
        },
    };

    getBag = async () => {
        const cart: any = await this.state.locale.withLocale(api.getBag);

        this.setState((state: any) => ({
            bag: {
                ...state.bag,
                id: cart.cartId,
                books: cart.items,
            },
        }));
    };
    updatePandemiaPopup = async (id: any) => {
        this.setState((state: any) => ({
            popups: { ...state.popups, isRequesting: true },
        }));

        const shops = await this.state.locale.withLocale(api.getAltShops, [id]);

        this.setState((state: any) => ({
            popups: {
                ...state.popups,
                isRequesting: false,
                shops: shops,
                pandemia: true,
            },
        }));
    };
    getFilters = async () => {
        const filters: any = await this.state.locale.withLocale(api.getFilters);

        return filters;
    };
    getBooks = async (filter: any, pagination: any) => {
        const books = await this.state.locale.withLocale(api.getFilteredBooks, [
            filter,
            pagination,
        ]);

        return books;
    };
    getMerch = async () => {
        const merch = await this.state.locale.withLocale(api.getMerch);

        return merch;
    };
    getPopular = async (cat?: string) => {
        const popular: any = await this.state.locale.withLocale(api.getBooks, [
            cat || '',
        ]);

        return popular;
    };
    addBookToCart = async (book: any, amount?: number) => {
        const newCart = await this.state.locale.withLocale(api.addBookToCart, [
            book.id,
            this.state.bag.id,
            amount || 1,
        ]);

        if (newCart.error) {
            // add error handler
        } else {
            this.setState((state: any) => ({
                bag: {
                    ...state.bag,
                    books: newCart.items,
                    lastAdded: book,
                },
            }));
        }
    };
    createNewOrder = async (
        cartId: string,
        isDelivering: boolean,
        order: any,
        push?: any,
    ) => {
        const newOrder = await this.state.locale.withLocale(
            api.createNewOrder,
            [cartId, isDelivering, order],
        );

        this.setState((state: any) => ({
            order: {
                ...state.order,
                id: newOrder.order.order,
                isDelivering: newOrder.order.delivery,
            },
        }));

        const getBag = this.getBag.bind(this);

        new PaytureWidget({
            ...newOrder.paytureOrder,
            OnTransactionCompleted: function() {
                push(`/${this.state.locale.value}/orderResponse`);
                getBag();
            },
        });
    };
    getMainPage = async () => {
        const main = await this.state.locale.withLocale(api.getMain);

        this.setState((state: any) => ({
            mainPage: {
                ...state.mainPage,
                data: main,
            },
        }));
    };
    decrementBook = async (bookId: number, cartId: string) => {
        const newCart = await api.updateBook(bookId, cartId, 'decrement');

        this.setState((state: any) => ({
            bag: {
                ...state.bag,
                books: newCart.items,
            },
        }));
    };
    removeBook = async (bookId: number, cartId: string) => {
        const newCart = await api.updateBook(bookId, cartId, 'remove');

        this.setState((state: any) => ({
            bag: {
                ...state.bag,
                books: newCart.items,
            },
        }));
    };
    getProfile = async () => {
        const profile = await this.state.locale.withLocale(api.getProfile);

        this.setState((state: any) => ({
            profile: {
                ...state.profile,
                data: profile,
            },
        }));
    };
    getOrders = async () => {
        const orders = await this.state.locale.withLocale(api.getOrders);

        this.setState((state: any) => ({
            profile: {
                ...state.profile,
                orders: orders.orders,
            },
        }));
    };
    getFavorites = async () => {
        const favorites = await this.state.locale.withLocale(api.getFavorites);

        this.setState((state: any) => ({
            profile: {
                ...state.profile,
                favorites: favorites.items,
            },
        }));
    };
    getPersonal = async () => {
        const personal = await this.state.locale.withLocale(api.getPersonal);

        this.setState((state: any) => ({
            profile: {
                ...state.profile,
                personal,
            },
        }));
    };
    getComments = async () => {
        const comments = await this.state.locale.withLocale(api.getComments);
        const books = await Promise.all(
            comments.map((comment: any) =>
                this.state.locale.withLocale(api.getBook, [comment.itemId]),
            ),
        );

        const withBooks = comments.map((comment: any, index: number) => ({
            ...comment,
            item: books[index],
        }));

        this.setState((state: any) => ({
            profile: {
                ...state.profile,
                comments: withBooks,
            },
        }));
    };

    setFavorite = async (id: number) => {
        await this.state.locale.withLocale(api.setFavorite, [id]);
    };
    unsetFavorite = async (id: number) => {
        await this.state.locale.withLocale(api.unsetFavorite, [id]);
    };

    sendComment = async (comment: string, book: string) => {
        await this.state.locale.withLocale(api.sendComment, [comment, book]);

        await this.getBook(Number(book));
    };
    getBook = async (id: number) => {
        this.state.popups.hide();

        const _book = await this.state.locale.withLocale(api.getBook, [id]);

        await this.updatePandemiaPopup(_book.items[0].id);

        this.setState((state: any) => ({
            book: {
                ...state.book,
                item: {
                    ..._book,
                },
            },
        }));
    };

    // shouldComponentUpdate = () => false

    getMainAndCart = async () => {
        await this.getMainPage();
        await this.getBag();
    };
    componentDidMount = () => {
        this.getMainAndCart();
    };
    render() {
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppProvider;
