import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';

const Container = styled.div<{ height?: string; bg?: string }>`
    height: ${props => (props.height ? props.height : '131px')};
    background-color: ${props => (props.bg ? props.bg : '#EDEDED')};
    margin-bottom: 7px;
`;

export default function() {
    return (
        <Box margin="0 20px">
            <Container height="43px" bg="#D8D8D8" />
            <Container height="313px" />
            <Container height="43px" bg="#D8D8D8" />
            <Container height="313px" />
            <Container height="43px" bg="#D8D8D8" />
            <Container height="72px" bg="#D8D8D8" />
            <Container height="270px" />
        </Box>
    );
}
