import React, { createContext } from 'react';

export const SearchContext = createContext({});
export class SearchProvider extends React.Component<any> {
    state = {
        query: '',
        updateQuery: (_query: string) => {
            this.setState((state: any) => ({
                ...state,
                query: _query,
            }));
        },
        getQuery: () => this.state.query,
    };
    render() {
        return (
            <SearchContext.Provider value={this.state}>
                {this.props.children}
            </SearchContext.Provider>
        );
    }
}
