import * as React from 'react';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import Icon from 'Src/components/Icon';

interface ISelectState {
    selected: ISelectItem['value'][];
    isOpen: boolean;
}

const Item = styled(Box)`
    & > *:hover {
        cursor: pointer;
    }
`;
const Heading = styled(Box)`
    &:hover {
        cursor: pointer;
    }
`;

const StyledText = styled(Text)`
    user-select: none;
`;

export default class Select extends React.PureComponent<
    {
        title: string;
        items: ISelectItem[];
        initial: any[];
        value: any[];
        onChange: (selected: ISelectItem['value'][]) => void;
        t?: boolean;
        handleOpenCollapseClick?: () => void;
    },
    ISelectState
> {
    state = {
        selected: this.props.initial,
        isOpen: false,
    };
    selectItem(item: ISelectItem) {
        this.setState(
            state => ({
                ...state,
                selected: [...state.selected, item.value],
            }),
            () => {
                this.props.onChange(this.state.selected);
            },
        );
    }
    toggleCollapse = () => {
        this.setState(state => ({ isOpen: !state.isOpen }));
    };
    removeItem(item: ISelectItem) {
        this.setState(
            state => ({
                ...state,
                selected: state.selected.filter(value => value !== item.value),
            }),
            () => {
                this.props.onChange(this.state.selected);
            },
        );
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.value.length !== this.props.value.length) {
            this.setState({
                selected: this.props.value,
            });
        }

        if (this.props.handleOpenCollapseClick) {
            this.props.handleOpenCollapseClick();
        }
    }
    render() {
        const { items } = this.props;

        return (
            <Box borderBottom="2px solid #000">
                <Heading
                    paddingTop="20px"
                    paddingBottom="13px"
                    display="flex"
                    justifyContent="space-between"
                    pr="2"
                    onClick={() => this.toggleCollapse()}
                >
                    <StyledText
                        type="h4"
                        textTransform="uppercase"
                        t={this.props.t}
                        color={
                            this.state.selected.length > 0 ? '#1A67F6' : '#000'
                        }
                    >
                        {this.props.title}
                    </StyledText>
                    <Icon
                        size={20}
                        name={this.state.isOpen ? 'minus' : 'plus'}
                    />
                </Heading>
                <Box mb="3" display={this.state.isOpen ? 'block' : 'none'}>
                    {items.map(item => (
                        <Item
                            key={item.value}
                            display="flex"
                            my="2"
                            onClick={
                                this.state.selected.find(
                                    value => item.value === value,
                                )
                                    ? () => this.removeItem(item)
                                    : () => this.selectItem(item)
                            }
                        >
                            {this.state.selected.find(
                                value => item.value === value,
                            ) && (
                                <Icon
                                    name="crossBlue"
                                    mr="2"
                                    mt="1px"
                                    size="15px"
                                />
                            )}
                            <StyledText
                                fontSize="15px"
                                fontFamily="Fugue"
                                color={
                                    this.state.selected.find(
                                        value => item.value === value,
                                    )
                                        ? '#1A67F6'
                                        : '#000'
                                }
                                textTransform="uppercase"
                            >
                                {item.name}
                            </StyledText>
                        </Item>
                    ))}
                </Box>
            </Box>
        );
    }
}
