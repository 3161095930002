import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import Grid from 'Src/components/blocks/Grid';
import BookGallery from 'Src/components/BookGallery';
import Collapse from 'Src/components/Collapse';
import PageDelimeter from 'Src/components/PageDelimeter';
import PageHeader from 'Src/components/PageHeader';
import { AppContext } from 'Src/model';

import Book from './Book';

const Content = styled(Box)`
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 40px;
`;

// interface ISize {
//     id: number;
//     title: string;
//     isInStore: boolean;
//     variants: any;
// }

class MerchPage extends React.PureComponent<any> {
    static contextType = AppContext;
    state = {
        isFavorite: this.props.book.isFavorite,
        heartIconHover: false,
        comment: '',
        bookAmount: 1,
        id: '',
    };
    handleSetFavoriteClick = (id: number) => {
        this.props.onFavoriteSet(id);
    };
    handleUnsetFavoriteClick = (id: number) => {
        this.props.onFavoriteUnset(id);
    };
    handleCommentSend = (event: any) => {
        if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
            this.props.onCommentSend(this.state.comment, this.props.book.id);
            this.setState({ comment: '' });
        }
    };
    handleCommentChange = (event: any) => {
        this.setState({ comment: event.target.value });
    };
    handleTagClick = (id: string) => {
        this.props.history.push(
            `/${this.context.locale.value}/items?topics=${id}`,
        );
    };
    handleSizeClick = (id: number) => {
        this.setState({ id });
    };
    handleAddBook = (id: string, amount: number) => {
        const _current = this.props.book.items.find(
            (item: any) => item.id === id,
        );
        const _withPrimary = {
            ...this.props.book.items.find(
                (item: any) => item.id === item.primary,
            ),
            variants: _current.variants,
            id: _current.id,
        };

        this.context.bag.addBook(_withPrimary, amount);
    };
    handleChangeBookAmount = (amount: number) => {
        this.setState({
            bookAmount: amount,
        });
    };
    componentDidMount() {
        window.scrollTo(0, 0);

        const primary = this.props.book.items.find(
            (item: any) => String(item.id) === this.props.match.params.id,
        );

        this.setState({ id: primary.id });
    }
    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (prevProps.book.isFavorite !== this.state.isFavorite) {
            this.setState({ isFavorite: prevProps.book.isFavorite });
        }

        if (prevProps.match.url !== this.props.match.url) {
            window.scrollTo(0, 0);
        }

        if (prevState.id !== this.state.id) {
            this.props.history.push(
                `/${this.context.locale.value}/items/${this.state.id}`,
            );
        }
    };
    render() {
        const { book, isLoading, popular } = this.props;

        const primary = book.items.find(
            (item: any) => item.id === this.state.id || item.primary,
        );

        // const sizes = book.items.map((item: ISize) => ({
        //     title: item.variants.size,
        //     id: item.id,
        //     isInStore: item.isInStore,
        // }));

        return (
            <React.Fragment>
                <PageHeader
                    onClick={() =>
                        this.props.history.push(
                            `/${this.context.locale.value}/merch`,
                        )
                    }
                    title={'headers.strelka_press_merch'}
                />
                {primary ? (
                    <Content
                        pt="magic.1"
                        px="magic.1"
                        pb="magic.1"
                        key={primary.id}
                    >
                        <Box>
                            <BookGallery images={primary.images} />
                        </Box>
                        <Box>
                            <Text type="h2" mb="40px" textTransform="uppercase">
                                {primary.title}
                            </Text>
                            {/* <Box display="flex">
                                {primary.isInStore && (
                                    <>
                                        {Object.keys(primary.variants).length >
                                        0 ? (
                                            <Size
                                                sizes={sizes}
                                                onSizeClick={
                                                    this.handleSizeClick
                                                }
                                                selected={+this.state.id}
                                            />
                                        ) : (
                                            <Counter
                                                onChange={
                                                    this.handleChangeBookAmount
                                                }
                                            />
                                        )}
                                    </>
                                )}
                                {primary.isInStore && (
                                    <Box
                                        paddingLeft="16px"
                                        paddingRight="16px"
                                    />
                                )}
                                {primary.isInStore ? (
                                    <AddAndPrice
                                        item={primary}
                                        onClick={() =>
                                            this.handleAddBook(this.state.id, 1)
                                        }
                                    />
                                ) : (
                                    <Text textTransform="uppercase" type="h4">
                                        нет в наличии
                                    </Text>
                                )}
                            </Box> */}
                            <Text mt="magic.1" mb="30px" type="body1">
                                {primary.description}
                            </Text>
                            {primary.summary && (
                                <Collapse t title="ui.about">
                                    <Text
                                        type="body2"
                                        my="10px"
                                        dangerouslySetInnerHTML={{
                                            __html: primary.summary,
                                        }}
                                    ></Text>
                                </Collapse>
                            )}
                            <Collapse t title="order.delivery">
                                <Box mt="10px">
                                    <Text type="body2" mb="13px" t>
                                        texts.delivery1
                                    </Text>
                                    <Text type="body2" mb="13px" t>
                                        texts.delivery2
                                    </Text>
                                    <Text type="body2" mb="13px" t>
                                        texts.delivery3
                                    </Text>
                                    <Text type="body2" mb="13px" t>
                                        texts.delivery4
                                    </Text>
                                </Box>
                            </Collapse>
                        </Box>
                    </Content>
                ) : isLoading ? (
                    'loading'
                ) : (
                    'товар не найден'
                )}
                <PageDelimeter
                    title="headers.also_like"
                    background="#5ECA91"
                    color="#fff"
                />
                <Grid rows={1} cols={4} direction="horizontal">
                    {popular.map((popularBook: any) => (
                        <Book key={popularBook.id} {...popularBook} />
                    ))}
                </Grid>
            </React.Fragment>
        );
    }
}

export default withRouter(MerchPage);
