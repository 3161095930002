// import { themeGet } from '@styled-system/theme-get';
import * as React from 'react';
import MediaQuery from 'react-responsive';
import { RouteComponentProps } from 'react-router';

import { AppContext } from 'Src/model';
import { Book as BookDesktop, Merch as MerchDesktop } from 'Src/pages';
import { Book as BookMobile, Merch as MerchMobile } from 'Src/pages/mobile';
import { Book as BookLoader } from 'Src/pages/PageLoaders';
import { Book as MobileBookLoader } from 'Src/pages/PageLoaders/mobile';

interface IShopItemState {
    book: any;
    popular: IBookShort[];
    isLoading: boolean;
}
type IShopItemProps = RouteComponentProps<{ id: string }>;

export default class ShopItem extends React.PureComponent<
    IShopItemProps,
    IShopItemState
> {
    static contextType = AppContext;
    state: IShopItemState = {
        book: null,
        popular: [],
        isLoading: false,
    };
    async fetchPopular(cat?: string) {
        const popular = await this.context.popular.get(cat);

        this.setState(
            (state: IShopItemState): IShopItemState => ({ ...state, popular }),
        );
    }
    handleSetFavorite(id: number) {
        this.context.favorites.set(id);
        this.setState((state: any) => ({
            book: {
                ...state.book,
                isFavorite: true,
            },
        }));
    }
    handleUnsetFavorite(id: number) {
        this.context.favorites.unset(id);
        this.setState((state: any) => ({
            book: {
                ...state.book,
                isFavorite: false,
            },
        }));
    }
    async handleBookChange() {
        this.setState(
            (state: IShopItemState): IShopItemState => ({
                ...state,
                isLoading: true,
            }),
        );

        await this.context.book.get(parseInt(this.props.match.params.id));

        this.context.lastSee.add(this.context.book.item);

        this.setState(
            (state: IShopItemState): IShopItemState => ({
                ...state,
                book: { ...this.context.book.item },
                isLoading: false,
            }),
            () =>
                this.fetchPopular(
                    this.context.book.item.items[0].categories[0].category,
                ),
        );
    }
    async handleSendComment(comment: string, book: number) {
        await this.context.comments.send(comment, book);
        this.handleBookChange();
    }
    componentDidMount() {
        this.handleBookChange();
    }
    componentDidUpdate(prevProps: IShopItemProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.handleBookChange();
        }
    }
    render() {
        const { book } = this.state;

        return book === null || JSON.stringify(book) === '{}' ? (
            <React.Fragment>
                <MediaQuery maxDeviceWidth={600}>
                    <MobileBookLoader />
                </MediaQuery>
                <MediaQuery minDeviceWidth={601}>
                    <BookLoader />
                </MediaQuery>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <MediaQuery minDeviceWidth={601}>
                    {book.type === 'book' ? (
                        <BookDesktop
                            {...this.state}
                            book={book.items}
                            preorderBooksList={this.context.bag.preorderIds}
                            popular={this.state.popular}
                            onFavoriteUnset={this.handleUnsetFavorite.bind(
                                this,
                            )}
                            onFavoriteSet={this.handleSetFavorite.bind(this)}
                            onCommentSend={this.handleSendComment.bind(this)}
                        />
                    ) : (
                        <MerchDesktop
                            book={book}
                            popular={this.state.popular}
                            onFavoriteUnset={this.handleUnsetFavorite.bind(
                                this,
                            )}
                            onFavoriteSet={this.handleSetFavorite.bind(this)}
                            onCommentSend={this.handleSendComment.bind(this)}
                        />
                    )}
                </MediaQuery>
                <MediaQuery maxDeviceWidth={600}>
                    {book.type === 'book' ? (
                        <BookMobile
                            {...this.state}
                            book={book.items}
                            preorderBooksList={this.context.bag.preorderIds}
                            onFavoriteUnset={this.handleUnsetFavorite.bind(
                                this,
                            )}
                            onFavoriteSet={this.handleSetFavorite.bind(this)}
                            onCommentSend={this.handleSendComment.bind(this)}
                        />
                    ) : (
                        <MerchMobile
                            {...this.state}
                            book={book}
                            onFavoriteUnset={this.handleUnsetFavorite.bind(
                                this,
                            )}
                            onFavoriteSet={this.handleSetFavorite.bind(this)}
                            onCommentSend={this.handleSendComment.bind(this)}
                        />
                    )}
                </MediaQuery>
            </React.Fragment>
        );
    }
}
