import React, { useContext, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
// import GoToAuth from 'Src/components/GoToAuth';
import Icon from 'Src/components/Icon';
import { FindInput } from 'Src/components/mobile';
import { stripLocale } from 'Src/locales';
import { AppContext } from 'Src/model';
import { SearchContext } from 'Src/model/search';

const IconsGroup = styled(Box)`
    display: flex;
    * {
        margin-left: 15px;
        * {
            margin: 0;
        }
    }
`;

// const IconContainer = styled(Box)`
//     align-items: baseline;
//     * {
//         margin: 0;
//     }
// `;
const SandwichContainer = styled(Box)`
    position: absolute;
    top: 75px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 75px);
    background: #fff;
    z-index: 5;
`;

const Sandwich = (props: any) =>
    props.opened ? (
        <Icon
            mr="4px"
            size={20}
            onClick={() => props.onClick(false)}
            name="cross"
        />
    ) : (
        <Icon size={24} onClick={() => props.onClick(true)} name="sandwich" />
    );

const SandwichText = styled(Text)<{ first?: boolean }>`
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #000;
    border-top: ${props => props.first && '1px solid #000'};
    margin-top: ${props => props.first && '20px'};
`;

const SandwichMenu = (props: any) => {
    return (
        <SandwichContainer onClick={props.onClick}>
            <Link to={`/${props.locale}/books`}>
                <SandwichText type="mh1" first t>
                    ui.books
                </SandwichText>
            </Link>
            <Link to={`/${props.locale}/merch`}>
                <SandwichText type="mh1" t>
                    ui.merch
                </SandwichText>
            </Link>
        </SandwichContainer>
    );
};

function Header(props: any) {
    const [isSandwichOpen, switchSandwich] = useState(false);
    const [locale, switchLocale] = useState(props.match.params.locale);
    const context: any = useContext(AppContext);
    const searchContext: any = useContext(SearchContext);

    const handleLocaleClick = () => {
        switchLocale(!locale || locale === 'ru' ? 'en' : 'ru');
        props.history.push(
            (locale ? `/${locale}` : '') +
                stripLocale(props.location.pathname, props.match.params.locale),
        );
        context.locale.set(locale);
    };

    return (
        <Box padding="10px 20px">
            <Box display="flex" jc="space-between" alignItems="center">
                <Link
                    to={
                        context.locale.value ? `/${context.locale.value}/` : '/'
                    }
                >
                    <Text type="mh2" whiteSpace="nowrap">
                        STRELKA SHOP
                    </Text>
                </Link>
                <IconsGroup ml="auto">
                    {/* <Link to={`/${context.locale.value}/cart`}>
                        <IconContainer display="flex">
                            <Icon size={20} name="bag" />
                            <Text type="mh2">
                                (
                                {((_total: number) =>
                                    context.bag.books.map((_book: any) => {
                                        _total += _book.amount;
                                    }) && _total)(0)}
                                )
                            </Text>
                        </IconContainer>
                    </Link> */}
                    {/* <Link to="/">
                <Icon name="heart" />
              </Link>*/}
                    {/* <GoToAuth>
                        <Icon name="person" />
                    </GoToAuth> */}
                    <Sandwich
                        opened={isSandwichOpen}
                        onClick={switchSandwich}
                    />
                </IconsGroup>
            </Box>
            <FindInput
                margin="10px 0 0"
                value={searchContext.query}
                onChange={searchContext.updateQuery}
                onLocaleClick={handleLocaleClick}
                locale={
                    props.match.params.locale === 'ru' ||
                    !props.match.params.locale
                        ? 'English'
                        : 'Русский'
                }
            />
            {isSandwichOpen && (
                <SandwichMenu
                    onClick={() => switchSandwich(false)}
                    locale={context.locale.value}
                />
            )}
        </Box>
    );
}

export default withRouter(Header);
