import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';

const Container = styled.div<{ height?: string; bg?: string }>`
    height: ${props => (props.height ? props.height : '77px')};
    background-color: ${props => (props.bg ? props.bg : '#D8D8D8')};
    margin-bottom: 7px;
`;

export default function() {
    return (
        <Box margin="0 20px">
            <Container height="70px" />
            <Container height="278px" bg="#EDEDED" />
            <Container />
            <Container />
            <Container height="101px" />
            <Container height="72px" />
            <Container height="270px" bg="#EDEDED" />
        </Box>
    );
}
