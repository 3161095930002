import { Field, Form, Formik } from 'formik';
import * as React from 'react';

import { Box, Input } from 'Src/components/basic';

const OrderFormSelf: React.FunctionComponent = function OrderFormSelf(
    props: any,
) {
    return (
        <Formik
            initialValues={{ name: '', surname: '', email: '', phone: '' }}
            onSubmit={(values, { setSubmitting }) => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
            }}
            validate={(values: any) => {
                const errors: any = {};

                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email,
                    )
                ) {
                    errors.email = 'Invalid email address';
                }

                if (!values.name) {
                    errors.name = 'Required';
                }

                if (!values.surname) {
                    errors.surname = 'Required';
                }

                return errors;
            }}
        >
            {({ handleChange, errors, touched }) => (
                <Form>
                    <Box display="flex">
                        <Field
                            mb="3"
                            mr="3"
                            type="text"
                            error={errors.name && touched.name && errors.name}
                            name="name"
                            as={Input}
                            placeholder="ui.name"
                            value={props.name}
                            onChange={(e: any) => {
                                handleChange(e);
                                props.onChange(e.target.name, e.target.value);
                            }}
                            small
                            mobile
                        />
                        <Field
                            mb="3"
                            type="text"
                            error={
                                errors.surname &&
                                touched.surname &&
                                errors.surname
                            }
                            name="surname"
                            as={Input}
                            placeholder="ui.lastName"
                            value={props.surname}
                            onChange={(e: any) => {
                                handleChange(e);
                                props.onChange(e.target.name, e.target.value);
                            }}
                            small
                            mobile
                        />
                    </Box>
                    <Field
                        mb="3"
                        type="email"
                        error={errors.email && touched.email && errors.email}
                        name="email"
                        value={props.email}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                        as={Input}
                        placeholder="ui.email"
                        small
                        mobile
                    />
                </Form>
            )}
        </Formik>
    );
};

export default OrderFormSelf;
