import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';
import Grid from 'Src/components/blocks/Grid';
import Card from 'Src/pages/PageLoaders/Card';

const Sidebar = styled.div`
    grid-area: side-bar;
    width: 250px;
    position: absolute;
    height: 203px;
    background: #d8d8d8;
`;

const Content = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 14.5rem);
    grid-template-areas: 'side-bar items items items';
`;

const GridView = styled.div`
    grid-area: items;
    display: grid;
    grid-row-gap: 4rem;
    justify-content: space-between;
    grid-template-columns: repeat(3, 14.5rem);
`;

const getCards = function(finish: any) {
    const cards = [];

    for (let i = 0; i < finish; i++) {
        cards[i] = <Card />;
    }

    return cards;
};

export default function() {
    return (
        <Box>
            <Box margin="120px 61.5px 4rem" position="relative">
                <Sidebar />
                <Content>
                    <GridView>{getCards(12)}</GridView>
                </Content>
            </Box>
            <Box backgroundColor="#D8D8D8" width="100%" height="43px" />
            <Grid rows={1} cols={4} direction="horizontal">
                {getCards(4)}
            </Grid>
        </Box>
    );
}
