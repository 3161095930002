import React from 'react';

import { Box } from 'Src/components/basic';
import { Book } from 'Src/components/mobile';

export default class extends React.PureComponent<any> {
    state: any = this.props;
    render() {
        return (
            <Box
                display="flex"
                justifyContent="space-between"
                padding="15px 20px"
                flexWrap="wrap"
                mt="15px"
            >
                {this.state.children.map(
                    (_book: IBookShort, _index: number) =>
                        (this.props.booksAmount
                            ? _index < Number(this.state.booksAmount)
                            : true) && <Book {..._book} key={_book.id} />,
                )}
            </Box>
        );
    }
}
