import React from 'react';
import { withRouter } from 'react-router-dom';

import { AppContext } from '../model';
import Popup from '../pages/Popup';

export default withRouter(
    class extends React.Component<any> {
        static contextType = AppContext;
        constructor(props: any) {
            super(props);
        }
        render() {
            return <Popup context={this.context} />;
        }
    },
);
