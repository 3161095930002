import * as React from 'react';
import MediaQuery from 'react-responsive';

import { AppContext } from 'Src/model';
import MainDesktop from 'Src/pages/Main/index';
import MainMobile from 'Src/pages/mobile/Main';
import { Main as MainLoader } from 'Src/pages/PageLoaders';
import { Main as MobileMainLoader } from 'Src/pages/PageLoaders/mobile';

export default class Main extends React.PureComponent<any> {
    static contextType = AppContext;
    state: { pageConfig?: any } = { pageConfig: undefined };
    render() {
        const pageConfig = this.context.mainPage.data;

        if (pageConfig) {
            return (
                <React.Fragment>
                    <MediaQuery minDeviceWidth={601}>
                        <MainDesktop {...pageConfig} />
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={600}>
                        <MainMobile {...pageConfig} />
                    </MediaQuery>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <MediaQuery minDeviceWidth={601}>
                        <MainLoader />
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={600}>
                        <MobileMainLoader />
                    </MediaQuery>
                </React.Fragment>
            );
        }
    }
}
