import React, { Fragment, PureComponent } from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import Logout from 'Src/components/Logout';
import PageHeader from 'Src/components/PageHeader';
import { AppContext } from 'Src/model';
import {
    Comments,
    Favorites,
    Info,
    Orders,
    Personal,
} from 'Src/pages/ProfilePages';

const route2header = (route: string) => {
    switch (route) {
        case '/profile':
            return 'Мой аккаунт';
        case '/profile/orders':
            return 'Мои заказы';
        case '/profile/favorites':
            return 'Моё избранное';
        case '/profile/personal':
            return 'Мои данные';
        case '/profile/comments':
            return 'Мои комментарии';
        default:
            return '';
    }
};

const Page = styled(Box)`
    display: flex;
    padding: 35px 60px;
`;
const Navi = styled(Box)`
    min-width: 320px;
    p {
        font-size: 22px;
        line-height: 24px;
        font-family: UniversRoman;
        text-transform: uppercase;
        padding: 20px 0 18px;
        margin-bottom: 14px;
        border-bottom: 1px solid black;
        margin-right: 110px;
        white-space: nowrap;
    }
`;
const Content = styled(Box)`
    padding: 20px;
    width: 100%;
`;
const MenuLink = (props: any) => {
    return (
        <Link to={props.to}>
            <Text t args={props.args} color={props.active ? 'blue' : 'black'}>
                {props.children}
            </Text>
        </Link>
    );
};

class Profile extends PureComponent<any> {
    static contextType = AppContext;
    componentDidMount() {
        const {
            getProfile,
            getFavorites,
            getOrders,
            getComments,
            getPersonal,
        } = this.context.profile;

        getProfile();
        switch (this.props.location.pathname) {
            case '/profile/orders':
                return getOrders();
            case '/profile/favorites':
                return getFavorites();
            case '/profile/personal':
                return getPersonal();
            case '/profile/comments':
                return getComments();
            default:
                return '';
        }
    }
    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            const {
                getProfile,
                getFavorites,
                getOrders,
                getComments,
                getPersonal,
            } = this.context.profile;

            switch (this.props.location.pathname) {
                case '/profile':
                    return getProfile();
                case '/profile/orders':
                    return getOrders();
                case '/profile/favorites':
                    return getFavorites();
                case '/profile/personal':
                    return getPersonal();
                case '/profile/comments':
                    return getComments();
                default:
                    return '';
            }
        }
    }
    render() {
        const { profile } = this.context;

        return (
            <Fragment>
                <PageHeader
                    title={route2header(this.props.location.pathname)}
                />
                <Page display="flex" padding="35px 60px">
                    <Navi>
                        <MenuLink
                            active={this.props.location.pathname === '/profile'}
                            to="/profile"
                        >
                            headers.profile_main
                        </MenuLink>
                        <MenuLink
                            active={
                                this.props.location.pathname ===
                                '/profile/orders'
                            }
                            to={`/profile/orders`}
                            args={profile.data.ordersCount}
                        >
                            headers.profile_orders
                        </MenuLink>
                        <MenuLink
                            active={
                                this.props.location.pathname ===
                                '/profile/favorites'
                            }
                            to={`/profile/favorites`}
                            args={profile.data.favoritesCount}
                        >
                            headers.profile_favorites
                        </MenuLink>
                        <MenuLink
                            active={
                                this.props.location.pathname ===
                                '/profile/personal'
                            }
                            to={`/profile/personal`}
                        >
                            headers.profile_personal
                        </MenuLink>
                        <MenuLink
                            active={
                                this.props.location.pathname ===
                                '/profile/comments'
                            }
                            to={`/profile/comments`}
                            args={profile.data.commentsCount}
                        >
                            headers.profile_comments
                        </MenuLink>
                        <Logout>
                            <MenuLink to="/">headers.sign_out</MenuLink>
                        </Logout>
                    </Navi>
                    <Content>
                        <Switch>
                            <Route
                                exact
                                path="/profile"
                                component={() => (
                                    <Info {...this.context.profile} />
                                )}
                            />
                            <Route
                                path="/profile/orders"
                                component={() => (
                                    <Orders {...this.context.profile} />
                                )}
                            />
                            <Route
                                path="/profile/favorites"
                                component={() => (
                                    <Favorites
                                        {...this.context.profile}
                                        unsetFavorite={
                                            this.context.favorites.unset
                                        }
                                    />
                                )}
                            />
                            <Route
                                path="/profile/personal"
                                component={() => (
                                    <Personal {...this.context.profile} />
                                )}
                            />
                            <Route
                                path="/profile/comments"
                                component={() => (
                                    <Comments {...this.context.profile} />
                                )}
                            />
                        </Switch>
                    </Content>
                </Page>
            </Fragment>
        );
    }
}

export default withRouter(Profile);
