import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const Container = styled.div<{ rows: number; cols: number }>`
    display: grid;
    grid-template-columns: repeat(${props => props.cols}, 1fr);
    grid-template-rows: repeat(${props => props.rows}, auto);
    grid-row-gap: 3rem;
    justify-items: center;
    padding: 2rem 0;
`;
const Page = styled.div`
    width: 100%;
    flex-shrink: 0;
`;

class Grid extends React.Component<
    IBlockGridScrollableProps & { children: JSX.Element[]; count?: number }
> {
    state = {
        ref: null,
    };
    c: any;

    componentDidUpdate(prevProps: any) {
        if (prevProps.count !== undefined && this.props.count !== undefined) {
            if (prevProps.count > this.props.count) {
                this.c.slickPrev();
            }

            if (prevProps.count < this.props.count) {
                this.c.slickNext();
            }
        }
    }

    render() {
        const cols = this.props.cols || 1;
        const rows = this.props.rows || 0;
        const cellsOnPage = cols * rows;

        if (cellsOnPage) {
            const slices =
                this.props.children &&
                Array(this.props.children) &&
                this.props.children.reduce(
                    (res: any, child: any, i: any) => {
                        const pageNumber = Math.floor(i / cellsOnPage);

                        if (!res[pageNumber]) {
                            res[pageNumber] = {
                                page: pageNumber,
                                children: [],
                            };
                        }
                        res[pageNumber].children.push(child);

                        return res;
                    },
                    [] as { page: number; children: JSX.Element[] }[],
                );
            const settings = {
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                adaptiveHeight: true,
            };

            if (this.props.direction === 'vertical') {
                Object.assign(settings, {
                    vertical: true,
                    verticalSwiping: true,
                });
            }

            return (
                <Slider {...settings} ref={(c: any) => (this.c = c)}>
                    {slices.map((slice: any) => (
                        <Page key={slice.page}>
                            <Container {...{ cols, rows }}>
                                {slice.children}
                            </Container>
                        </Page>
                    ))}
                </Slider>
            );
        }

        return <Container {...{ cols, rows }}>{this.props.children}</Container>;
    }
}

export default Grid;
