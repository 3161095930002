import React, { PureComponent } from 'react';

// import styled from 'styled-components';
import { Box } from 'Src/components/basic';

export default class extends PureComponent<any> {
    render() {
        return <Box>Personal</Box>;
    }
}
