import { getUriFromFilters } from 'Src/utils/filters2uri';

const apiUrl = 'https://api.shop.yc.strelka.com';
// const authUrl = 'https://auth.do.strelka.com';

export const CLIENT = 'shop';

export const getBag = (locale?: string) => {
    return new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/cart`, {
            credentials: 'include',
        }).then((res: any) => resolve(res.json())),
    );
};

export const getMainPage = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/items`, {
            credentials: 'include',
        }).then((res: any) => resolve(res.json())),
    );

export const getMain = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/page/main`, {
            credentials: 'include',
        }).then((res: any) => resolve(res.json())),
    );

export const getFilters = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/items/filters`, {
            credentials: 'include',
        }).then((res: any) => resolve(res.json())),
    );

export const addBookToCart = (bookId: string, cartId: string, amount: number) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/cart/${cartId}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ item: bookId, incr: amount }),
        }).then((res: any) => {
            if (res.ok) {
                resolve(res.json());
            } else {
                if (res.status === 409) {
                    resolve({ error: true, message: 'no_more_items' });
                }
            }

            return null;
        }),
    );

export const getMerch = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/items?cat=merch&limit=1000`).then(
            (res: any) => resolve(res.json()),
        ),
    );

export const getAltShops = (id: string, locale?: string) =>
    new Promise<any>(resolve =>
        fetch(
            `${apiUrl}/api/${locale ? `${locale}/` : ''}altStores/item/${id}`,
        ).then((res: any) => resolve(res.json())),
    );

export const getInstantCart = (config: string) => {
    return new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/en/cart?configId=${config}`, {
            credentials: 'include',
        }).then((res: any) => resolve(res.json())),
    );
};

export const getDeliveryPrice = (
    cartId: string,
    country?: string,
    city?: string,
) =>
    new Promise<any>(resolve =>
        fetch(
            `${apiUrl}/api/ru/order/delivery?cartId=${cartId}&country=${country}&city=${city}`,
            {
                credentials: 'include',
            },
        ).then((res: any) => resolve(res.json())),
    );

export const createNewOrder = (
    cartId: string,
    isDelivering: boolean,
    order: any,
    locale?: string,
) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/order`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cartId,
                delivery: isDelivering,
                userData: order,
            }),
        }).then((res: any) => {
            if (res.status !== 500) {
                resolve(res.json());
            }
        }),
    );

export const updateBook = (
    bookId: number,
    cartId: string,
    action: string,
    configId?: string,
) =>
    new Promise<any>(resolve =>
        fetch(
            `${apiUrl}/api/cart/${cartId}${
                configId ? `?configId=${configId}` : ''
            }`,
            {
                method: 'PATCH',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ item: bookId, action }),
            },
        ).then((res: any) => {
            return resolve(res.json());
        }),
    );

export const signUp = (token: string) => {
    return new Promise<any>(resolve =>
        fetch(`${apiUrl}/auth/access`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, client: 'local' }),
        }).then((responce: any) => {
            resolve(responce.json());
        }),
    );
};

export const signOut = () => {
    return new Promise<any>(resolve =>
        fetch(
            `${apiUrl}/auth/logout?callback=${encodeURIComponent(
                '/',
            )}&client=${CLIENT}`,
            {
                method: 'DELETE',
                credentials: 'include',
                redirect: 'follow',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ test: 'test' }),
            },
        ).then((responce: any) => {
            resolve(responce.json());
        }),
    );
};

export const getProfile = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/profile`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const getBook = (id: number, locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/items/${id}`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const getOrders = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/order`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const getComments = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/comments`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const getFavorites = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/favorites`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const getBooks = (cat: string, locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/items?cat=${cat}&limit=9999`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const getFilteredBooks = (
    filter: any,
    pagination: any,
    locale?: string,
) => {
    pagination;

    return new Promise<any>(resolve =>
        fetch(
            `${apiUrl}/api/${locale}/items${getUriFromFilters(
                filter,
            )}&offset=0&limit=9999&cat=books`,
            {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            },
        ).then((responce: Response) => {
            if (responce.ok) {
                resolve(responce.json());
            }
        }),
    );
};

export const getPersonal = (locale?: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/${locale}/personal`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const setFavorite = (id: number) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}}/api/favorites/${id}`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const unsetFavorite = (id: number) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/favorites/${id}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }).then((responce: Response) => resolve(responce.json())),
    );

export const sendComment = (commentText: string, itemId: string) =>
    new Promise<any>(resolve =>
        fetch(`${apiUrl}/api/comments`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ commentText, itemId }),
        }).then((responce: Response) => resolve(responce.json())),
    );
