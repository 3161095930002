import _cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';

// import CoverVisual from 'Src/components/animations/CoverVisual';
import { Box } from 'Src/components/basic';
import { Cover } from 'Src/components/ResizedImage';

const borderMoving = keyframes`
  0% {
    background-position: 0px 0px, 210% 100%, 0px 125%, 100% 0px;
  }
  100% {
    background-position: 210% 0px, 0px 100%, 0px 0%, 100% 125%;
  }
`;

const Root = styled.div<{ toLeft?: boolean; margin?: string }>`
    ${props => props.margin && `margin: ${props.margin}`};
    display: grid;
    grid-template-columns: ${(props: any) =>
        props.toLeft ? '3.5fr 1fr' : '1fr 3.5fr'};
    padding-bottom: 10%;
    min-height: ${(props: any) => (props.toLeft ? 'auto' : '623px')};
`;

const Border = styled(Box)`
    position: absolute;
    width: 120%;
    height: 114%;
    left: -10%;
    top: -7%;
    background: linear-gradient(90deg, black 50%, transparent 50%),
        linear-gradient(90deg, black 50%, transparent 50%),
        linear-gradient(0deg, black 50%, transparent 50%),
        linear-gradient(0deg, black 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 6px 1px, 6px 1px, 1px 6px, 1px 6px;
    background-position: 0px 0px, 100% 100%, 0px 100%, 100% 0px;
    z-index: -1;
`;

const AnimatedBorder = styled(Border)`
    animation: ${borderMoving} 4s infinite linear;
    z-index: -1;
`;
const StyledBigCover = styled(Cover)`
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    max-width: ${props => props.maxWidth || 1000};
`;
const StyledLittleCover = styled(Cover)`
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
`;
// const BigCover = styled(Cover)`
// `;

type ImageToShow = IBook['images'][0] & { index: number };

export default class BookGallery extends React.PureComponent<
    { images: IBook['images'] } & {
        toLeft?: boolean;
        withoutAnimation?: boolean;
        size?: number[];
        mobile?: boolean;
        margin?: string;
    } // { selectedItemIndex: number } &
> {
    state = {
        selectedItemIndex: 0,
        hoveredItem: -1,
    };
    selectItem(index: number) {
        this.setState(() => ({ selectedItemIndex: index }));
    }
    render() {
        const {
            props: { images }, // TODO: add size to main cover
            state: { selectedItemIndex, hoveredItem },
        } = this;

        const imagesToShow: ImageToShow[] = _cloneDeep(images)
            .slice(0, 3)
            .map((img, index) => ({ ...img, index }));

        // const coverImages = _cloneDeep(images)
        //     .slice(0, 4)
        //     .filter(img => img.purpose && img.purpose !== 'page');

        return (
            <Root toLeft={this.props.toLeft} margin={this.props.margin}>
                {this.props.toLeft && (
                    <StyledBigCover
                        margin={this.props.mobile && '0 auto 0 0'}
                        path={
                            imagesToShow[selectedItemIndex]
                                ? imagesToShow[selectedItemIndex].path
                                : ''
                        }
                        // width={size ? size[0] : 'auto'}
                        height={260}
                        mobile
                    />
                )}
                <Box
                    id="coverBox"
                    display="flex"
                    flexDirection="column"
                    justifyContent={
                        imagesToShow.length === 2
                            ? 'space-around'
                            : 'space-between'
                    }
                    height={
                        this.props.mobile
                            ? '100%'
                            : imagesToShow.length === 2
                            ? '60%'
                            : '75%'
                    }
                    marginTop={this.props.mobile ? '' : '85px'}
                >
                    {imagesToShow.map((image: any, index: number) => {
                        return (
                            <StyledLittleCover
                                position="relative"
                                key={image.index}
                                path={image.path}
                                // isSelected={selectedItemIndex === index}
                                onClick={() => this.selectItem(index)}
                                onMouseEnter={() =>
                                    this.setState({
                                        hoveredItem: index,
                                    })
                                }
                                onMouseLeave={() =>
                                    this.setState({
                                        hoveredItem: -1,
                                    })
                                }
                                height={this.props.toLeft ? 70 : 120}
                                maxWidth={150 / 1.6}
                                withCursor
                            >
                                {hoveredItem === index ? (
                                    <AnimatedBorder />
                                ) : (
                                    selectedItemIndex === index && <Border />
                                )}
                            </StyledLittleCover>
                        );
                    })}
                </Box>
                {this.props.withoutAnimation || (
                    <StyledBigCover
                        path={
                            imagesToShow[selectedItemIndex]
                                ? imagesToShow[selectedItemIndex].path
                                : ''
                        }
                        height={530}
                        maxWidth={530}
                    />
                )}
            </Root>
        );
    }
}
