import React, { useContext, useReducer } from 'react';

const initialState = {
    language: JSON.parse(localStorage.getItem('language') || '"ru"') || 'ru',
    status: null,
};

const actionTypes = {
    LANGUAGE_SET: 'LANGUAGE_SET',
};

const reducer = (state: any, action: any) => {
    const { LANGUAGE_SET } = actionTypes;
    const { type, payload } = action;

    switch (type) {
        case LANGUAGE_SET:
            return { ...state, language: payload };
        default:
            return state;
    }
};

export const TranslationContext = React.createContext({
    state: initialState,
    actions: {},
});

export function useTranslation(): any {
    const context = useContext(TranslationContext);

    if (context === undefined) {
        throw new Error(
            'useTranslation must be used within a TranslationProvider',
        );
    }

    return context;
}

export function TranslationProvider({ children }: any) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const actions = {
        setLanguage: async (language: string) => {
            dispatch({ type: actionTypes.LANGUAGE_SET, payload: language });
            localStorage.setItem('language', JSON.stringify(language));
        },
    };

    return (
        <TranslationContext.Provider value={{ state, actions }}>
            {children}
        </TranslationContext.Provider>
    );
}
