import React, { createContext } from 'react';

import { createNewOrder, getInstantCart, updateBook } from 'Src/api';
import { PaytureWidget } from 'Src/external/paytureLib/payture.widget.min.js';

interface InstantCartContext {
    cartId: string;
    configId: string;
    items: any[];
    total: number;
    getCart: (cartId: string) => any;
    increment: (itemId: number) => any;
    decrement: (itemId: number) => any;
    createOrder: (order: any) => any;
    isRequesting: boolean;
    error: null | string;
}

export const InstantCartContext = createContext({});

export class InstantCartProvider extends React.Component<
    any,
    InstantCartContext
> {
    state: InstantCartContext = {
        cartId: '',
        configId: '',
        items: [],
        total: 0,
        getCart: (cartId: string) => this.getCart(cartId),
        increment: (itemId: number) =>
            this.increment(itemId, this.state.cartId, this.state.configId),
        decrement: (itemId: number) =>
            this.decrement(itemId, this.state.cartId, this.state.configId),
        createOrder: (order: any) => this.createOrder(this.state.cartId, order),
        isRequesting: true,
        error: null,
    };

    getCart(cartId: string) {
        this.setState({ isRequesting: true, configId: cartId });
        getInstantCart(cartId).then(data => {
            if (data.message) {
                this.setState({
                    isRequesting: false,
                    error: 'No available items',
                });
            } else {
                this.setState(
                    {
                        cartId: data.cartId,
                        items: data.items,
                        total: data.total,
                    },
                    () => this.setState({ isRequesting: false, error: null }),
                );
            }
        });

        return null;
    }
    increment(itemId: number, cartId: string, configId: string) {
        updateBook(itemId, cartId, 'increment', configId).then(data =>
            this.setState({
                items: data.items,
                total: data.total,
            }),
        );
    }
    decrement(itemId: number, cartId: string, configId: string) {
        updateBook(itemId, cartId, 'decrement', configId).then(data =>
            this.setState({
                items: data.items,
                total: data.total,
            }),
        );
    }
    createOrder = async (cartId: string, order: any) => {
        const newOrder = await createNewOrder(cartId, false, order, 'en');

        // console.log(newOrder);

        // this.setState((state: any) => ({
        //     order: {
        //         ...state.order,
        //         id: newOrder.order.order,
        //         isDelivering: newOrder.order.delivery,
        //     },
        // }));

        new PaytureWidget({
            ...newOrder.paytureOrder,
            OnTransactionCompleted: function() {
                // console.log(123);
                // push(`/${this.state.locale.value}/orderResponse`);
            },
        });
    };

    render() {
        return (
            <InstantCartContext.Provider value={this.state}>
                {this.props.children}
            </InstantCartContext.Provider>
        );
    }
}
