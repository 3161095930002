import styled from 'styled-components';

import arrowDown from 'Src/assets/icons/arrowDown.svg';
import arrowFind from 'Src/assets/icons/arrowFind.svg';
import arrowLeft from 'Src/assets/icons/arrowLeft.svg';
import arrowLink from 'Src/assets/icons/arrowLink.svg';
import arrowNavi from 'Src/assets/icons/arrowNavi.svg';
import arrowTopRight from 'Src/assets/icons/arrowTopRight.svg';
import bag from 'Src/assets/icons/bag.svg';
import burger from 'Src/assets/icons/burger.svg';
import check from 'Src/assets/icons/check.svg';
import crossBlue from 'Src/assets/icons/cross-blue.svg';
import cross from 'Src/assets/icons/cross.svg';
import fb from 'Src/assets/icons/fb.svg';
import featureText from 'Src/assets/icons/featureText.svg';
import featureTextDesktop from 'Src/assets/icons/featureTextDesktop.svg';
import glass from 'Src/assets/icons/glass.svg';
import grid from 'Src/assets/icons/grid.svg';
import grid4 from 'Src/assets/icons/grid4.svg';
import grid8 from 'Src/assets/icons/grid8.svg';
import heartWhite from 'Src/assets/icons/heart-white.svg';
import heart from 'Src/assets/icons/heart.svg';
import instagram from 'Src/assets/icons/instagram.svg';
import list from 'Src/assets/icons/list.svg';
import minus from 'Src/assets/icons/minus.svg';
import minusWhite from 'Src/assets/icons/minusWhite.svg';
import mminus from 'Src/assets/icons/mminus.svg';
import mobilegrid from 'Src/assets/icons/mobilegrid.svg';
import mobilelist from 'Src/assets/icons/mobilelist.svg';
import mplus from 'Src/assets/icons/mplus.svg';
import person from 'Src/assets/icons/person.svg';
import plus from 'Src/assets/icons/plus.svg';
import plusBlue from 'Src/assets/icons/plusBlue.svg';
import plusWhite from 'Src/assets/icons/plusWhite.svg';
import radioActive from 'Src/assets/icons/radioActive.png'; // PUMS
import radioInactive from 'Src/assets/icons/radioActive.svg';
import sandwich from 'Src/assets/icons/sandwich.svg';
import share from 'Src/assets/icons/share.svg';
import square from 'Src/assets/icons/square.svg';
import twitter from 'Src/assets/icons/twitter.svg';
import vimeo from 'Src/assets/icons/vimeo.svg';
import vk from 'Src/assets/icons/vk.svg';
import whiteCross from 'Src/assets/icons/whiteCross.svg';
import youtube from 'Src/assets/icons/youtube.svg';

import { Box } from './basic';

const icons: Record<IIconProps['name'], string> = {
    arrowDown,
    arrowLeft,
    arrowLink,
    arrowNavi,
    heart,
    heartWhite,
    crossBlue,
    person,
    arrowFind,
    bag,
    burger,
    glass,
    fb,
    plusWhite,
    minusWhite,
    instagram,
    twitter,
    vimeo,
    arrowTopRight,
    check,
    vk,
    youtube,
    grid,
    mobilegrid,
    list,
    mobilelist,
    plus,
    plusBlue,
    minus,
    share,
    cross,
    whiteCross,
    radioActive,
    radioInactive,
    sandwich,
    grid4,
    grid8,
    square,
    featureText,
    featureTextDesktop,
    mminus,
    mplus,
};

interface IIconProps {
    name:
        | 'arrowDown'
        | 'arrowLeft'
        | 'arrowLink'
        | 'arrowNavi'
        | 'heart'
        | 'heartWhite'
        | 'person'
        | 'crossBlue'
        | 'bag'
        | 'burger'
        | 'check'
        | 'glass'
        | 'fb'
        | 'arrowFind'
        | 'instagram'
        | 'twitter'
        | 'vimeo'
        | 'vk'
        | 'grid'
        | 'plusWhite'
        | 'minusWhite'
        | 'mobilegrid'
        | 'list'
        | 'mobilelist'
        | 'plus'
        | 'plusBlue'
        | 'minus'
        | 'sandwich'
        | 'share'
        | 'youtube'
        | 'radioActive'
        | 'radioInactive'
        | 'arrowTopRight'
        | 'grid4'
        | 'grid8'
        | 'square'
        | 'featureText'
        | 'featureTextDesktop'
        | 'mminus'
        | 'mplus'
        | 'cross'
        | 'whiteCross';
}

const Image = styled(Box)<IIconProps>`
    display: inline-block;
    min-width: ${props => props.size} !important;
    min-height: ${props => props.size} !important;
    background: url(${props => icons[props.name] || ''}) center/contain
        no-repeat;
`;

Image.defaultProps = {
    size: '3',
};

export default Image;
