import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';

const Container = styled.div<{ background?: string; height?: string }>`
    background: ${props => props.background || '#EDEDED'};
    height: ${props => props.height || '79px'};
    overflow: hidden;
    margin-bottom: 22.65px;
`;

const FlexContainer = styled.div<{
    flexDirection?: string;
    marginRight?: string;
}>`
    display: flex;
    flex-direction: ${props => props.flexDirection || 'row'};
    margin-right: ${props => props.marginRight || '0'};
    width: 100%;
`;

export default function() {
    return (
        <Box marginLeft="61.5px" marginRight="61.5px">
            <Container height="358px" />
            <Container background="#D8D8D8" height="78px" />
            <Container height="542px" />
            <FlexContainer>
                <FlexContainer flexDirection="column" marginRight="25px">
                    <Container background="#D8D8D8" height="78px" />
                    <Container height="1070px" />
                </FlexContainer>
                <FlexContainer flexDirection="column">
                    <Container background="#D8D8D8" height="78px" />
                    <Container height="512px" />
                    <Container height="535px" />
                </FlexContainer>
            </FlexContainer>
        </Box>
    );
}
