// import { themeGet } from '@styled-system/theme-get';
import * as React from 'react';
import MediaQuery from 'react-responsive';

import { OrderResponse as OrderResponseDesktop } from 'Src/pages';

export default function() {
    return (
        <React.Fragment>
            <MediaQuery maxDeviceWidth={600}>
                {/* <ProfileMobile /> */}
            </MediaQuery>
            <MediaQuery minDeviceWidth={601}>
                <OrderResponseDesktop />
            </MediaQuery>
        </React.Fragment>
    );
}
