import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Text } from 'Src/components/basic';
import Icon from 'Src/components/Icon';

const Box = styled(Link)`
    cursor: pointer;
    display: flex;
`;

export default (props: { children: string; to: string }) => {
    return (
        <Box to={props.to}>
            <Text type="h5" t textTransform="uppercase">
                {props.children}
            </Text>
            <Icon name="arrowLink" size="12px" m="3px 0 0 7px"></Icon>
        </Box>
    );
};
