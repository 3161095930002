import React, { useState } from 'react';
import styled from 'styled-components';

import { Carousel } from 'Src/components';
import { Box } from 'Src/components/basic';
// import Icon from 'Src/components/Icon';

const Container = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 358px;
    overflow: hidden;
`;

// const Header = styled(Box)`
//     height: 100%;
//     padding: 70px 60px;
//     border-right: 2px solid #000;
// `;

// const FeatureIcon = styled(Icon)`
//     width: 100%;
//     height: 100%;
// `;

export default function PromoItem(props: any) {
    const [ref1, setRef1] = useState(null);
    const [ref2, setRef2] = useState(null);

    return (
        <Container>
            <Box minWidth="50%">
                <Carousel
                    items={props.promoItems}
                    speed={700}
                    setRef={setRef1}
                    withoutDots
                    anotherSlider={ref2}
                    withoutText
                />
            </Box>
            <Box minWidth="50%" borderLeft="1">
                <Carousel
                    items={props.promoItems}
                    speed={700}
                    setRef={setRef2}
                    anotherSlider={ref1}
                    withoutPicture
                />
            </Box>
        </Container>
    );
}
