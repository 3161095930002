import React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';

const Container = styled.div<{ height?: string; bg?: string }>`
    height: ${props => (props.height ? props.height : '72px')};
    background-color: ${props => (props.bg ? props.bg : '#EDEDED')};
    margin-bottom: 7px;
`;

export default function() {
    return (
        <Box margin="0 20px">
            <Container height="266px" />
            <Container height="103px" bg="#D8D8D8" />
            <Container bg="#D8D8D8" />
            <Container height="216px" />
            <Container height="203.5px" />
            <Container bg="#D8D8D8" />
            <Container bg="#D8D8D8" />
            <Container height="203.5px" />
            <Container height="203.5px" />
            <Container bg="#D8D8D8" />
            <Container height="255px" />
            <Container height="211.5px" />
        </Box>
    );
}
