import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';

import * as api from 'Src/api';
import Footer from 'Src/components/footer';
import Header from 'Src/components/header';
import { withContext } from 'Src/model';
import theme from 'Src/theme';
import { jwt2json } from 'Src/utils/jwt';
import Book from 'Src/views/Book';
import Popup from 'Src/views/BookPopup';
import Filtered from 'Src/views/Filtered';
import Find from 'Src/views/Find';
import Main from 'Src/views/Main';
import MerchShop from 'Src/views/MerchShop';
import NotFound from 'Src/views/NotFound';
import Order from 'Src/views/Order';
import OrderResponse from 'Src/views/OrderResponse';
import Profile from 'Src/views/Profile';

const View = styled.main`
    flex-grow: 1;
`;

class App extends React.Component<any> {
    state: any = {
        isInstant: document.location.pathname.includes('/instant'),
    };
    shouldComponentUpdate = (nextProps: any) => {
        return (
            this.props.context.popups.pandemia !==
            nextProps.context.popups.pandemia
        );
    };
    componentDidMount() {
        const token = document.cookie
            .split(';')
            .map((cookie: string) => ({
                key: cookie.split('=')[0],
                value: cookie.split('=')[1],
            }))
            .find(
                cookie =>
                    cookie.key.includes('accessToken') ||
                    cookie.key.includes('str-auth-token'),
            );

        if (token) {
            this.props.context.auth.authorize(jwt2json(token.value).profile);
            // window.history.pushState({}, 'Home', '/');
        } else if (document.location.search.includes('token')) {
            this.sendToken(document.location.search.split('=')[1]);
        }
    }
    componentDidUpdate() {
        console.info('update');
    }
    async sendToken(token: string) {
        const authData = await api.signUp(token);

        this.props.context.auth.authorize(
            jwt2json(authData.accessToken).profile,
        );
        this.props.context.bag.getCart();

        document.cookie = `accessToken=${authData.accessToken}`;
        document.cookie = `sid=${authData.sid}`;
        document.cookie = `uuid=${authData.uuid}`;
        window.history.pushState({}, 'Home', '/');
    }
    render() {
        const base = '/:locale(en|ru)?';

        return (
            <ThemeProvider theme={theme}>
                <Router>
                    {/* {this.state.isInstant ? (
                        <Route path="/instant/:cart" component={InstantCart} />
                    ) : ( */}
                    <>
                        <Route path={`${base}`} component={Header} />
                        <View>
                            <Popup />
                            <Switch>
                                <Route
                                    path={`${base}`}
                                    exact
                                    component={Main}
                                />
                                <Route
                                    exact
                                    path={`${base}/books`}
                                    component={Filtered}
                                />
                                <Route
                                    exact
                                    path={`${base}/merch`}
                                    component={MerchShop}
                                />
                                <Route
                                    path={`${base}/items/:id`}
                                    component={Book}
                                />
                                {/* <Route path={`${base}/cart`} component={Cart} /> */}
                                <Route path={`${base}/find`} component={Find} />
                                <Route
                                    path={`${base}/order`}
                                    component={Order}
                                />
                                <Route
                                    path={`${base}/orderResponse`}
                                    component={OrderResponse}
                                />
                                <Route
                                    path={`${base}/profile`}
                                    component={Profile}
                                />
                                <Route
                                    path={`${base}/profile/*`}
                                    component={Profile}
                                />
                                <Route component={NotFound} />
                            </Switch>
                        </View>
                        <Footer />
                    </>
                    {/* )} */}
                </Router>
            </ThemeProvider>
        );
    }
}

export default withContext(App);
