import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text as BaseText } from 'Src/components/basic';
// import { Cover } from 'Src/components/ResizedImage';

const Text = styled(BaseText)`
    font-family: WilliamDisplay;
    font-size: 36px;
    line-height: 42px;
`;

const BookContent = styled(Box)`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    padding: 20px;
`;
const BoxLeftCenter = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const TextBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -40px;
`;
const BoxRightCenter = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Book = (props: any) => (
    <BookContent>
        <BoxLeftCenter>
            {/* <Cover
                path={props.images.find((image: any) => image.isCover).path}
                height={177}
                margin="0"
            />*/}
        </BoxLeftCenter>
        <TextBox>
            <BaseText
                fontSize="15px"
                marginBottom="17px"
                fontFamily="1"
                lineHeight="18px"
            >
                КНИГА: {props.title}
            </BaseText>
            <u>
                <Link to={`/items/${props.id}`}>
                    <BaseText fontSize="15px" fontFamily="2">
                        Перейти на страницу товара
                    </BaseText>
                </Link>
            </u>
        </TextBox>
        <BoxRightCenter>
            <BaseText fontSize="15px" fontFamily="1">
                {props.price / 100} РУБ
            </BaseText>
        </BoxRightCenter>
    </BookContent>
);

export default class extends PureComponent<any> {
    render() {
        const { orders } = this.props;

        return orders.length === 0 ? (
            <Text>
                У вас нет заказов.{' '}
                <Link to="/items">
                    <u>Перейти в магазин</u>
                </Link>
            </Text>
        ) : (
            orders.map((order: any, orderIndex: number) => (
                <Box key={orderIndex}>
                    {order.items.map((book: any, bookIndex: number) => (
                        <Book {...book} key={bookIndex} />
                    ))}
                </Box>
            ))
        );
    }
}
