import React, { useState } from 'react';
import styled from 'styled-components';

import { Box as BaseBox, Text as BaseText } from 'Src/components/basic';
import BaseIcon from 'Src/components/Icon';

const Box = styled(BaseBox)`
    padding: 40px 4rem 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const ArrowsBox = styled(BaseBox)`
    margin-top: -27px;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
`;
const Icon = styled(BaseIcon)`
    margin-top: 10px;
    justify-self: start;
    cursor: pointer;
`;
const IconRotated = styled(Icon)`
    transform: rotate(180deg);
    justify-self: end;
`;
const Text = styled(BaseText)`
    text-align: center;
    z-index: 2;
    user-select: none;

    &.pointer {
        cursor: pointer;
    }
`;

export default function(props: any) {
    const [count, updateCount] = useState(0);

    const handleChange = async (_count: number) => {
        await updateCount(_count);
        props.updateCount(_count);
    };

    return (
        <Box border="0px solid #000">
            <Text
                type="h3"
                t={props.t}
                textTransform="uppercase"
                className={props.onClick && 'pointer'}
                onClick={props.onClick}
            >
                {props.children}
            </Text>
            <ArrowsBox>
                <Icon
                    onClick={() => handleChange(count - 1)}
                    name="arrowNavi"
                    size={35}
                />
                <IconRotated
                    onClick={() => handleChange(count + 1)}
                    name="arrowNavi"
                    size={35}
                />
            </ArrowsBox>
        </Box>
    );
}
