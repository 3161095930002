// import { themeGet } from '@styled-system/theme-get';
import * as React from 'react';
import styled from 'styled-components';

import { Box, Text as BaseText } from 'Src/components/basic';
import Icon from 'Src/components/Icon';

const HeaderText = styled(BaseText)`
    text-transform: uppercase;
`;

const StyledLink = styled.a`
    margin-right: 2px;
`;

const TopBox = styled(Box)`
    padding: 1.5rem 1rem 0;
`;

const BottomBox = styled(Box)`
    padding: 1rem 1rem 20px;
`;

const TextBox = styled(Box)`
    display: inline;
    white-space: nowrap;
    margin-right: 5px;
`;

export default function() {
    return (
        <Box
            color="black"
            backgroundColor="white"
            borderTop="1px solid black"
            borderBottom="1px solid black"
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Box display="flex">
                    <TopBox width="50%" borderRight="1px solid black">
                        <HeaderText type="mh3" t>
                            footer.contact_us
                        </HeaderText>
                        <BaseText type="mbody3" mt="8px">
                            books@strelka.com
                            <br />
                            +7 (495) 268 06 19
                        </BaseText>
                    </TopBox>
                    <TopBox width="50%">
                        <HeaderText type="mh3" t>
                            footer.follow_us
                        </HeaderText>
                        <BaseText type="mbody3" mt="8px">
                            <TextBox>
                                <StyledLink
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/StrelkaPress/?ref=bookmarks"
                                >
                                    Facebook
                                </StyledLink>
                                <Icon name="arrowTopRight" size={8} mb="-2px" />
                            </TextBox>
                            <TextBox>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/strelka_institute/"
                                >
                                    Instagram
                                </a>
                                <Icon name="arrowTopRight" size={8} mb="-2px" />
                            </TextBox>
                        </BaseText>
                        {/* <StyledLink target="_blank" rel="noopener noreferrer">
                            <BaseText type="mbody3" t>
                                footer.follow
                            </BaseText>
                        </StyledLink> */}
                    </TopBox>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <BottomBox width="50%" borderRight="1px solid black">
                        <HeaderText type="mh3" t>
                            footer.find_us
                        </HeaderText>
                        <BaseText type="mbody3" mt="8px" t>
                            footer.address
                        </BaseText>
                    </BottomBox>
                    <BottomBox width="50%">
                        <HeaderText whiteSpace="wrap" type="mh3" t>
                            footer.strelka
                        </HeaderText>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/rules.pdf"
                        >
                            <Box display="flex" mt="8px">
                                <BaseText type="mbody3" t>
                                    footer.terms_and_conditions
                                </BaseText>
                                <Icon
                                    name="arrowTopRight"
                                    size={8}
                                    marginTop="2px"
                                />
                            </Box>
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://strelka-storage.fra1.cdn.digitaloceanspaces.com/shop/files/docs/privacy-policy.pdf"
                        >
                            <Box display="flex">
                                <BaseText type="mbody3" t>
                                    footer.privacy_policy
                                </BaseText>
                                <Icon
                                    name="arrowTopRight"
                                    size={8}
                                    marginTop="2px"
                                />
                            </Box>
                        </a>
                    </BottomBox>
                </Box>
            </Box>
        </Box>
    );
}
