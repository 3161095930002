import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { ArrowsHeader } from 'Src/components';
import { Box } from 'Src/components/basic';
import Block from 'Src/components/blocks';
import SeeAllButton from 'Src/components/SeeAllButton';
import { AppContext } from 'Src/model';

import NewPopular from './NewPopular';
import PromoItem from './PromoItem';

const Main: any = (props: any) => {
    const [booksCount, updateBooksCount] = useState(0);
    const [merchCount, updateMerchCount] = useState(0);
    const [popularCount, updatePopularCount] = useState(0);

    const appContext: any = useContext(AppContext);

    const updateCount = (count: string) => (value: number) => {
        switch (count) {
            case 'books':
                return updateBooksCount(value);
            case 'merch':
                return updateMerchCount(value);
            case 'popular':
                return updatePopularCount(value);
            default:
                return null;
        }
    };

    return (
        <Box borderTop="2px solid #000">
            <PromoItem {...props.props} />
            <Box borderTop="2px solid #000">
                <ArrowsHeader
                    t
                    updateCount={updateCount('books')}
                    onClick={() =>
                        props.history.push(`/${appContext.locale.value}/books`)
                    }
                >
                    headers.strelka_press_books
                </ArrowsHeader>
                <Block count={booksCount} {...props.blocks[0]} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    m="0 0 0"
                    padding="0 0 30px"
                >
                    <SeeAllButton to="/books">ui.see_all</SeeAllButton>
                </Box>
            </Box>
            <NewPopular updateCount={updateCount}>
                <Block count={merchCount} {...props.blocks[1]} />
                <Block count={popularCount} {...props.blocks[2]} />
            </NewPopular>
        </Box>
    );
};

export default withRouter(Main);
