import { Field, Form, Formik } from 'formik';
import * as React from 'react';

import { Box, Input } from 'Src/components/basic';

const OrderFormSelf: React.FunctionComponent = function OrderFormSelf(
    props: any,
) {
    return (
        <Formik
            initialValues={{
                name: '',
                surname: '',
                email: '',
                phone: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
            }}
            validate={(values: any) => {
                const errors: any = {};

                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email,
                    )
                ) {
                    errors.email = 'Invalid email address';
                }

                if (!values.name) {
                    errors.name = 'Required';
                }

                if (!values.surname) {
                    errors.surname = 'Required';
                }

                if (!values.phone) {
                    errors.phone = 'Required';
                } else if (
                    !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                        values.phone,
                    )
                ) {
                    errors.phone = 'Invalid phone number';
                }

                return errors;
            }}
        >
            {({ handleChange, errors, touched }) => (
                <Form>
                    <Box display="flex" mb="3">
                        <Box width="100%">
                            <Field
                                mr="3"
                                type="text"
                                name="name"
                                error={
                                    errors.name && touched.name && errors.name
                                }
                                as={Input}
                                placeholder="ui.name"
                                value={props.name}
                                onChange={(e: any) => {
                                    handleChange(e);
                                    props.onChange(
                                        e.target.name,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Box>
                        <Box width="100%">
                            <Field
                                type="text"
                                name="surname"
                                error={
                                    errors.surname &&
                                    touched.surname &&
                                    errors.surname
                                }
                                as={Input}
                                placeholder="ui.lastName"
                                value={props.surname}
                                onChange={(e: any) => {
                                    handleChange(e);
                                    props.onChange(
                                        e.target.name,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Box>
                    </Box>
                    <Field
                        mb="3"
                        type="email"
                        name="email"
                        error={errors.email && touched.email && errors.email}
                        as={Input}
                        placeholder="ui.email"
                        value={props.email}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                    />
                    <Field
                        mb="3"
                        type="tel"
                        name="phone"
                        error={errors.phone && touched.phone && errors.phone}
                        as={Input}
                        placeholder="ui.phone"
                        value={props.phone}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default OrderFormSelf;
