import * as React from 'react';
import styled from 'styled-components';

import { Text } from 'Src/components/basic';

import { Box } from './basic';

const Heading = styled(Box)<{ background?: string; color?: string }>`
    text-transform: uppercase;
    text-align: center;
    background: ${props => props.background || '#f3fa75'};
    border: 0;
    color: ${props => props.color || '#000'};
`;

export default function PageHeader(props: {
    title: string;
    mobile?: boolean;
    background?: string;
    color?: string;
    margin?: string;
}) {
    return (
        <Heading
            borderY="0"
            // py={props.mobile ? '2' : '2.5'}
            padding={props.mobile ? '7px' : '10px'}
            margin={props.margin}
            background={props.background}
            color={props.color}
        >
            <Text
                type={props.mobile ? 'mh2' : 'h4'}
                t
                textTransform="uppercase"
            >
                {props.title}
            </Text>
        </Heading>
    );
}
