import React, { useState } from 'react';
import styled from 'styled-components';

import { Box as BaseBox, Text as BaseText } from 'Src/components/basic';

// const animations = [
//     {
//         animationName: 'topPartTransformation',
//         animation: keyframes`
//       0% {
//           width: 100%;
//           height: 80%;
//           top: 0;
//           left: 0;
//       }

//       25% {
//           width: 100%;
//           height: 15%;
//           top: 0;
//           left: 0;
//       }

//       45% {
//           width: 75%;
//           height: 15%;
//           top: 0;
//           left: 0;
//       }

//       75% {
//           width: 100%;
//           height: 50%;
//           top: 0;
//           left: 0;
//       }

//       100% {
//           width: 100%;
//           height: 80%;
//           top: 0;
//           left: 0;
//       }
//     `,
//     },
//     {
//         animationName: 'bottomPartTransformation',
//         animation: keyframes`
//       0% {
//           width: 90%;
//           height: 20%;
//           top: 80%;
//           left: 0;
//       }

//       25% {
//           width: 90%;
//           height: 85%;
//           top: 15%;
//           left: 0;
//       }

//       45% {
//           width: 80%;
//           height: 85%;
//           top: 15%;
//           left: 20%;
//       }

//       75% {
//           width: 80%;
//           height: 50%;
//           top: 50%;
//           left: 20%;
//       }

//       100% {
//           width: 90%;
//           height: 20%;
//           top: 80%;
//           left: 0;
//       }
//     `,
//     },
// ];

// const headingAnimationSpread = keyframes`
//   100% {
//     height: 195px;
//   }
// `;

// const headingAnimationNarrow = keyframes`
//   100% {
//     height: 110px;
//   }
// `;

// const topPartSpread = keyframes`
//   50% {
//     width: 100%;
//     height: 75%;
//     top: 0;
//     left: 0;
//   }

//   to {
//     width: 100%;
//     height: 75%;
//     top: 0;
//     left: 0;
//   }
// `;

// const titleAnimationNarrow = keyframes`
//   100% {
//     opacity: 0;
//     width: 0;
//     padding: 0;
//   }
// `;

// const titleTextAnimationNarrow = keyframes`
//   0% {
//     transform: scaleX(1)
//   }
//   100% {
//     transform: scaleX(0)
//   }
// `;

// const visualParentAnimationSpread = keyframes`
//   100% {
//     width: 100%;
//   }
// `;

// const titleAnimationSpread = keyframes`
//   0% {
//     width: 0;
//     opacity: 0;
//     padding: 0;
//   }
//   100% {
//     opacity: 1;
//     width: 50%;
//     padding: 1rem;
//   }
// `;

// const titleTextAnimationSpread = keyframes`
//   0% {
//     transform: scaleX(0)
//   }
//   100% {
//     transform: scaleX(1)
//   }
// `;

// const visualParentAnimationNarrow = keyframes`
//   100% {
//     width: 50%;
//   }
// `;

// const bottomPartSpread = keyframes`
//   50% {
//     width: 90%;
//     height: 25%;
//     top: 75%;
//     left: 0;
//   }

//   to {
//     width: 90%;
//     height: 25%;
//     top: 75%;
//     left: 0;
//   }
// `;

// const textAnimationFromTransparent = keyframes`
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// `;

// const textAnimationToTransparent = keyframes`
//   0% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// `;

// const animation = (animationName: string) => {
//     let _animation = {};

//     animations.map(item => {
//         if (animationName === item.animationName) {
//             _animation = item.animation;
//         }
//     });

//     return _animation;
// };

// const Heading = styled.div`
//     border: solid rgb(0, 0, 0);
//     border-width: 1px 0 1px;
//     height: 110px;
//     grid-template-columns: none;
//     grid-column: none;
//     display: flex;
//     transition: 1s ease forwards;
//     &:hover {
//         cursor: pointer;
//     }
//     > *:not(*:last-of-type) {
//         border-right: 1px solid rgb(0, 0, 0);
//     }
// `;

// const PressTitles = styled.div`
//     grid-column: 1 / span 3;
//     font-size: 17px;
//     width: 50%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     padding: 1rem;
//     background: url(${lineStraightSVG}) center/100% 100% no-repeat;
//     > *:last-of-type {
//         align-self: flex-end;
//     }
// `;

// const Visual = styled.div<{
//     animationName: string;
//     width: string;
//     height: string;
//     top: string;
//     left: string;
// }>`
//     position: absolute;
//     box-sizing: border-box;
//     padding: 2.5px;
//     animation: ${props => animation(props.animationName)} 7s infinite linear
//         running;
//     width: ${props => props.width};
//     height: ${props => props.height};
//     top: ${props => props.top};
//     left: ${props => props.left};
// `;

// const VisualInner = styled.div`
//     background-color: rgb(243, 250, 117);
//     width: 100%;
//     height: 100%;
// `;

// const Wrapper = styled.div`
//     position: relative;
//     width: 100%;
//     height: 100%;
// `;

// const Parent = styled.div`
//     padding: 2.5px 10px;
//     width: 50%;
// `;

// const VisualText = styled(Box)`
//     position: absolute;
//     font-size: 11px;
//     padding: 3.7% 5%;
//     opacity: 0;
//     font-family: WilliamDisplay;
//     line-height: 14px;
// `;

const Text = styled(BaseText)`
    user-select: none;
`;
const Box = styled(BaseBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default React.memo(function PressHeading() {
    const [isText, setClicked] = useState(false);
    // const [clicked, setClicked] = useState(false);
    // const [everClicked, setEverClicked] = useState(false);

    // const Heading = !everClicked
    //     ? styled(BaseHeading)``
    //     : everClicked && clicked
    //     ? styled(BaseHeading)`
    //           height: 110px;
    //           animation: ${headingAnimationSpread} 1s ease forwards;
    //           .visual-title {
    //               animation: ${titleAnimationNarrow} 1s ease forwards;
    //               div {
    //                   animation: ${titleTextAnimationNarrow} 1s ease forwards;
    //               }
    //           }

    //           .visual-parent {
    //               animation: ${visualParentAnimationSpread} 1s ease forwards;
    //               .visual-top {
    //                   animation-play-state: paused;
    //                   animation-name: ${topPartSpread};
    //                   animation-duration: 2s;
    //                   animation-delay: 1s;
    //               }

    //               .visual-bottom {
    //                   animation-play-state: paused;
    //                   animation-name: ${bottomPartSpread};
    //                   animation-duration: 2s;
    //                   animation-delay: 1s;
    //               }
    //           }

    //           .visual-text {
    //               animation: ${textAnimationFromTransparent} 0.5s ease forwards
    //                   0.7s;
    //           }
    //       `
    //     : styled(BaseHeading)`
    //           height: 195px;
    //           animation: ${headingAnimationNarrow} 1s ease forwards;
    //           .visual-title {
    //               animation: ${titleAnimationSpread} 1s ease forwards;
    //               div {
    //                   animation: ${titleTextAnimationSpread} 1s ease forwards;
    //               }
    //           }

    //           .visual-parent {
    //               width: 100%;
    //               animation: ${visualParentAnimationNarrow} 1s ease forwards;
    //           }

    //           .visual-text {
    //               animation: ${textAnimationToTransparent} 0.2s ease forwards;
    //           }
    //       `;

    const Heading = styled(Box)`
        border: solid rgb(0, 0, 0);
        border-width: 1px 0 0;
        height: ${isText ? 'auto' : '14rem'};
        grid-template-columns: none;
        grid-column: none;
        display: flex;
        &:hover {
            cursor: pointer;
        }
        > *:not(*:last-of-type) {
            border-right: 2px solid rgb(0, 0, 0);
        }
    `;

    return (
        <Heading
            onClick={() => {
                setClicked(!isText);
            }}
        >
            {isText ? (
                <Text
                    type="mbody1"
                    className="visual-text"
                    t
                    margin="2.8rem 2.5rem"
                >
                    texts.about
                </Text>
            ) : (
                <Box>
                    <Text type="mh1" textTransform="uppercase" t>
                        headers.strelka_press
                    </Text>
                    <Text mt="20px" type="mh3" textTransform="uppercase" t>
                        headers.about
                    </Text>
                </Box>
            )}
        </Heading>
    );
});
