import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from 'Src/App';
import { TranslationProvider } from 'Src/Context/Translations';
import GlobalStyles from 'Src/GlobalStyles';
import AppProvider from 'Src/model';
import { InstantCartProvider } from 'Src/model/instantCart';
import { SearchProvider } from 'Src/model/search';

const placeholder = document.getElementById('placeholder');

if (placeholder) {
    placeholder.remove();
}

ReactDOM.render(
    <TranslationProvider>
        <InstantCartProvider>
            <SearchProvider>
                <AppProvider>
                    <GlobalStyles />
                    <App />
                </AppProvider>
            </SearchProvider>
        </InstantCartProvider>
    </TranslationProvider>,
    document.getElementById('app'),
);
