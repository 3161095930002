import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import PageHeader from 'Src/components/PageHeader';
import { AppContext } from 'Src/model';

const StyledText = styled(Text)`
    display: inline;
`;

class OrderResponse extends PureComponent<any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <>
                <PageHeader title="ui.empty" withoutBack />
                <Box m="75px 62px" minHeight="40vh">
                    <StyledText type="body1" t>
                        orderResponse.your_order
                    </StyledText>
                    <StyledText type="body1">{this.props.order.id}</StyledText>
                    <StyledText type="body1" t>
                        orderResponse.is_success
                    </StyledText>
                    {this.props.order.isDelivering ? (
                        <StyledText type="body1" t>
                            orderResponse.delivery
                        </StyledText>
                    ) : (
                        <StyledText type="body1" t>
                            orderResponse.pick_up
                        </StyledText>
                    )}
                </Box>
            </>
        );
    }
}

export default withRouter(
    class extends React.PureComponent<any> {
        constructor(props: any) {
            super(props);
        }
        render() {
            return (
                <AppContext.Consumer>
                    {(model: any) => <OrderResponse order={model.order} />}
                </AppContext.Consumer>
            );
        }
    },
);
