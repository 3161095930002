import * as React from 'react';
import styled from 'styled-components';

import { Box } from 'Src/components/basic';
import Icon from 'Src/components/Icon';

const Container = styled(Box)`
    &:hover {
        cursor: pointer;
    }
`;
const RadioItem: React.FunctionComponent<{
    onChange?: (b: boolean) => void;
    value?: boolean;
    size?: string;
    mobile?: boolean;
    marginTop?: string;
}> = props => {
    const { onChange, value } = props;

    return (
        <Container
            onClick={() => onChange && onChange(!value)}
            display="flex"
            my={props.mobile ? '20px' : '38px'}
        >
            <Icon
                name={value ? 'radioActive' : 'radioInactive'}
                mr={props.mobile ? '15px' : '23px'}
                size={props.size || '28px'}
                flex="0 0 auto"
            />
            <Box pt="2px">{props.children}</Box>
        </Container>
    );
};

export default RadioItem;
