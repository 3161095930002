import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Cover, Text } from 'Src/components/basic';
import Price from 'Src/components/Price';

const LinkBlock = styled(Link)`
    display: block;
`;

export default function(
    props: IBookShortBag & { presentation?: 'card' | 'line' },
) {
    const authors = props.authors.map((a: any) => a.name).join(', ');
    const cover = props.images.find((img: any) => Boolean(img.isCover));
    const coverImageUrl = cover ? cover.path : '';
    const link = `/items/${props.id}`;

    return (
        <LinkBlock to={link}>
            <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                width="200px"
            >
                <Cover
                    bgUrl={coverImageUrl}
                    flexShrink={0}
                    width="magic.3"
                    boxShadow="0"
                />
                <Box flexGrow={1} mr="magic.3">
                    <Text textTransform="uppercase" fontSize="5">
                        {props.title}
                    </Text>
                    <Text pt={3} fontSize="4" fontFamily="2">
                        {authors}
                    </Text>
                    <Text pt={3} fontFamily="2">
                        {props.summary}
                    </Text>
                </Box>
                <Box flexShrink={0}>
                    <Price value={props.price} />
                </Box>
            </Box>
        </LinkBlock>
    );
}
