import * as React from 'react';
import styled from 'styled-components';

import { Box, Text } from 'Src/components/basic';
import Icon from 'Src/components/Icon';
import { Book, Carousel, Header } from 'Src/components/mobile';
import PageDelimeter from 'Src/components/PageDelimeter';

const Topbar = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
`;
const Content = styled.div`
    margin-top: 20px;
`;
const ViewIcon = styled(Icon)<{ activeName: string }>`
    opacity: ${props => (props.name === props.activeName ? 1 : 0.25)};
    width: 23px;
    height: 23px;
`;
const Filters = styled((props: any) => (
    <div className={props.className || ''}>{props.children}</div>
))`
    height: 0;
    overflow: hidden;
    transition: 0.3s;
    &.open {
        height: auto;
    }
`;
const AbsoluteBox = styled(Box)`
    z-index: 3;
    position: absolute;
    border: 1px solid #000;
    border-top: 0;
    width: calc(100% - 40px);
    margin-left: -1px;
    margin-top: 1px;
    background: #fff;
    max-height: 200px;
    overflow: auto;
    padding: 5px 0;
`;

class Filter extends React.PureComponent<any> {
    state: any = {
        isOpened: false,
        filtered: this.props.initial || [],
    };
    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.drop) {
            this.setState(
                () => ({ filtered: [] }),
                () => this.props.onFilter(this.state.filtered),
            );
        }

        if (prevProps.initial.length !== this.props.initial.length) {
            this.setState(() => ({ filtered: this.props.initial }));
        }

        if (prevState.isOpened && !this.state.isOpened) {
            document.onclick = null;
        }

        if (!prevState.isOpened && this.state.isOpened) {
            // document.onclick = () => console.log(123)
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    toggleFilter = () => this.setState({ isOpened: !this.state.isOpened });
    handleFilterClick = (id: string) => {
        this.setState(
            (state: any) => ({
                filtered: state.filtered.includes(id)
                    ? state.filtered.filter((_id: string) => _id !== id)
                    : [...state.filtered, id],
            }),
            () => this.props.onFilter(this.state.filtered),
        );
    };
    render() {
        const { title, all } = this.props;
        const { isOpened, filtered } = this.state;

        return (
            <Box border="1px solid #000" marginBottom="7px">
                <Box
                    padding="7px 10px 6px"
                    display="flex"
                    justifyContent="space-between"
                    onClick={this.toggleFilter}
                    backgroundColor={isOpened ? '#F8F8F8' : '#fff'}
                >
                    {all && !isOpened && filtered.length > 0 ? (
                        <Text
                            color={
                                !isOpened && filtered.length > 0
                                    ? '#1A67F6'
                                    : '#000'
                            }
                            type="mh2"
                            textTransform="uppercase"
                        >
                            {filtered
                                .map(
                                    (id: string) =>
                                        all.find((item: any) => item.id === id)
                                            .name,
                                )
                                .join(', ')}
                        </Text>
                    ) : (
                        <Text
                            color={
                                !isOpened && filtered.length > 0
                                    ? '#1A67F6'
                                    : '#000'
                            }
                            type="mh2"
                            textTransform="uppercase"
                            t
                        >
                            {title}
                        </Text>
                    )}
                    <Icon size="16px" name={isOpened ? 'mminus' : 'mplus'} />
                </Box>
                {isOpened && (
                    <AbsoluteBox>
                        {all &&
                            all.map((item: any) => (
                                <Box
                                    padding="5px 10px"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    key={item.id}
                                    onClick={() =>
                                        this.handleFilterClick(item.id)
                                    }
                                >
                                    <Text type="mh2">
                                        {item.name.toUpperCase()}
                                    </Text>
                                    <Icon
                                        name={
                                            filtered.includes(item.id)
                                                ? 'radioActive'
                                                : 'radioInactive'
                                        }
                                        size="22px"
                                    />
                                </Box>
                            ))}
                    </AbsoluteBox>
                )}
            </Box>
        );
    }
}

export default class ShopIndex extends React.PureComponent<any> {
    state: any = {
        isFilterOpened: true,
        isDrop: false,
    };

    toggleFilter = () =>
        this.setState((state: any) => ({
            isFilterOpened: !state.isFilterOpened,
        }));

    handleDropFilters = () =>
        this.setState(
            () => ({ isDrop: true }),
            () => this.setState(() => ({ isDrop: false })),
        );

    public render() {
        const {
            view,
            books,
            popular,
            filterValues,
            filter: { authors, topics, languages },
            setFilterValue,
            setView,
        } = this.props;
        const { isDrop } = this.state;

        const bookPresentation = view === 'list' ? 'line' : 'singleGrid';

        const isFilterEmpty =
            authors.length === 0 &&
            topics.length === 0 &&
            languages.length === 0;

        return (
            <React.Fragment>
                <Header
                    background="#F3FA75"
                    title="headers.strelka_press_books"
                    onClick={this.handleDropFilters.bind(this)}
                    t
                />
                <Box margin="0 20px">
                    <Topbar>
                        <Box
                            display="flex"
                            alignItems="center"
                            onClick={this.toggleFilter}
                        >
                            <Text type="mh2" textTransform="uppercase" t>
                                ui.filter
                            </Text>
                            <Icon
                                margin="-4px 0 0 10px"
                                name={
                                    this.state.isFilterOpened
                                        ? 'mminus'
                                        : 'mplus'
                                }
                                size="16px"
                            />
                        </Box>
                        <Box display="flex">
                            <ViewIcon
                                activeName={`mobile${view}`}
                                name="mobilegrid"
                                onClick={() => setView('grid')}
                            />
                            <ViewIcon
                                activeName={`mobile${view}`}
                                name="mobilelist"
                                margin="0 0 0 10px"
                                onClick={() => setView('list')}
                            />
                        </Box>
                    </Topbar>
                    <Filters
                        margin="10px 0"
                        className={this.state.isFilterOpened ? 'open' : ''}
                    >
                        <Filter
                            onFilter={(filtered: any) =>
                                setFilterValue('topics', filtered)
                            }
                            title="ui.theme"
                            initial={topics}
                            drop={isDrop}
                            all={
                                filterValues &&
                                filterValues.topics.map((topic: any) => ({
                                    name: topic.name,
                                    id: topic.topic_id,
                                }))
                            }
                        />
                        <Filter
                            onFilter={(filtered: any) =>
                                setFilterValue('authors', filtered)
                            }
                            title="ui.author"
                            initial={authors}
                            drop={isDrop}
                            all={
                                filterValues &&
                                filterValues.authors.map((author: any) => ({
                                    name: author.name,
                                    id: author.authorId,
                                }))
                            }
                        />
                        {/*         <Filter
                            onFilter={(filtered: any) =>
                                setFilterValue('languages', filtered)
                            }
                            title="ui.lang"
                            initial={languages}
                            drop={isDrop}
                            all={
                                filterValues &&
                                filterValues.languages.map((language: any) => ({
                                    name: language.language,
                                    id: language.code,
                                }))
                            }
                        />*/}
                        {isFilterEmpty ? (
                            <Text type="mbody3" color="#fff" textAlign="right">
                                000
                            </Text>
                        ) : (
                            <Text
                                type="mbody2"
                                color="#1A67F6"
                                textAlign="left"
                                marginTop="15px"
                                onClick={this.handleDropFilters}
                                t
                            >
                                ui.reset_filter
                            </Text>
                        )}
                    </Filters>
                    <Content>
                        <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="space-between"
                        >
                            {books.map((book: any) => (
                                <Book
                                    key={book.id}
                                    {...book}
                                    presentation={bookPresentation}
                                    withButtonPrice={true}
                                />
                            ))}
                        </Box>
                    </Content>
                </Box>
                <PageDelimeter
                    title="headers.popular"
                    background="#EA3DF7"
                    color="#fff"
                    mobile
                />
                <Carousel
                    items={((total: any) =>
                        total.map((_: never, index: number) => [
                            popular[index * 2],
                            popular[index * 2 + 1],
                        ]))([0, 0])}
                    component={(_props: any) => (
                        <Box
                            display="flex !important"
                            flexDirection="row"
                            justifyContent="space-between"
                            margin="35px 20px 0"
                        >
                            <Book {..._props[0]} />
                            <Book {..._props[1]} />
                        </Box>
                    )}
                />
            </React.Fragment>
        );
    }
}
