import { Field, Form, Formik } from 'formik';
import * as React from 'react';

import { Box, Input, Textarea } from 'Src/components/basic';

const OrderFormCourier: React.FunctionComponent = function OrderFormCourier(
    props: any,
) {
    return (
        <Formik
            initialValues={{
                name: '',
                surname: '',
                email: '',
                phone: '',
                comment: '',
                country: '',
                city: '',
                address: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
            }}
            validate={(values: any) => {
                const errors: any = {};

                if (!values.email) {
                    errors.email = 'Required';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email,
                    )
                ) {
                    errors.email = 'Invalid email address';
                }

                if (!values.name) {
                    errors.name = 'Required';
                }

                if (!values.country) {
                    errors.country = 'Required';
                }

                if (!values.surname) {
                    errors.surname = 'Required';
                }

                if (!values.city) {
                    errors.city = 'Required';
                }

                if (!values.address) {
                    errors.address = 'Required';
                }

                if (!values.comment) {
                    errors.comment = 'Required';
                }

                if (!values.phone) {
                    errors.phone = 'Required';
                } else if (
                    !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
                        values.phone,
                    )
                ) {
                    errors.phone = 'Invalid phone number';
                }

                return errors;
            }}
        >
            {({ handleChange, errors, touched }) => (
                <Form>
                    <Box display="flex" mb="3">
                        <Field
                            mr="3"
                            type="text"
                            error={errors.name && touched.name && errors.name}
                            name="name"
                            as={Input}
                            placeholder="ui.name"
                            value={props.name}
                            onChange={(e: any) => {
                                handleChange(e);
                                props.onChange(e.target.name, e.target.value);
                            }}
                            small
                            mobile
                        />
                        <Field
                            type="text"
                            error={
                                errors.surname &&
                                touched.surname &&
                                errors.surname
                            }
                            name="surname"
                            as={Input}
                            placeholder="ui.lastName"
                            value={props.surname}
                            onChange={(e: any) => {
                                handleChange(e);
                                props.onChange(e.target.name, e.target.value);
                            }}
                            small
                            mobile
                        />
                    </Box>
                    <Field
                        mb="3"
                        type="email"
                        error={errors.email && touched.email && errors.email}
                        name="email"
                        value={props.email}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                        as={Input}
                        placeholder="ui.email"
                        small
                        mobile
                    />
                    <Field
                        mb="3"
                        type="tel"
                        error={errors.phone && touched.phone && errors.phone}
                        name="phone"
                        value={props.phone}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                        as={Input}
                        placeholder="ui.phone"
                        small
                        mobile
                    />
                    <Field
                        mb="3"
                        type="text"
                        error={
                            errors.country && touched.country && errors.country
                        }
                        name="country"
                        value={props.country}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                        as={Input}
                        placeholder="ui.country"
                        small
                        mobile
                    />
                    <Field
                        mb="3"
                        type="text"
                        error={errors.city && touched.city && errors.city}
                        name="city"
                        value={props.city}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                        as={Input}
                        placeholder="ui.city"
                        small
                        mobile
                    />
                    <Field
                        mb="3"
                        type="text"
                        error={
                            errors.address && touched.address && errors.address
                        }
                        name="address"
                        value={props.address}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                        as={Input}
                        placeholder="ui.address"
                        small
                        mobile
                    />
                    <Field
                        mb="3"
                        type="textarea"
                        error={
                            errors.comment && touched.comment && errors.comment
                        }
                        name="comment"
                        value={props.comment}
                        onChange={(e: any) => {
                            handleChange(e);
                            props.onChange(e.target.name, e.target.value);
                        }}
                        as={Textarea}
                        placeholder="ui.comment"
                        small
                        mobile
                    />
                </Form>
            )}
        </Formik>
    );
};

export default OrderFormCourier;
